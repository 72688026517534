<template>
  
  <div>
    <!-- 添加企业信息 -->
    <el-button type="primary" plain @click="addList"
      ><i class="el-icon-plus"></i>添加企业信息
    </el-button>
    <el-dialog
      title="企业信息"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :show-close="false"
      :center="true"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="企业名称" prop="companyName">
              <el-input
                clearable
                v-model="ruleForm.companyName"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item label="企业类型" prop="companyType">
              <el-input
                clearable
                v-model="ruleForm.companyType"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <el-form-item label="企业联系人" prop="hrName">
                <el-input v-model="ruleForm.hrName" clearable></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="8"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="电话" prop="hrTel">
                <el-input v-model="ruleForm.hrTel" clearable></el-input>
              </el-form-item></div
          ></el-col>
        </el-row>
        <el-form-item label="企业地址" prop="companyAddress" style="width: 67%">
          <el-input clearable v-model="ruleForm.companyAddress"></el-input>
        </el-form-item>
        <el-form-item label="就业描述" prop="companyInfo" style="width: 67%">
          <el-input
            type="textarea"
            :rows="3"
            v-model="ruleForm.companyInfo"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-form
            label-position="left"
            inline
            class="demo-table-expand"
            style="padding: 0 180px"
          >
            <el-form-item label="企业地址:">
              <span>{{ scope.row.companyAddress }}</span>
            </el-form-item>
            <el-form-item label="联系人名称:">
              <span>{{ scope.row.hrName }}</span>
            </el-form-item>
            <el-form-item label="电话:">
              <span>{{ scope.row.hrTel }}</span>
            </el-form-item>
            <el-form-item label="企业类型:">
              <span>{{ scope.row.companyType }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="企业名称" prop="companyName"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="240%">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleDescribe(scope.$index, scope.row)"
            >就业描述</el-button
          >
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>

          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
   
  </div>
  
</template> 
<script>
export default {
  data() {
    return {
      id: "",
      teacherId: null,
      tableData: [],
      total: 0,
      ruleForm: {
        teacherId: 53,
        companyName: "",
        companyType: "",
        companyAddress: "",
        hrName: "",
        hrTel: "",
        companyInfo: "",
        delivery: false,
        
      },
      rules: {
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        companyType: [
          { required: true, message: "请输入企业类型", trigger: "blur" },
        ],
        companyAddress: [
          { required: true, message: "请输入企业地址", trigger: "blur" },
        ],
        hrName: [
          { required: true, message: "请输入企业联系人姓名", trigger: "blur" },
        ],
        hrTel: [
          { required: true, message: "请输入企业联系人电话", trigger: "blur" },
        ],
        companyInfo: [
          { required: true, message: "请输入企业招聘信息", trigger: "blur" },
        ],
      },
      dialogVisible: false,
      modalType: 0, //0表示新增，1表示编辑
      formLabelWidth: "120px",
    };
  },
  methods: {
    addList() {
      this.dialogVisible = true;
      this.modalType = 0;
    },
    handleDescribe(index, row) {
      this.$alert(row.companyInfo, row.companyName, {
        confirmButtonText: "确定",
      });
    },
    handleEdit(row) {
      this.modalType = 1;

      this.id = row.id;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.ruleForm = JSON.parse(JSON.stringify(row));
      });
    },
    //提交表单
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.modalType == 0) {
            this.$http({
              url: "/companys",
              method: "POST",
              data: {
                companyName: this.ruleForm.companyName,
                companyType: this.ruleForm.companyType,
                companyAddress: this.ruleForm.companyAddress,
                hrName: this.ruleForm.hrName,
                hrTel: this.ruleForm.hrTel,
                companyInfo: this.ruleForm.companyInfo,
                teacherId: this.teacherId,
              },
            })
              .then((res) => {
                this.reqList();
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.$http({
              url: "/companys",
              method: "PUT",
              data: {
                id: this.id,
                companyName: this.ruleForm.companyName,
                companyType: this.ruleForm.companyType,
                companyAddress: this.ruleForm.companyAddress,
                hrName: this.ruleForm.hrName,
                hrTel: this.ruleForm.hrTel,
                companyInfo: this.ruleForm.companyInfo,
                teacherId: this.teacherId,
              },
            })
              .then(() => {
                //再次请求
                this.reqList();
              })
              .catch((err) => {
                console.log(err);
              });
          }
          //清空表单数据
          this.$refs.ruleForm.resetFields();
          //关闭弹窗
          this.dialogVisible = false;
        }
      });
    },
    //删除
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: "/companys/companyId/" + row.id,
            method: "DELETE",
          }).then(() => {
            this.reqList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    cancel() {
      this.handleClose();
    },
    handleClose() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
    //请求数据
    reqList() {
      this.$http({
        url: "/companys/teacherId/"+this.teacherId,
        method: "GET",
      })
        .then((res) => {

          this.tableData = res.data.data;
          this.total = res.data.data.length;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  mounted() {
    this.teacherId = window.sessionStorage.getItem("workIdUpdate");
    this.reqList();
   
  },
};
</script>

<style scoped lang="less">
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 33%;
}
</style>