import Vue from 'vue';
import VueRouter from 'vue-router';
import IdLogin from '../views/Login/IdLogin.vue';
//老师的相关组件
import Teacher from '../views/Teacher/Teacher.vue';
import CollegeEmployment from '../views/Teacher/CollegeEmployment.vue';
import ChangeStatus from '../views/Teacher/ChangeStatus.vue';
import InquireAudit from '../views/Teacher/InquireAudit.vue';
import TeacherGuidance from '../views/Teacher/TeacherGuidance.vue';
import EmploymentRate from '../views/Teacher/EmploymentRate.vue';
import CompanyMessage from '../views/Teacher/CompanyMessage.vue';
import HighSeas from '../views/Teacher/HighSeas.vue';
//领导的相关组件
import Leader from '../views/Leader/Leader.vue';
//领导与教师、招就处共用相关组件
import TeacherBonus from '../views/Manager/TeacherBonus.vue';
//招就处与领导共用相关组件
import SituationAll from '../views/Leader/SituationAll.vue';
import SituationCollege from '../views/Leader/SituationCollege.vue';
import SituationMajor from '../views/Leader/SituationMajor.vue';
//招就处的相关组件
import Manager from '../views/Manager/Manager.vue';
import PickLesson from '../views/Manager/PickLesson.vue';
import StudentMsg from '../views/Manager/StudentMsg.vue';
import TeacherMsg from '../views/Manager/TeacherMsg.vue';
import LeaderMsg from '../views/Manager/LeaderMsg.vue';
import Audit from '../views/Manager/Audit.vue';
import EmploymentAwards from '../views/Manager/EmploymentAwards.vue';
import EmploymentComparison from '../views/Manager/EmploymentComparison.vue';
import College from '../views/Manager/College.vue';
import Indicators from '../views/Manager/Indicators.vue';
import AwardsByCollege from '@/views/Teacher/AwardsByCollege.vue';
import AwardsByOffice from '@/views/Teacher/AwardsByOffice.vue';
import ChinaECharts from '@/views/Manager/ChinaECharts.vue';


Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/login',
		component: IdLogin
	},
	{
		path: '/teacher',
		component: Teacher,
		redirect: '/teacher/CollegeEmployment',
		children: [
			{path: '/teacher/CollegeEmployment', component: CollegeEmployment},
			{path: '/teacher/ChangeStatus', component: ChangeStatus},
			{path: '/teacher/InquireAudit', component: InquireAudit},
			{path: '/teacher/TeacherGuidance', component: TeacherGuidance},
			{path: '/teacher/EmploymentRate', component: EmploymentRate},
			{path: '/teacher/CompanyMessage', component: CompanyMessage},
			{path: '/teacher/TeacherBonus', component: TeacherBonus},
			{path: '/teacher/HighSeas', component: HighSeas},
			{path: '/teacher/collegeBonus', component: AwardsByCollege},
			{path: '/teacher/office/:collegeName', component: AwardsByOffice, props: true}

		]
	},
	{
		path: '/leader',
		component: Leader,
		redirect: '/leader/situationAll',
		children: [
			{path: '/leader/situationAll', component: SituationAll},
			{path: '/leader/situationCollege', component: SituationCollege},
			{path: '/leader/situationMajor', component: SituationMajor},
			{path: '/leader/teacherBonus', component: TeacherBonus},
			{path: '/leader/collegeBonus', component: AwardsByCollege},
			{path: '/leader/office/:collegeName', component: AwardsByOffice, props: true}
		]
	},
	{
		path: '/manager',
		component: Manager,
		redirect: '/manager/College',
		children: [
			{path: '/manager/PickLesson', component: PickLesson},
			{path: '/manager/Indicators', component: Indicators},
			{path: '/manager/StudentMsg', component: StudentMsg},
			{path: '/manager/TeacherMsg', component: TeacherMsg},
			{path: '/manager/LeaderMsg', component: LeaderMsg},
			{path: '/manager/Audit', component: Audit},
			{path: '/manager/echarts', component: ChinaECharts},
			{path: '/manager/SituationAll', component: SituationAll},
			{path: '/manager/SituationCollege', component: SituationCollege},
			{path: '/manager/SituationMajor', component: SituationMajor},
			{path: '/manager/TeacherBonus', component: TeacherBonus},
			{path: '/manager/EmploymentComparison', component: EmploymentComparison},
			{path: '/manager/EmploymentAwards', component: EmploymentAwards},
			{path: '/manager/College', component: College},
			{path: '/manager/collegeBonus', component: AwardsByCollege},
			{path: '/manager/office/:collegeName', component: AwardsByOffice, props: true}

		]
	}
];

const router = new VueRouter({
	mode: 'history',
	routes
});

// 在此处进行配置全局的路由守卫（全局前置钩子）
// 目的是：判断当前用户中本地存储是否有token，使用户在登录之前，只能访问到/login页面，其他的页面是访问不到的。
router.beforeEach((to, from, next) => {
	// to 即将进入的路由
	// from 在哪个路由进入的
	// next 放行
	//   console.log(to); //打印的是页面要跳转到的路由,例如：它下面的path：为"/login"
	let token = sessionStorage.getItem('token') || ''; //在本地存储中获取token
	if (token) {
		//判断是否有token
		next();
	} else {
		//在没有token的前提下，to下面的path是否为/login，如果不是则页面跳转到登录页面
		if (to.path == '/login') {
			next();
		} else {
			// this.$message.error("权限丢失,请重新登录");
			alert('无权限访问！将为您跳转到登入页面')
			next({path: '/login'}); //跳转页面到login页
		}
	}
});
export default router;
