<template>
  <div>
    <div>
	    <el-col :span="6">
	      <el-date-picker v-model="value" placeholder="选择年" type="year" value-format="yyyy" @change="selectYear(value)">
	      </el-date-picker>
	    </el-col>
	    <el-col :span="6">
	      <el-button type="primary" @click="exportExcel">导出就业奖励总计表</el-button>
	    </el-col>
    </div>

	 <div>
		 <el-col :span="7" class="reward-calculation">
	    <div class="reward-title">奖罚金额计算公式参数调整:</div>
	    <div class="calculation-rule">
	      就业率已达标 :
	      <span>奖罚人数 * 奖励钱数（元/人） * 接课比例</span><br/>
	      就业率未达标 :
	      <span>奖罚人数 * 罚款钱数（元/人） * 接课比例</span><br/><br/>
	      <span>输入示例：若灵活就业为0.5输入50即可</span><br/>
	    </div>
	    <div class="input-group">
	      <div class="demo-input-suffix">
	        奖励钱数（元/人）:
	        <el-input v-model="BigInputReward" placeholder="奖励钱数（元/人）"></el-input>
	      </div>
	      <div class="demo-input-suffix">
	        罚款钱数（元/人）:
	        <el-input v-model="SmallInputIntroduce" placeholder="罚款钱数（元/人）"></el-input>
	      </div>
	      <div class="demo-input-suffix">
	        研究生%:
	        <el-input v-model="kaoyan" placeholder="研究生%"></el-input>
	      </div>
	      <div class="demo-input-suffix">
	        公务员/选调生/参军入伍%:
	        <el-input v-model="kaogong" placeholder="公务员/选调生/参军入伍%"></el-input>
	      </div>
	    </div>
	    <div class="input-group">
	      <div class="demo-input-suffix">
	        已就业（三方协议/劳务合同）%:
	        <el-input v-model="jobing" placeholder="已就业（三方协议/劳务合同）%"></el-input>
	      </div>
	      <div class="demo-input-suffix">
	        自主创业%:
	        <el-input v-model="selfjob" placeholder="自主创业%"></el-input>
	      </div>
	      <div class="demo-input-suffix">
	        灵活就业%:
	        <el-input v-model="flexjob" placeholder="灵活就业%"></el-input>
	      </div>
	      <div class="demo-input-suffix">
	        自定义就业%:
	        <el-input v-model="selfdefine" placeholder="自定义就业%"></el-input>
	      </div>
	    </div>
	  </el-col>
	 </div>

  <div style="height: 400px">
	  <el-table :data="tableData" border stripe>
		<el-table-column label="学院名称" prop="collegeName">
		</el-table-column>
		<el-table-column fixed label="专业名称" prop="majorName">
		</el-table-column>
		<el-table-column label="毕业人数" prop="graduateNum">
		</el-table-column>
		<el-table-column label="考研、出国人数" prop="postgraduateNum">
		</el-table-column>
		<el-table-column label="真实就业人数" prop="realEmployedNum">
		</el-table-column>
		<el-table-column label="30%内灵活就业人数" prop="flexibleEmployedNumWithin30Percent">
		</el-table-column>
		<el-table-column label="30%外灵活就业人数" prop="flexibleEmployedNumBeyond30Percent">
		</el-table-column>
		<el-table-column label="未就业人数" prop="unemployedNum">
		</el-table-column>
		<el-table-column label="折算就业人数" prop="equivalentEmployedNum">
		</el-table-column>
		<el-table-column label="就业奖励起点" prop="employmentRewardStartPoint">
		</el-table-column>
		<el-table-column label="奖罚人数" prop="rewardPunishNum">
		</el-table-column>
		<el-table-column label="是否接课" prop="acceptCourse">
		</el-table-column>
		<el-table-column label="就业介绍人数" prop="introduceTeaNum">
		</el-table-column>
		<el-table-column label="奖罚金额" prop="rewardAmount">
		</el-table-column>
	</el-table>
  </div>

  </div>
</template>


<script>

export default {
	data() {
		return {
			//奖罚金额:若奖罚人数＞0，（奖罚人数*BigInputReward-就业介绍人数*BigInputIntroduce）*接课比例
			BigInputReward: 500,
			BigInputIntroduce: 200,
			// 若奖罚人数＜0，（奖罚人数*SmallInputReward-就业介绍人数*SmallInputIntroduce）*接课比例
			SmallInputReward: 200,
			SmallInputIntroduce: 200,
			kaoyan: 100,
			kaogong: 100,
			jobing: 100,
			selfjob: 100,
			flexjob: 50,
			selfdefine: 10,
			file: '',
			filename: '',
			filelist: [],
			value: '2023',
			year: '',
			tableData: [],
			formData: []
		};
	},
	mounted() {
		this.loadData()
	},
	methods: {
		async loadData() {
			try {
				const formData = this.getFormData()
				const res = await this.$http.post('/students/awardJobReward', formData);
				if (res?.data?.data) {
					this.tableData = res?.data?.data
				}
			} catch (error) {
				console.error('Error:', error);
			}
		},
		//验证X,Y,W,M
		verify() {

			if (
					this.BigInputReward === '' || this.BigInputIntroduce === '' ||
					this.SmallInputReward === '' || this.SmallInputIntroduce === ''
			) {
				return false;
			}
			return !(isNaN(this.BigInputReward) ||
					isNaN(this.BigInputIntroduce) ||
					isNaN(this.SmallInputReward) ||
					isNaN(this.SmallInputIntroduce));

		},


		//导出就业奖励总计表的点击事件
		exportExcel() {
			let flag = this.verify();
			if (!flag) {
				this.$message({
					type: 'error',
					message: '参数为空或格式错误！'
				});
				return;
			}
			this.$http({
				method: 'GET',
				url: '/download/JobReward',
				responseType: 'blob',
				params: {
					year: this.value,
					BigInputReward: this.BigInputReward,
					BigInputIntroduce: this.BigInputIntroduce,
					SmallInputReward: this.SmallInputReward,
					SmallInputIntroduce: this.SmallInputIntroduce,
					kaoyan: this.kaoyan,
					kaogong: this.kaogong,
					jobing: this.jobing,
					selfjob: this.selfjob,
					flexjob: this.flexjob,
					selfdefine: this.selfdefine
				}
			})
					.then((res) => {
						const blob = new Blob([res.data], {
							type: 'application/vnd.ms-excel'
						});
						let fileName = '就业奖励总计表.xlsx';
						if ('download' in document.createElement('a')) {
							const elink = document.createElement('a');
							elink.download = fileName;
							elink.style.display = 'none';
							elink.href = URL.createObjectURL(blob);
							document.body.appendChild(elink);
							elink.click();
							URL.revokeObjectURL(elink.href); // 释放URL 对象
							document.body.removeChild(elink);
						} else {
							navigator.msSaveBlob(blob, fileName);
						}
						if (res.status === 200) {
							this.$message({
								type: 'success',
								message: '文件导出成功！'
							});
						} else {
							this.$message({
								type: 'error',
								message: '数据异常，文件导出失败！'
							});
						}
					})
					.catch((error) => {
						console.log(error);
						this.$message({
							type: 'error',
							message: '数据为空，导出失败！'
						});
					});
		},
		selectYear(valueYear) {
			this.value = valueYear
			this.loadData()
		},
		submitForm() {
			this.$refs.form.validate(valid => {
				if (valid) {
					console.log('Form submitted successfully');
				} else {
					console.log('Form validation failed');
				}
			})
		},
		// 获取表单数据
		getFormData() {
			return {
				endYear: this.value,
				bigInputReward: this.BigInputReward,
				bigInputIntroduce: this.BigInputIntroduce,
				smallInputReward: this.SmallInputReward,
				smallInputIntroduce: this.SmallInputIntroduce,
				kaoyan: this.kaoyan,
				kaogong: this.kaogong,
				jobing: this.jobing,
				selfjob: this.selfjob,
				flexjob: this.flexjob,
				selfdefine: this.selfdefine
			};
		}
	}
}
</script>

<style lang="less" scoped>
.input-group {
	display: flex;
	justify-content: space-between;
}

.reward-calculation {
	width: 100%;
	padding: 16px;
	background-color: #f5f7fa;
}

.input-group {
	display: flex;
	flex-wrap: wrap;
}

.input-group > .demo-input-suffix {
	width: calc(25% - 16px);
	padding-right: 16px;
	box-sizing: border-box;
}

</style>
