<template>
  <el-tabs type="border-card">
    <!-- 审核中（未审核） -->
    <el-tab-pane>
      <span slot="label"
        ><i class="el-icon-timer" @click="audit"></i> 审核中</span
      >
      <el-table :data="tableData1" style="width: 100%">
        <el-table-column
          label="日期"
          width="180"
          prop="createTime"
          :formatter="setDate"
        >
        </el-table-column>
        <el-table-column label="姓名" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.studentName }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="学号" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.studentId }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="学院" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.college }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="专业" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.office }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="详细信息" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" size="small"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >撤销</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--  --------------------------------分页1-------------------------- -->
      <div class="block">
        <el-pagination
          layout="prev, pager, next"
          :total="this.total1"
          @current-change="handlePage1"
          background
        >
        </el-pagination>
      </div>
    </el-tab-pane>

    <!-- 审核通过 -->
    <el-tab-pane>
      <span slot="label"
        ><i class="el-icon-success" @click="accomplish"></i> 审核通过</span
      >
      <el-table :data="tableData2" style="width: 100%">
        <el-table-column
          label="日期"
          width="180"
          prop="createTime"
          :formatter="setDate"
        >
        </el-table-column>
        <el-table-column label="姓名" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.studentName }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="学号" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.studentId }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="学院" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.college }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="专业" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.office }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="详细信息" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" size="small"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--  --------------------------------分页2-------------------------- -->
      <div class="block">
        <el-pagination
          layout="prev, pager, next"
          :total="this.total2"
          @current-change="handlePage2"
          background
        >
        </el-pagination>
      </div>
    </el-tab-pane>

    <!-- 审核未通过 -->
    <el-tab-pane>
      <span slot="label"
        ><i class="el-icon-document-delete" @click="refusal"></i>
        审核未通过</span
      >
      <el-table :data="tableData3" style="width: 100%">
        <el-table-column
          label="日期"
          width="180"
          prop="createTime"
          :formatter="setDate"
        >
        </el-table-column>
        <el-table-column label="姓名" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.studentName }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="学号" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.studentId }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="学院" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.college }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="专业" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.office }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="详细信息" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" size="small"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="失败原因" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick1(scope.row)" size="small"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--  --------------------------------分页3-------------------------- -->
      <div class="block">
        <el-pagination
          layout="prev, pager, next"
          :total="this.total3"
          @current-change="handlePage3"
          background
        >
        </el-pagination>
      </div>
    </el-tab-pane>

    <!-- 审核记录 -->
    <el-tab-pane>
      <span slot="label"
        ><i class="el-icon-upload" @click="history"></i> 审核记录</span
      >
      <el-table :data="tableData4" style="width: 100%">
        <el-table-column
          label="日期"
          width="180"
          prop="createTime"
          :formatter="setDate"
        >
        </el-table-column>
        <el-table-column label="姓名" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.studentName }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="学号" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.studentId }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="学院" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.college }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="专业" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.office }}</el-tag>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="详细信息" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" size="small"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--  --------------------------------分页4-------------------------- -->
      <div class="block">
        <el-pagination
          layout="prev, pager, next"
          :total="this.total4"
          @current-change="handlePage4"
          background
        >
        </el-pagination>
      </div>
    </el-tab-pane>

    <!-- 就业信息对话框 -->
    <el-dialog
      title="就业信息"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose3"
    >
      <template>
        <el-descriptions class="margin-top" :column="4" :size="size" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              学生名称
            </template>
            <span>{{ rowData.studentName }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              学号
            </template>
            <span>{{ rowData.studentId }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              专业
            </template>
            <span>{{ rowData.office }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              生源地
            </template>
            <span>{{ rowData.studentSource }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-information"></i>
              意向地(A)
            </template>
            <span>{{ rowData.intentionPlaceOne }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-information"></i>
              意向地(B)
            </template>
            <span>{{ rowData.intentionPlaceTwo }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(A)
            </template>
            <span>{{ rowData.jobIntentionOne }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(B)
            </template>
            <span>{{ rowData.jobIntentionTwo }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(C)
            </template>
            <span>{{ rowData.jobIntentionThree }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业地
            </template>
            <span>{{ rowData.jobIntentionThree }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              介绍老师名称(A)
            </template>
            <span>{{ rowData.introducerOne }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师工号(A)
            </template>
            <span>{{ rowData.introducerOneWorkId }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师电话(A)
            </template>
            <span>{{ rowData.introducerOneTel }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              介绍老师名称(B)
            </template>
            <span>{{ rowData.introducerTwo }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师工号(B)
            </template>
            <span>{{ rowData.introducerTwoWorkId }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师电话(B)
            </template>
            <span>{{ rowData.introducerTwoTel }}</span>
          </el-descriptions-item>
        </el-descriptions>
      </template>

      <!-- 展示图片 -->

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="echoPicture"
        :loading="loading"
        >查看相关附件</el-button
      >
    </el-dialog>

    <!-- 修改建议对话框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible2"
      width="30%"
      :before-close="handleClose2"
    >
      <span>{{ auditSuggestion }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible2 = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 图片展示对话框 -->
    <el-dialog
      title="就业信息图片"
      :visible.sync="dialogVisible3"
      width="60%"
      :before-close="handleClose2"
    >
      <div class="demo-image">
        <div class="block" v-for="item in img64Best" :key="item.index">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            :preview-src-list="img64Best"
          ></el-image>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </el-tabs>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      fits: ["cover"],
      pageNum1: 1,
      pageNum2: 1,
      pageNum3: 1,
      pageNum4: 1,
      total1: 0,
      total2: 0,
      total3: 0,
      total4: 0,
      id: 0,
      workIdUpdate: 0,
      tableData1: [], //审核中...
      tableData2: [], //审核成功！
      tableData3: [], //审核未通过！
      tableData4: [], //审核记录！
      rowData: [], //每行数据
      size: "",
      auditSuggestion: "",
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      imgUrl: [],
      imgUrlNew: "", //参数
      //  img64: "", //存储64
      img64Best: [],
      loading: false,
    };
  },
  methods: {
    /* 展示图片 */
    echoPicture() {
      if (this.imgUrl.length == 0) {
        this.$message({
          message: "该学生暂无相关附件！",
          type: "warning",
        });
      } else {
        this.loading = true;
        for (let i = 0; i < this.imgUrl.length - 1; i++) {
          this.imgUrlNew = this.imgUrl[i]; //参数
          this.downloadPicture(i); //图片下载请求
        }
        setTimeout(() => {
          this.dialogVisible3 = true;
        }, 1500);
      }
    },
    handleClose3() {
      this.imgUrl = [];
      this.dialogVisible = false;
    },
    handleClose2() {
      this.loading = false;
      this.dialogVisible3 = false;
    },
    setDate(row, column) {
      const daterc = row[column.property];
      if (daterc != null) {
        return moment(daterc).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    handleClick(row) {
      this.dialogVisible = true;
      this.rowData = row;
      /* 用逗号分隔 */
      if (row.imageUrl) {
        this.imgUrl = row.imageUrl.split(",");
      }
      this.img64Best = []; //重置数组
    },
    handleClick1(row) {
      this.dialogVisible2 = true;
      this.auditSuggestion = row.auditSuggestion;
    },
    /* 分页1 */
    handlePage1(res) {
      this.pageNum1 = res;
      this.audit();
    },
    /* 分页2 */
    handlePage2(res) {
      this.pageNum2 = res;
      this.accomplish();
    },
    /* 分页3 */
    handlePage3(res) {
      this.pageNum3 = res;
      this.refusal();
    },
    /* 分页4 */
    handlePage4(res) {
      this.pageNum4 = res;
      this.history();
    },
    handleEdit(index, row) {
    },
    handleDelete(index, row) {
      this.id = row.id;
      this.handleClose(index, row);
    },
    handleClose(row) {
      this.$confirm("确认撤销？")
        .then((_) => {
          this.teacherId = row.teacherId;
          this.revocation();
        })
        .catch((_) => {});
    },
    //查询未审核（审核中）的学生信息
    audit() {
      this.$http({
        url: "/students/auditStatus",
        method: "GET",
        params: {
          page: this.pageNum1,
          pageSize: 10,
          auditStatus: "0",
          workIdUpdate: this.workIdUpdate,
        },
      })
        .then((res) => {
          this.total1 = res.data.data.total;
          this.tableData1 = res.data.data.records;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //查询已审核的学生信息
    accomplish() {
      this.$http({
        url: "/students/auditStatus",
        method: "GET",
        params: {
          page: this.pageNum2,
          pageSize: 10,
          auditStatus: "1",
          workIdUpdate: this.workIdUpdate,
        },
      })
        .then((res) => {
          this.total2 = res.data.data.total;
          this.tableData2 = res.data.data.records;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //查询审核未通过的学生信息
    refusal() {
      this.$http({
        url: "/students/auditStatus",
        method: "GET",
        params: {
          page: this.pageNum3,
          pageSize: 10,
          auditStatus: "2",
          workIdUpdate: this.workIdUpdate,
        },
      })
        .then((res) => {
          this.total3 = res.data.data.total;
          this.tableData3 = res.data.data.records;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //查询审核历史的学生信息
    history() {
      this.$http({
        url: "/students/auditedMsg",
        method: "GET",
        params: {
          page: this.pageNum4,
          pageSize: 10,
        },
      })
        .then((res) => {
          this.total4 = res.data.data.total;
          this.tableData4 = res.data.data.records;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //撤销
    revocation() {
      this.$http({
        url: "/teachers/auditMsg",
        method: "DELETE",
        data: {
          id: this.id,
          workIdUpdate: this.workIdUpdate,
        },
      })
        .then((res) => {
          this.audit();
        })
        .catch((err) => {
          console.log("撤销失败：", err);
        });
    },
    downloadPicture(i) {
      this.$http({
        url: "/common/imgDownload",
        method: "GET",
        responseType: "blob",
        params: {
          imgUrl: this.imgUrlNew,
        },
      }).then((res) => {
        this.getBase64(res.data).then((base64) => {
          //  this.img64 = base64;
          this.img64Best[i] = base64;
        });
      });
    },
    getBase64(data) {
      return new Promise((resolve, reject) => {
        const blob = new Blob([data], { type: "image/jpg" }); // 必须指定type类型
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
  mounted() {
    this.workIdUpdate = window.sessionStorage.getItem("workIdUpdate");
    this.audit();
    this.accomplish();
    this.refusal();
    this.history();
  },
};
</script>


<style scoped lang="less">
.block {
  text-align: center;
  padding: 20px 0;
}
</style>