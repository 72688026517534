<template>
  <div class="com-container">
    <el-row>
      <el-col :span="24">
        <el-card class="box-card">
          <div class="year">
            <el-date-picker
              v-model="valueYear"
              type="year"
              placeholder="选择年"
              value-format="yyyy"
              @change="selectYear(valueYear)"
            ></el-date-picker>
          </div>
          <div class="selCollege">
            <el-select v-model="value" placeholder="全校" @change="selectcollege(value)" clearable>
              <el-option
                v-for="item in selectCollege"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                
              ></el-option>
            </el-select>
          </div>
          <div class="allnum">
            <el-tag type="info" style="width:80px">指导总人数：{{num}}</el-tag>
          </div>
          <div class="com-chart" ref="employment_ref"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
 
<script>
// import * as echarts from 'echarts'
export default {
  data() {
    return {
      chartPie: '',
      valueYear: (new Date().getFullYear()-1).toString(),
      typeName: [], //状态类型名称
      typeNum: [], //状态类型数量
      num: '100',
      selectCollege: [],
      value: ''
    }
  },
  methods: {
    drawPieChart() {
      // 基于准备好的 dom，初始化 echarts 实例
      this.chartPie = this.$echarts.init(this.$refs.employment_ref, {
        renderer: 'svg'
      })
      this.chartPie.setOption({
        //设置标题，副标题，以及标题位置居中
        title: {
          text: '学生就业情况分析',
          x: 'center',
          top: '0.5%'
        },
        //具体点击某一项触发的样式内容
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        //左上侧分类条形符
        legend: {
          orient: 'vertical',
          left: 'left',
          data: []
        },
        //饼状图类型以及数据源
        series: [
          {
            name: '统计数量',
            type: 'pie',
            radius: '60%',
            center: ['50%', '50%'],
            data: [],
            //设置饼状图扇形区域的样式
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
    },
    //动态获取饼状图的数据
    async initData() {
      const { data: res } = await this.$http.get('/echarts/guideStatusPie', {
        params: {
          workId: sessionStorage.getItem('workerid'),
          year: this.valueYear
        }
      })
      const { data: res2 } = await this.$http.get('/echarts/guideNum', {
        params: {
          workId: sessionStorage.getItem('workerid'),
          year: this.valueYear
        }
      })
      this.num = 0
      var getData = [];
      //先进行赋值
      console.log(res)
      for (let i = 0; i < res.data.length; i++) {
        var obj = new Object()
        obj.name = res.data[i].name
        obj.value = res.data[i].value
        this.num=this.num+res.data[i].value
        getData[i] = obj
      }
      if (this.num === 0) {
    // 数据为空时显示加载提示
    // this.myChart.clear();
    this.chartPie.showLoading({
      text: '暂无数据',
      color: '#999',
      textColor: '#333',
      maskColor: 'rgba(255, 255, 255, 0.8)',
      zlevel: 0,
      textStyle: {
        fontSize: 16 // 调整字体大小
      }
    });
    return;
  }
  // 数据加载完成后隐藏加载提示
  this.chartPie.hideLoading();
      this.chartPie.setOption({
        legend: {
          data: res.data.typeName
        },
        series: [
          {
            data: getData
          }
        ]
      })
    },
    drawCharts() {
      this.drawPieChart()
    },
    // 选择年份的方法
    selectYear(val) {
      this.valueYear = val
      this.initData()
    },
    async initCollege() {
      var obj1 = new Object()
      obj1.value = "全校"
      this.selectCollege[0] = obj1
      const { data: res3 } = await this.$http.get('/college')
      for (let i = 1; i < res3.data.length; i++) {
        var obj1 = new Object()
        obj1.value = res3.data[i].college
        this.selectCollege[i] = obj1
      }
      console.log(this.selectCollege[1])
    },
    async selectcollege(val) {
      if(val=="全校"){
        this.value=''
      }
      const { data: res } = await this.$http.get('/echarts/guideStatusPie', {
        params: {
          year: this.valueYear,
          college: this.value
        }
      })
      //   this.initData()
      this.num=0
      var getData = []
      //先进行赋值
      for (let i = 0; i < res.data.length; i++) {
        var obj = new Object()
        obj.name = res.data[i].name
        obj.value = res.data[i].value
        this.num=this.num+res.data[i].value
        getData[i] = obj
      }
      if (this.num === 0) {
    // 数据为空时显示加载提示
    // this.myChart.clear();
    this.chartPie.showLoading({
      text: '暂无数据',
      color: '#999',
      textColor: '#333',
      maskColor: 'rgba(255, 255, 255, 0.8)',
      zlevel: 0,
      textStyle: {
        fontSize: 16 // 调整字体大小
      }
    });
    return;
  }
  // 数据加载完成后隐藏加载提示
  this.chartPie.hideLoading();
      this.chartPie.setOption({
        legend: {
          data: res.data.typeName
        },
        series: [
          {
            data: getData
          }
        ]
      })
    }
  },
  mounted() {
    this.initCollege()
    this.initData()
    this.drawCharts()
  }
}
</script>
 
<style lang="less" scoped>
.com-container {
  padding-top: 10px;
  .allnum {
    position: absolute;
    right: 40px;
    bottom: 40px;
    font-size: 18px;
  }
  .com-chart {
    padding-top: 60px;
    width: 100%;
    height: 400px;
  }
  .selCollege {
    position: absolute;
    top: 20px;
    left: 280px;
  }
}
</style>