<template>
  <div>
    <el-row>
      <el-col :span="6"
        ><div class="grid-content bg-purple">
          <div class="block">
            <el-date-picker
              v-model="endYear"
              type="year"
              format="yyyy"
              value-format="yyyy"
              placeholder="选择年"
              @change="changeYear"
            >
            </el-date-picker>
          </div></div
      ></el-col>
      <el-col :span="6"
        ><div class="grid-content bg-purple-light">
          <div class="block">
            <el-cascader
              clearable
              placeholder="请选择专业"
              :show-all-levels="false"
              v-model="office"
              :options="options"
              :props="{ expandTrigger: 'hover', emitPath: false }"
              @change="handleChange"
            ></el-cascader>
          </div></div
      ></el-col>
      <el-col :span="6"
        ><div class="grid-content bg-purple">
          <div class="block">
          <template>
            <el-select
              v-model="value"
              clearable
              placeholder="请选择就业状态"
              @change="changeStatus"
            >
              <el-option
                v-for="item in optionsT"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template></div></div
      ></el-col>
      <el-col :span="6"
        ><div class="grid-content bg-purple-light"></div
      ></el-col>
    </el-row>
    <el-table
      :data="
        tableData.filter(
          (data) => !search || data.studentName || data.studentId
        )
      "
      style="width: 100%"
    >
      <el-table-column prop="endYear" label="毕业年份" width="90">
      </el-table-column>
      <el-table-column prop="studentName" label="姓名" width="80">
      </el-table-column>
      <el-table-column prop="sex" label="性别" width="70px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.sex | getSexColorType" disable-transitions>{{
            scope.row.sex | getSexColorName
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="studentId" label="学号" width="120">
      </el-table-column>
      <el-table-column prop="college" label="学院" width="130">
      </el-table-column>
      <el-table-column prop="office" label="专业" width="160">
      </el-table-column>
      <el-table-column prop="tel" label="电话" width="130"> </el-table-column>

      <el-table-column prop="status" label="是否就业" width="175px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status | getColorType" disable-transitions>{{
            scope.row.status | getColorName
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="就业信息" width="80">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" size="small"
            >查看</el-button
          >
        </template>
      </el-table-column>

      <el-table-column >
        <template slot="header" slot-scope="scope">
          <el-input
            clearable
            v-model="search"
            :value="scope"
            maxlength="10"
            show-word-limit
            size="medium"
            placeholder="输入学号"
            @input="reseek"
          >
            <el-button
              type="primary"
              plain
              slot="append"
              icon="el-icon-search"
              @click="seek"
            ></el-button>
          </el-input>
        </template>
        <template slot-scope="scope">
          <el-popconfirm
            title="确认要移入公海吗？"
            @confirm="outSea(scope.row)"
          >
            <el-button size="mini" slot="reference" type="warning" plain
              >移入公海</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- -----------------------------------分页------------------------------------- -->
    <div class="block">
      <el-pagination
        layout="total,prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="handlePage"
        background
        :current-page.sync="pageNum"
      >
      </el-pagination>
    </div>
    <el-dialog title="就业信息" :visible.sync="dialogVisible" width="60%">
      <template>
        <el-descriptions class="margin-top" :column="4" :size="size" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              学生名称
            </template>
            <span>{{ rowData.studentName }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              学号
            </template>
            <span>{{ rowData.studentId }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              专业
            </template>
            <span>{{ rowData.office }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              生源地
            </template>
            <span>{{ rowData.studentSource }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-information"></i>
              意向地(A)
            </template>
            <span>{{ rowData.intentionPlaceOne }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-information"></i>
              意向地(B)
            </template>
            <span>{{ rowData.intentionPlaceTwo }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(A)
            </template>
            <span>{{ rowData.jobIntentionOne }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(B)
            </template>
            <span>{{ rowData.jobIntentionTwo }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(C)
            </template>
            <span>{{ rowData.jobIntentionThree }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业地
            </template>
            <span>{{  }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              介绍老师名称(A)
            </template>
            <span>{{ rowData.introducerOne }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师工号(A)
            </template>
            <span>{{ rowData.introducerOneWorkId }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师电话(A)
            </template>
            <span>{{ rowData.introducerOneTel }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              介绍老师名称(B)
            </template>
            <span>{{ rowData.introducerTwo }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师工号(B)
            </template>
            <span>{{ rowData.introducerTwoWorkId }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师电话(B)
            </template>
            <span>{{ rowData.introducerTwoTel }}</span>
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </el-dialog>
  </div>
</template>
  
<script>
export default {
  filters: {
    getColorType(status) {
      const colorMap = {
        0: "danger",
        1: "",
        2: "warning",
        3: "success",
        4: "info",
        5: "info",
      };
      return colorMap[status];
    },
    getColorName(status) {
      const colorMap = {
        0: "未就业",
        1: "研究生",
        2: "公务员/选调生/参军入伍",
        3: "已就业(三方协议/劳务合同)",
        4: "自主创业",
        5: "灵活就业",
      };
      return colorMap[status];
    },
    getSexColorType(status) {
      const colorMap = {
        1: "primary",
        0: "danger",
      };
      return colorMap[status];
    },
    getSexColorName(status) {
      const colorMap = {
        1: "男",
        0: "女",
      };
      return colorMap[status];
    },
  },
  data() {
    return {
      size: "",
      dialogVisible: false,
      statusNew: "",
      endYear: null,
      office: null,
      studentId: null,
      search: "",
      total: 0,
      tableData: [],
      pageNum: 1,
      rowData: [],
      options: [],
      optionsT: [
        {
          value: "0",
          label: "未就业",
        },
        {
          value: "1",
          label: "研究生",
        },
        {
          value: "2",
          label: "公务员/选调生/参军入伍",
        },
        {
          value: "3",
          label: "已就业(三方协议/劳务合同)",
        },
        {
          value: "4",
          label: "自主创业",
        },
        {
          value: "5",
          label: "灵活就业",
        },
      ],
      value: "",
    };
  },
  methods: {
    //改变毕业年份
    changeYear(val) {
      this.endYear = val;
      this.pageNum = 1;
      this.getList();
    },
    //改变专业
    handleChange(val) {
      this.office = val;
      this.pageNum = 1;
      this.getList();
    },
    //改变就业状态
    changeStatus(val) {
      this.statusNew = val;
      this.pageNum = 1;
      this.getList();
    },
    //请求专业
    getCollege() {
      this.$http({
        url: "/college/valueLabel",
        method: "GET",
      })
        .then((res) => {
          this.options = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //移入公海
    outSea(row) {
      this.$http({
        url: "/openSea",
        method: "POST",
        params: {
          id: row.id,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "恭喜你，成功移入公海！",
              type: "success",
            });
          }
          if(res.data.code==4007){
            this.$message({
              message: "该生已存在公海中！",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(row) {
      this.dialogVisible = true;
      this.rowData = row;
    },
    seek() {
      if (this.search) {
        this.studentId = this.search;
        this.getList();
      }
    },
    reseek(res) {
      if (!this.search) {
        this.studentId = null;
        this.getList();
      }
    },
    getList() {
      this.$http({
        url: "/students",
        method: "POST",
        params: {
          pageNum: this.pageNum,
          pageSize: 10,
        },
        data: {
          status: this.statusNew,
          endYear: this.endYear,
          office: this.office,
          studentId: this.studentId,
        },
      })
        .then((res) => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total || 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handlePage(res) {
      this.pageNum = res;
      this.getList();
    },
  },
  mounted() {
    this.getList();
    this.getCollege();
  },
};
</script>
  
<style scoped lang="less">
body {
  margin: 0;
}
.block {
  text-align: center;
  padding: 20px 0;
}
</style>
  