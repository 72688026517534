<template>
  <div>
    <el-row>
      <el-col :span="6"
        ><div class="grid-content bg-purple">
          <div class="block">
            <el-date-picker
              style="padding: 20px 0"
              v-model="endYear"
              type="year"
              format="yyyy"
              value-format="yyyy"
              placeholder="选择年"
              @change="changeYear"
            >
            </el-date-picker>
          </div></div
      ></el-col>
      <el-col :span="6"
        ><div class="grid-content bg-purple-light">
          <div class="block">
            <el-cascader
              style="padding: 20px 0"
              clearable
              placeholder="请选择专业"
              :show-all-levels="false"
              v-model="officeN"
              :options="options"
              :props="{ expandTrigger: 'hover', emitPath: false }"
              @change="handleChangeT"
            ></el-cascader>
          </div></div
      ></el-col>
      <el-col :span="6"
        ><div class="grid-content bg-purple">
          <div class="block">
            <template>
              <el-select
                v-model="valueS"
                clearable
                placeholder="请选择就业状态"
                style="padding: 20px 0"
                @change="changeStatus"
              >
                <el-option
                  v-for="item in optionsT"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </div></div
      ></el-col>
      <el-col :span="6"
        ><div class="grid-content bg-purple-light"></div
      ></el-col>
    </el-row>
    <el-dialog
      title="修改信息"
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
      :destroy-on-close="true"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :rules="rules"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="学生姓名" prop="studentName">
              <el-input
                clearable
                v-model="ruleForm.studentName"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item label="学号" prop="studentId">
              <el-input
                clearable
                v-model="ruleForm.studentId"
                type="number"
                @keydown.native="clearInput"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item label="电话" prop="tel">
              <el-input
                clearable
                v-model="ruleForm.tel"
                type="number"
                @keydown.native="clearInput"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="性别" prop="sex">
              <el-select v-model="ruleForm.sex" placeholder="请选择性别">
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="生源地" prop="studentSource">
              <el-input
                clearable
                v-model="ruleForm.studentSource"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item label="意向地" prop="intentionPlaceOne">
              <el-input
                clearable
                v-model="ruleForm.intentionPlaceOne"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item label="意向地" prop="intentionPlaceTwo">
              <el-input
                clearable
                v-model="ruleForm.intentionPlaceTwo"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <el-form-item label="学院" prop="college">
                <el-select
                  v-model="ruleForm.college"
                  @change="changeCollege"
                  placeholder="请选择学院"
                >
                  <el-option
                    v-for="item in optionsX"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="就业意向" prop="jobIntentionOne">
              <el-input
                clearable
                v-model="ruleForm.jobIntentionOne"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item label="就业意向" prop="jobIntentionTwo">
              <el-input
                clearable
                v-model="ruleForm.jobIntentionTwo"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item label="就业意向" prop="jobIntentionThree">
              <el-input
                clearable
                v-model="ruleForm.jobIntentionThree"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="专业" prop="office">
                <el-select v-model="ruleForm.office" placeholder="请选择">
                  <el-option
                    v-for="item in optionsC"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item></div
          ></el-col>
        </el-row>
        <el-row> </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="介绍老师姓名" prop="introducerOne">
              <el-input
                :disabled="false"
                clearable
                v-model="ruleForm.introducerOne"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="介绍老师工号" prop="introducerOneWorkId">
              <el-input
                :disabled="false"
                clearable
                v-model="ruleForm.introducerOneWorkId"
                type="number"
                @keydown.native="clearInput"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="介绍老师电话" prop="introducerOneTel">
              <el-input
                :disabled="false"
                clearable
                v-model="ruleForm.introducerOneTel"
                type="number"
                @keydown.native="clearInput"
              ></el-input>
            </el-form-item>
          </el-col>

          
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="介绍老师姓名" prop="introducerTwo">
              <el-input
                :disabled="false"
                clearable
                v-model="ruleForm.introducerTwo"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="介绍老师工号" prop="introducerTwoWorkId">
              <el-input
                :disabled="false"
                clearable
                v-model="ruleForm.introducerTwoWorkId"
                type="number"
                @keydown.native="clearInput"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="介绍老师电话" prop="introducerTwoTel">
              <el-input
                :disabled="false"
                clearable
                v-model="ruleForm.introducerTwoTel"
                type="number"
                @keydown.native="clearInput"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="6">
            <el-form-item label="就业状态" prop="status">
              <el-select v-model="ruleForm.status" placeholder="请选择就业状态">
                <el-option label="未就业" value="0"></el-option>
                <el-option label="研究生" value="1"></el-option>
                <el-option label="公务员/选调生/参军入伍" value="2"></el-option>
                <el-option label="已就业" value="3"></el-option>
                <el-option label="自主创业" value="4"></el-option>
                <el-option label="灵活就业" value="5"></el-option>
                <el-option label="自定义就业" value="6"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="就业地" prop="introducerTwoTel">
              <el-input
                :disabled="false"
                clearable
                v-model="ruleForm.introducerTwoTel"
                type="number"
                @keydown.native="clearInput"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="毕业年份" prop="endYear">
              <div class="blockYear">
                <el-date-picker
                  v-model="ruleForm.endYear"
                  type="year"
                  :clearable="false"
                >
                </el-date-picker>
              </div> </el-form-item
          ></el-col>
        </el-row>

        <!-- --------图片上传--------图片上传---------图片上传------------图片上传 -->
        <el-upload
          ref="upload"
          :limit="4"
          accept=".jpg,.gif,.png,.jpeg,.txt,.pdf,.doc,.docx,.xls,.xlsx"
          name="files"
          :multiple="true"
          action="http://182.92.109.173:8080/common/imgUpload"
          :headers="myHeader"
          :on-change="handleFileChange"
          :before-remove="handleFileRemove"
          :auto-upload="false"
          :file-list="fileList"
        >
          <el-button slot="trigger" size="small" type="primary"
            >选取文件</el-button
          >
          <div slot="tip" class="el-upload__tip">
            若改变就业状态，请上传相关图片证明（限制四张），未提交前可点击右侧按钮删除，提交后无法撤回！
          </div>
        </el-upload>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提 交</el-button
        >
      </span>
    </el-dialog>
    <el-table
      :data="tableData.filter((data) => !search || data.studentId)"
      style="width: 100%"
      @filter-change="filterChange"
    >
      <el-table-column prop="endYear" label="毕业年份" width="90">
      </el-table-column>
      <el-table-column prop="studentName" label="姓名" width="90">
      </el-table-column>
      <el-table-column prop="sex" label="性别" width="80px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.sex | getSexColorType" disable-transitions>{{
            scope.row.sex | getSexColorName
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="studentId" label="学号" width="130">
      </el-table-column>
      <el-table-column prop="college" label="学院" width="120">
      </el-table-column>
      <el-table-column prop="office" label="专业" width="140">
      </el-table-column>
      <el-table-column prop="tel" label="电话" width="120"> </el-table-column>

      <el-table-column prop="status" label="是否就业" width="190px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status | getColorType" disable-transitions>{{
            scope.row.status | getColorName
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="就业信息" width="80">
        <template slot-scope="scope">
          <el-button @click="examineClick(scope.row)" size="small"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="right">
        <template slot="header" slot-scope="scope">
          <el-input
            clearable
            v-model="search"
            :value="scope"
            maxlength="10"
            show-word-limit
            size="medium"
            placeholder="输入学号"
            @input="reseek"
          >
            <el-button
              type="primary"
              plain
              slot="append"
              icon="el-icon-search"
              @click="seek"
            ></el-button>
          </el-input>
        </template>
        <template slot-scope="scope">
          <div>
            <el-button
              @click="handleClick(scope.row)"
              size="small"
              type="primary"
              >修改</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        layout="total,prev, pager, next"
        :total="total"
        @current-change="handlePage"
        :page-size="10"
        background
        :current-page.sync="pageNum"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="就业信息"
      :visible.sync="dialogVisible2"
      width="60%"
      :before-close="handleClose3"
    >
      <template>
        <el-descriptions class="margin-top" :column="4" :size="size" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              学生名称
            </template>
            <span>{{ rowData.studentName }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              学号
            </template>
            <span>{{ rowData.studentId }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              专业
            </template>
            <span>{{ rowData.office }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              生源地
            </template>
            <span>{{ rowData.studentSource }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-information"></i>
              意向地(A)
            </template>
            <span>{{ rowData.intentionPlaceOne }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-information"></i>
              意向地(B)
            </template>
            <span>{{ rowData.intentionPlaceTwo }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(A)
            </template>
            <span>{{ rowData.jobIntentionOne }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(B)
            </template>
            <span>{{ rowData.jobIntentionTwo }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(C)
            </template>
            <span>{{ rowData.jobIntentionThree }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(C)
            </template>
            <span>{{ rowData.jobIntentionThree }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              就业地
            </template>
            <span>{{  }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师工号(A)
            </template>
            <span>{{ rowData.introducerOneWorkId }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师电话(A)
            </template>
            <span>{{ rowData.introducerOneTel }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              介绍老师名称(B)
            </template>
            <span>{{ rowData.introducerTwo }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师工号(B)
            </template>
            <span>{{ rowData.introducerTwoWorkId }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>介绍老师电话(B)
            </template>
            <span>{{ rowData.introducerTwoTel }}</span>
          </el-descriptions-item>
        </el-descriptions>
      </template>
      <!-- 展示图片 -->
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="echoPicture"
        :loading="loading"
        >查看相关附件</el-button
      >
    </el-dialog>
    <!-- 图片展示对话框 -->
    <el-dialog
      title="就业信息图片"
      :visible.sync="dialogVisible3"
      width="60%"
      :before-close="handleClose2"
    >
      <div class="demo-image">
        <div class="block" v-for="item in img64Best" :key="item.index">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            :preview-src-list="img64Best"
          ></el-image>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  filters: {
    getColorType(status) {
      const colorMap = {
        0: "danger",
        1: "",
        2: "warning",
        3: "success",
        4: "info",
        5: "info",
        6: "info"
      };
      return colorMap[status];
    },
    getColorName(status) {
      const colorMap = {
        0: "未就业",
        1: "研究生",
        2: "公务员/选调生/参军入伍",
        3: "已就业(三方协议/劳务合同)",
        4: "自主创业",
        5: "灵活就业",
        6: "自定义就业"
      };
      return colorMap[status];
    },
    getSexColorType(status) {
      const colorMap = {
        1: "primary",
        0: "danger",
      };
      return colorMap[status];
    },
    getSexColorName(status) {
      const colorMap = {
        1: "男",
        0: "女",
      };
      return colorMap[status];
    },
  },
  data() {
    return {
      upload: {
        fileList: [],
        fileName: [],
      },
      statusNew: "",
      search: "",
      studentId: null,
      endYear: null,
      teacherData:{},
      workIdUpdate: 0,
      ruleForm: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      total: 0,
      loading: false,
      tableData: [],
      rowData: [],
      pageNum: 1,
      size: "",
      fileList: [],
      imageUrl: [],
      imgUrl: [],
      imgUrlNew: "", //参数
      img64Best: [],
      office: null,
      officeN: null,
      trigger: false,
      interHTTPCollege: null,
      interCollege: null,
      options: [],
      optionsX: [], //所有学院
      optionsC: [], //学院下的专业
      optionsT: [
        {
          value: "0",
          label: "未就业",
        },
        {
          value: "1",
          label: "研究生",
        },
        {
          value: "2",
          label: "公务员/选调生/参军入伍",
        },
        {
          value: "3",
          label: "已就业(三方协议/劳务合同)",
        },
        {
          value: "4",
          label: "自主创业",
        },
        {
          value: "5",
          label: "灵活就业",
        },
        {
          value: "6",
          label: "自定义就业",
        }
      ],
      valueS: "",
      valueT: "",
      rules: {
        studentName: [
          { required: true, message: "请输入学生姓名", trigger: "blur" },
          { min: 2, max: 4, message: "长度在 2 到 4 个字符", trigger: "blur" },
        ],
        studentSource: [
          { required: true, message: "请输入学生生源地", trigger: "blur" },
        ],
        studentId: [
          { required: true, message: "请输入学生学号" },
          { min: 10, max: 10, message: "学生学号应为10位" },
        ],
        tel: [
          { required: false, message: "请输入学生电话" },
          { min: 11, max: 11, message: "电话应为11位" },
        ],
        endYear: [{ required: true, message: "请输入毕业年份" }],
        college: [{ required: true, message: "请选择学院", trigger: "change" }],
        office: [{ required: true, message: "请选择专业", trigger: "change" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        status: [
          { required: true, message: "请选择就业状态", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    handleClose3() {
      this.imgUrl = [];
      this.dialogVisible2 = false;
    },
    handleClose2() {
      this.loading = false;
      this.dialogVisible3 = false;
    },
    echoPicture() {
      if (this.imgUrl.length == 0) {
        this.$message({
          message: "该学生暂无相关附件！",
          type: "warning",
        });
      } else {
        this.loading = true;
        for (let i = 0; i < this.imgUrl.length - 1; i++) {
          this.imgUrlNew = this.imgUrl[i]; //参数imgUrl
          this.downloadPicture(i); //图片下载请求
        }
        setTimeout(() => {
          this.dialogVisible3 = true;
        }, 1500);
      }
    },
    downloadPicture(i) {
      this.$http({
        url: "/common/imgDownload",
        method: "GET",
        responseType: "blob",
        params: {
          imgUrl: this.imgUrlNew,
        },
      }).then((res) => {
        this.getBase64(res.data).then((base64) => {
          this.img64Best[i] = base64;
        });
      });
    },
    getBase64(data) {
      return new Promise((resolve, reject) => {
        const blob = new Blob([data], { type: "image/jpg" }); // 必须指定type类型
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    //选择学院时
    changeCollege(res) {
      //  this.trigger = true;
      this.ruleForm.office = null;
      this.interCollege = res;
      this.allMajor();
    },
    changeYear(val) {
      this.endYear = val;
      this.pageNum = 1;
      this.getList();
    },
    //改变专业
    handleChangeT(val) {
      this.office = val;
      this.pageNum = 1;
      this.getList();
    },
    //改变就业状态
    changeStatus(val) {
      this.statusNew = val;
      this.pageNum = 1;
      this.getList();
    },
    getCollege() {
      this.$http({
        url: "/college/valueLabel",
        method: "GET",
      })
        .then((res) => {
          this.options = res.data.data;
        })
        .catch((err) => {
        });
    },
    seek() {
      if (this.search) {
        this.studentId = this.search;
        this.getList();
      }
    },
    reseek(res) {
      if (!this.search) {
        this.studentId = null;
        this.getList();
      }
    },
    // 上传发生变化钩子
    handleFileChange(file, fileList) {
      this.upload.fileList = fileList;
    },
    // 删除之前钩子
    handleFileRemove(file, fileList) {
      this.upload.fileList = fileList;
    },
    // 封装的上传请求
    uploadFile(data) {
      return this.$http.post(
        `/common/imgUpload?endYear=` +
          this.ruleForm.endYear +
          "&office=" +
          this.ruleForm.office +
          "&studentId=" +
          this.ruleForm.studentId,
        data
      );
    },
    handleChange(res) {
      this.office = res;
    },
    clearInput(e) {
      let key = e.key;
      if (
        key === "e" ||
        key === "E" ||
        key === "+" ||
        key === "-" ||
        key === "."
      ) {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    examineClick(row) {
      this.dialogVisible2 = true;
      this.rowData = row;
      if (row.imageUrl) {
        this.imgUrl = row.imageUrl.split(",");
      }
      this.img64Best = []; //重置数组
    },
    filterChange(res) {
      if (res.status) {
        this.statusNew = res.status[0];
      }
      if (res.endYear) {
        this.endYear = res.endYear[0];
      }
      this.pageNum = 1;
      this.getList();
    },

    submitForm(formName) {
      this.$confirm(
        "请仔细检查一遍信息，是否在离开页面前保存修改？",
        "确认信息",
        {
          distinguishCancelAndClose: true,
          showClose: false,
          confirmButtonText: "确认提交",
          cancelButtonText: "放弃修改",
          beforeClose: (action, ctx, close) => {
            if (action !== "confirm") {
              close();
              return;
            }
            ctx.confirmButtonLoading = true;
            // 创建新的数据对象
            let formData = new FormData();
            // 将上传的文件放到数据对象中
            this.upload.fileList.forEach((file) => {
              formData.append("file", file.raw);
              this.upload.fileName.push(file.name);
            });
            // 文件名
            formData.append("fileName", this.upload.fileName);
            // 自定义上传
            this.uploadFile(formData)
              .then((response) => {
                this.imageUrl = response.data.data;
                close();
              })
              .finally(() => {
                ctx.confirmButtonLoading = false;
              })
              .catch(() => {
                this.$message.error("上传失败！");
              });
          },
        }
      )
        .then(() => {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              if (this.ruleForm.sex == "男") {
                this.ruleForm.sex = 1;
              }
              if (this.ruleForm.sex == "女") {
                this.ruleForm.sex = 0;
              }
              if (this.ruleForm.status == "未就业") {
                this.ruleForm.status = 0;
              }
              if (this.ruleForm.status == "已就业(三方协议/劳务合同)") {
                this.ruleForm.status = 3;
              }
              if (this.ruleForm.status == "研究生") {
                this.ruleForm.status = 1;
              }
              if (this.ruleForm.status == "自主创业") {
                this.ruleForm.status = 4;
              }
              if (this.ruleForm.status == "考公/参军") {
                this.ruleForm.status = 2;
              }
              if (this.ruleForm.status == "灵活就业") {
                this.ruleForm.status = 5;
              }
              if (this.ruleForm.status == "自定义就业") {
                this.ruleForm.status = 6;
              }
              if (!this.ruleForm.introducerOne) {
                this.ruleForm.introducerOne = "";
              }
              if (!this.ruleForm.intentionPlaceTwo) {
                this.ruleForm.intentionPlaceTwo = "";
              }
              if (!this.ruleForm.intentionPlaceOne) {
                this.ruleForm.intentionPlaceOne = "";
              }
              if (!this.ruleForm.introducerOneWorkId) {
                this.ruleForm.introducerOneWorkId = "";
              }
              if (!this.ruleForm.introducerOneTel) {
                this.ruleForm.introducerOneTel = "";
              }
              if (!this.ruleForm.introducerTwo) {
                this.ruleForm.introducerTwo = "";
              }
              if (!this.ruleForm.introducerTwoWorkId) {
                this.ruleForm.introducerTwoWorkId = "";
              }
              if (!this.ruleForm.introducerTwoTel) {
                this.ruleForm.introducerTwoTel = "";
              }

              this.submit();
              this.dialogVisible = false;
              this.$message({
                type: "success",
                message: "保存修改,请留意进度!",
              });
            } else {
              this.$message({
                type: "info",
                message: "表格错误",
              });
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃修改" : "停留在当前页面",
          });
        });
    },
    handleClick(row) {
      this.collegeAll();
      this.interCollege = row.college;
      this.dialogVisible = true;
      this.ruleForm = JSON.parse(JSON.stringify(row));
      if (this.ruleForm.sex == 1) {
        this.ruleForm.sex = "男";
      } else {
        this.ruleForm.sex = "女";
      }
      if (this.ruleForm.status == 0) {
        this.ruleForm.status = "未就业";
      }
      if (this.ruleForm.status == 1) {
        this.ruleForm.status = "研究生";
      }
      if (this.ruleForm.status == 2) {
        this.ruleForm.status = "公务员/选调生/参军入伍";
      }
      if (this.ruleForm.status == 3) {
        this.ruleForm.status = "已就业(三方协议/劳务合同)";
      }
      if (this.ruleForm.status == 4) {
        this.ruleForm.status = "自主创业";
      }
      if (this.ruleForm.status == 5) {
        this.ruleForm.status = "灵活就业";
      }
      if (this.ruleForm.status == 6) {
        this.ruleForm.status = "自定义就业";
      }
      this.allMajor();
      this.upload.fileList = [];
      this.upload.fileName = [];
    },
    handlePage(res) {
      this.pageNum = res;
      this.getList();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.$refs.upload.clearFiles();
          done();
        })
        .catch((_) => {});
    },

    getList() {
      this.$http({
        url: "/students",
        method: "POST",
        params: {
          pageNum: this.pageNum,
          pageSize: 10,
        },
        data: {
          status: this.statusNew,
          endYear: this.endYear,
          studentId: this.studentId,
          office: this.office,
        },
      })
        .then((res) => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total || 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    collegeAll() {
      this.$http({
        url: "/college/all",
        method: "GET",
      }).then((res) => {
        this.optionsX = res.data.data;
      });
    },
    allMajor() {
      this.$http({
        url: "/college/allMajor",
        method: "GET",
        params: {
          college: this.interCollege,
        },
      }).then((res) => {
        this.optionsC = res.data.data;
      });
    },
    submit() {
      this.$http({
        url: "/students/needAudit",
        method: "POST",
        data: {
          studentNumber: this.ruleForm.id, //学生id
          studentId: this.ruleForm.studentId, //学生学号
          studentName: this.ruleForm.studentName, //学生姓名
          tel: this.ruleForm.tel, //学生电话
          sex: this.ruleForm.sex, //学生性别（男1 女0）
          office: this.ruleForm.office, //学生专业
          college: this.ruleForm.college, //学生所属学院
          endYear: this.ruleForm.endYear, //学生毕业年份
          status: this.ruleForm.status, //学生就业状态
          studentSource: this.ruleForm.studentSource, //生源地
          intentionPlaceOne: this.ruleForm.intentionPlaceOne,
          intentionPlaceTwo: this.ruleForm.intentionPlaceTwo,
          jobIntentionOne: this.ruleForm.jobIntentionOne, //就业意向1
          jobIntentionTwo: this.ruleForm.jobIntentionTwo, //就业意向2
          jobIntentionThree: this.ruleForm.jobIntentionThree, //就业意向3
          introducerOne: this.ruleForm.introducerOne, //指导老师1姓名
          introducerOneTel: this.ruleForm.introducerOneTel, //指导老师1电话
          introducerOneWorkId: this.ruleForm.introducerOneWorkId, //指导老师1工号
          introducerTwo: this.ruleForm.introducerTwo, //指导老师2姓名
          introducerTwoTel: this.ruleForm.introducerTwoTel, //指导老师2电话
          introducerTwoWorkId: this.ruleForm.introducerTwoWorkId, //指导老师2工号
          imageUrl: this.imageUrl, //图片
          workIdUpdate: this.workIdUpdate, //账号（修改者）老师工号
          updateTeacher: this.teacherData.teacher_name,
        },
      })
        .then((res) => {
          //  this.getList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    myHeader: function () {
      return { token: window.sessionStorage.getItem("token") };
    },
  },
  mounted() {
    this.getList();
    this.getCollege();
    this.workIdUpdate = window.sessionStorage.getItem("workIdUpdate");
    this.teacherData =JSON.parse(window.sessionStorage.getItem("teacherData"));
  },
};
</script>

<style scoped >
body {
  margin: 0;
}
.block {
  text-align: center;
  padding: 30px 0;
}
blockYear {
  padding: 0px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.block[data-v-17f2e808] {
  padding: 0;
}
</style>
  