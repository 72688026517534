<script>

export default {
	data() {
		return {
			activeName: 'first',
			chart: null,
			total: 0,
			currentPage: 1,
			valueYear: (new Date().getFullYear()-1).toString(),
			pageSize: 10,
			data: [],// 将数据存储在data属性中
			currentPageData: [],
			chartOptions: {
				title: {
					text: '指导教师奖金',
					x: 'center',
					// 调整标题样式
					textStyle: {
						fontSize: 18,
						fontWeight: 'bold',
						color: '#333'
					}
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				// echarts提供的工具箱
				toolbox: {
					feature: {
						dataView: {show: true, readOnly: false},
						magicType: {show: true, type: ['bar']},
						restore: {show: true},
						saveAsImage: {show: true}
					}
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '15%', // 调整图表底部留白
					containLabel: true
				},
				xAxis: {
					type: 'category',
					axisLabel: {
						interval: 0,
						rotate: 0, // 调整标签显示方向为水平
						fontSize: 12 // 调整标签字体大小
					},
					axisTick: {
						alignWithLabel: true
					},
					axisLine: {
						lineStyle: {
							color: '#999' // 调整轴线颜色
						}
					}
				},
				yAxis: {
					type: 'log',
					logBase: 2, // 设置对数的基数为2
					axisLabel: {
						fontSize: 12 // 调整标签字体大小
					},
					axisLine: {
						lineStyle: {
							color: '#999' // 调整轴线颜色
						}
					},

				},
				series: [{
					type: 'bar',
					name: '指导教师奖金',
					itemStyle: {
						// color: '#3580cd', // 调整柱状图颜色
						barBorderRadius: [5, 5, 0, 0] // 设置柱状图的边缘为圆角
					}
				}]
			}
		}
	},
	mounted() {
		const echarts = this.$echarts
		this.chart = echarts.init(this.$refs.chart)
		// 在mounted钩子中加载数据
		this.loadData()

		// 监听窗口大小变化事件，调用 resize 方法
		window.addEventListener('resize', () => {
			this.chart.resize()
		})

	},
	methods: {
		async loadData() {
			try {
				const res = await this.$http.get('/echarts/TeacherBonus', {
					params: {
						year: this.valueYear
					}
				})
				this.data = await res.data.data;
				this.total = this.data.length;
				this.data.sort((a, b) => b.bonus - a.bonus); // 对数据进行降序排序

				this.renderChart();// 数据加载完成后渲染图表
			} catch (error) {
				this.data = []
				console.error(error)
			}
		},
		renderChart() {
			if (this.data.length === 0) {
				// this.chart.clear();
        this.chart.showLoading({
          text: '暂无数据',
          color: '#999',
          textColor: '#333',
          maskColor: 'rgba(255, 255, 255, 0.8)',
          zlevel: 0,
		  textStyle: {
          fontSize: 16 // 调整字体大小
          }
        });
        return;
			}
			this.chart.hideLoading(); // 隐藏加载提示
			const startIndex = (this.currentPage - 1) * this.pageSize;
			const endIndex = Math.min(startIndex + this.pageSize, this.data.length);
			this.currentPageData = this.data.slice(startIndex, endIndex);

			// 只更新当前页的数据，不影响图表类型
			this.chartOptions.xAxis.data = this.currentPageData.map(item => item.teaName);
			this.chartOptions.series[0].data = this.currentPageData.map(item => item.bonus);

			this.chart.setOption(this.chartOptions);
		},
		handleCurrentChange(page) {
			this.currentPage = page;
			this.renderChart();
		},
		// 选择年份的方法
		selectYear(val) {
			this.valueYear = val;
			this.loadData()
		}
	}
}
</script>

<template>
	<div class="com-container">
		<el-row>
			<el-col :span="24">
				<el-card class="box-card">
					<el-date-picker
							v-model="valueYear"
							placeholder="选择年"
							type="year"
							value-format="yyyy"
							@change="selectYear(valueYear)"
					></el-date-picker>
					<!-- 响应式布局 -->
					<el-row>
						<el-col :span="24">
							<div ref="chart" class="chart"></div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-pagination
									:current-page="currentPage"
									:page-size="pageSize"
									:total="total"
									background
									class="page"
									layout="prev, pager, next"
									@current-change="handleCurrentChange"
							>
							</el-pagination>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
		</el-row>
	</div>

</template>

<style lang="less" scoped>

.com-container {
	padding-top: 10px;

	.page {
		text-align: center;
		margin-top: 10px;
	}

	.chart {
		width: 80%;
		height: 600px;
		text-align: center;
		margin: 0 auto;
	}
}
</style>

