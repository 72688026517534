<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <el-container>
    <el-header>
      <TeacherCommonHeader></TeacherCommonHeader>
    </el-header>
    <el-container>
      <el-aside width="201px">
        <TeacherCommonAside></TeacherCommonAside>
      </el-aside>
      <el-main>
        <!-- 路由出口 -->
        <!-- 路由匹配到的组件将渲染在这里 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import TeacherCommonHeader from "../../components/TeacherCommonHeader.vue";
import TeacherCommonAside from "../../components/TeacherCommonAside.vue";

export default {
  components: {
    TeacherCommonAside,
    TeacherCommonHeader,
  },
};
</script>

<style>
body {
  min-width: 800px;

}

.el-aside {
  background-color: #ecf5ff;
  
}
.el-header {
  background-color: #409eff;
}
.el-main{
  
}

</style>