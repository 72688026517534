import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import * as echarts from 'echarts';
import moment from 'moment';

Vue.prototype.$moment = moment;
// 定位中国使用中文
moment.locale('zh-cn');

Vue.prototype.$echarts = echarts;
axios.defaults.baseURL = 'http://115.159.207.97:8080'
//axios.defaults.baseURL = 'http://127.0.0.1:8080'
Vue.prototype.$http = axios;
Vue.use(ElementUI);
Vue.config.productionTip = false;


axios.interceptors.request.use((config) => {
	config.headers.token = window.sessionStorage.getItem('token');
	const currentPageUrl = window.location.pathname;
	config.headers.currentUrl = currentPageUrl;
	return config;
});


axios.interceptors.response.use((response) => {
			return response;
		}, (error) => {
			// 处理错误的响应
			if (error.response) {
				// 判断响应状态码
				const {status} = error.response;
				let alreadyShowedUnauthorizedAlert = false;
				if (error.response && error.response.status === 401 && !alreadyShowedUnauthorizedAlert) {
					alreadyShowedUnauthorizedAlert = true;
					window.location.href = '/login';
				}
			}
			// 重新抛出错误，以便外部的 catch 块可以捕获
			return Promise.reject(error);
		}
);
// alert('无权限访问！将为您跳转到登入页面')
new Vue({
	router,
	render: (h) => h(App)
}).$mount('#app');

