<template>
  <el-row :gutter="20">
    <el-col :span="20"
      ><div class="grid-content bg-purple">
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple" style="display: flex">
              <img
                src="../assets/images/logo.png"
                alt=""
                width="40px"
                style="margin: 10px"
              />
              <span
                style="
                  font-size: 20px;
                  align-items: center;
                  margin: 15px 0;
                  color: white;
                "
                >河南开封科技传媒学院</span
              >
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="4"
      ><div class="grid-content bg-purple">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#8cc5ff"
          text-color="#fff"
          active-text-color="#ffffff"
        >
          <el-submenu index="">
            <template slot="title"
              ><i class="el-icon-cpu"></i>我的工作台</template
            >
            <el-menu-item index="1"
              ><i class="el-icon-user"></i>个人信息</el-menu-item
            >
            <el-menu-item index="2"
              ><i class="el-icon-edit"></i>修改个人信息</el-menu-item
            >
            <el-menu-item index="3"
              ><i class="el-icon-edit-outline"></i>修改个人密码</el-menu-item
            >
            <el-menu-item index="4"
              ><i class="el-icon-refresh-left"></i>退出登入</el-menu-item
            >
            <el-menu-item index="5"
              ><i class="el-icon-user"></i>切换身份</el-menu-item
            >
          </el-submenu>
        </el-menu>
      </div>
    </el-col>
    <!-- 个人信息对话框 -->
    <el-dialog
      title="个人信息"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose1"
      @open="openDialog"
    >
      <el-descriptions class="margin-top" :column="3" :size="size" border>
        <!--  <template slot="extra">
          <el-button type="primary" size="small">操作</el-button>
        </template> -->
        <el-descriptions-item label="管理员名称">{{
          managerData.manager_name
        }}</el-descriptions-item>

        <el-descriptions-item label="管理员工号">{{
          managerData.worker_id
        }}</el-descriptions-item>

        <el-descriptions-item label="管理员电话">{{
          managerData.tel
        }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible1 = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改个人信息对话框 -->
    <el-dialog
      title="修改个人信息"
      :visible.sync="dialogVisible2"
      width="60%"
      :before-close="handleClose2"
      @open="openDialog"
    >
      <el-form
        :model="managerData"
        :rules="rules"
        ref="managerData"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col 
            ><div class="grid-content bg-purple">
              <el-form-item label="管理员名称" prop="manager_name">
                <el-input v-model="managerData.manager_name"></el-input>
              </el-form-item></div
          ></el-col>
          <!-- <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="性别" prop="sex">
                <el-select v-model="managerData.sex" placeholder="请选择">
                  <el-option label="男" value="1"></el-option>
                  <el-option label="女" value="0"></el-option>
                </el-select>
              </el-form-item></div
          ></el-col> -->
        </el-row>
        <el-row>
          <el-col 
            ><div class="grid-content bg-purple">
              <el-form-item label="工号" prop="worker_id">
                <el-input v-model="managerData.worker_id"></el-input>
              </el-form-item></div
          ></el-col>

          <!-- <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="学院" prop="college">
                <el-select
                  v-model="teacherData.college"
                  placeholder="请选择学院"
                >
                  <el-option
                    label="信息工程学院"
                    value="信息工程学院"
                  ></el-option>
                  <el-option label="商学院" value="商学院"></el-option>
                </el-select>
              </el-form-item></div
          ></el-col> -->
        </el-row>
        <el-row>
          <el-col 
            ><div class="grid-content bg-purple">
              <el-form-item label="电话" prop="tel">
                <el-input v-model="managerData.tel"></el-input>
              </el-form-item></div
          ></el-col>
          <!-- <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="专业" prop="office">
                <div class="block">
                  <el-cascader
                    placeholder="请选择专业"
                    :show-all-levels="false"
                    v-model="office"
                    :options="options"
                    :props="{ expandTrigger: 'hover', emitPath: false }"
                    @change="handleChange"
                  ></el-cascader>
                </div>
              </el-form-item></div
          ></el-col> -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button
          type="primary"
          :plain="true"
          @click="submitForm1('managerData')"
          >提交修改</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible3"
      width="60%"
      :before-close="handleClose3"
      :destroy-on-close="true"
    >
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="新密码" prop="pass">
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            type="password"
            v-model="ruleForm.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close3">取 消</el-button>
        <el-button type="primary" :plain="true" @click="submitForm2('ruleForm')"
          >提 交</el-button
        >
      </span>
    </el-dialog>
  </el-row>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      office: [],
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      managerData: [],
      activeIndex: "1",
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      size: "",
      options: [
        {
          value: "信息工程学院",
          label: "信息工程学院",
          children: [
            {
              value: "计算机科学与技术",
              label: "计算机科学与技术",
            },
          ],
        },
        {
          value: "理工学院",
          label: "理工学院",
          children: [
            {
              value: "计算机科学与技术",
              label: "计算机科学与技术",
            },
          ],
        },
        {
          value: "商学院",
          label: "商学院",
          children: [
            {
              value: "会计学",
              label: "会计学",
            },
          ],
        },
        {
          value: "外语学院",
          label: "外语学院",
          children: [
            {
              value: "英语",
              label: "英语",
            },
          ],
        },
        {
          value: "人文学院",
          label: "人文学院",
          children: [
            {
              value: "书法专业",
              label: "书法专业",
            },
          ],
        },
      ],
      rules: {
        teacher_name: [
          /*  { required: true, message: "请输入活动名称", trigger: "blur" }, */
          { min: 2, max: 4, message: "长度在 2 到 4 个字符", trigger: "blur" },
        ],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    openDialog() {
      this.managerData = JSON.parse(
        window.sessionStorage.getItem("managerData")
      );
    },
    close3() {
      this.ruleForm.pass = null;
      this.ruleForm.checkPass = null;
      this.dialogVisible3 = false;
    },
    submitForm2(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitPass();
          this.dialogVisible3 = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose3(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.ruleForm.pass = null;
          this.ruleForm.checkPass = null;
          this.dialogVisible3 = false;
        })
        .catch((_) => {});
    },
    handleChange(res) {
      this.office = res;
    },
    submitForm1(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if (this.teacherData.sex == "男") {
          //   this.teacherData.sex = "1";
          // }
          // if (this.teacherData.sex == "女") {
          //   this.teacherData.sex = "0";
          // }
          this.submit();
          this.dialogVisible2 = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSelect(key, keyPath) {
      if (keyPath == "1") {
        // console.log("打开个人信息弹框");
        // if (this.teacherData.sex == 1 || this.teacherData.sex == "男") {
        //   this.teacherData.sex = "男";
        //   this.dialogVisible1 = true;
        // } else {
        //   this.teacherData.sex = "女";
        this.dialogVisible1 = true;
        // }
      }
      if (keyPath == "2") {
        // console.log("打开修改个人信息弹框");
        // if (this.teacherData.sex == 1 || this.teacherData.sex == "男") {
        //   this.teacherData.sex = "男";
        // } else {
        //   this.teacherData.sex = "女";
        // }
        this.dialogVisible2 = true;
      }
      if (keyPath == "3") {
        // console.log("打开修改密码弹框");
        this.dialogVisible3 = true;
      }
      if (keyPath == "4") {
        this.$router.push("/Login");
        this.$message.success("退出成功");
        sessionStorage.clear();
      }
      if (keyPath == "5") {
        location.href = "hnkczhaojiu.com ";
      }
    },
    handleClose1(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleClose2(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    close() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    submit() {
      this.$http({
        url: "/manager",
        method: "PUT",
        data: {
          id: this.managerData.id,
          managerName: this.managerData.manager_name,
          workerId: this.managerData.worker_id,
          // sex: this.managerData.sex,
          tel: this.managerData.tel,
          // college: this.managerData.college,
          // office: this.office,
        },
      })
        .then((res) => {
          // console.log("修改管理员信息成功", res);
          window.sessionStorage.setItem(
            "managerData",
            JSON.stringify(this.managerData)
          );
          this.open1();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitPass() {
      this.$http({
        url: "/manager",
        method: "PUT",
        data: {
          id: this.managerData.id,
          managerName: this.managerData.manager_name,
          workerId: this.managerData.worker_id,
          // sex: this.managerData.sex,
          tel: this.managerData.tel,
          college: this.managerData.college,
          // office: this.office,
          password: this.ruleForm.checkPass,
        },
      })
        .then((res) => {
          // console.log("修改管理员密码成功", res);
          this.open2();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    open1() {
      this.$message({
        message: "个人信息修改成功！！！",
        type: "success",
      });
    },
    open2() {
      this.$message({
        message: "账号密码修改成功！！！",
        type: "success",
      });
    },
  },
  mounted() {
    this.managerData = JSON.parse(window.sessionStorage.getItem("managerData"));
    // console.log("教师信息2：", this.teacherData);
    // this.office = this.managerData.office;
    // console.log("dangqian", this.office);

    /*  //下标0为一级菜单value
    this.office[0] = this.teacherData.college;
    //下标1为二级菜单value
    this.office[1] = this.teacherData.office;
   */
  },
};
</script>

<style scoped lang="less">
.el-button--text {
  color: rgb(0, 9, 9);
}
.el-menu-demo {
  width: 80px;
}
img {
  border-radius: 50%;
}
</style>
