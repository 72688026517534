<template class="background-bj">
	<div id="app">
		<!-- 路由出口 -->
		<!-- 路由匹配到的组件将渲染在这里 -->
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'App',
	components: {}
};
</script>

<style>

body {
	margin: 0px;
}

.app {
	min-width: 1024px;
}
</style>
