<script>

import AwardsByOffice from '@/views/Teacher/AwardsByOffice.vue';

export default {
	name: 'AwardsByCollege',
	components: {
		AwardsByOffice
	},
	data() {
		return {
			chart: null,
			valueYear: (new Date().getFullYear()-1).toString(),
			chartOption: {
				title: {
					text: '各学院指导奖金',
					subtext: '点击饼状图可以查看详细页面',
					left: 'center'
				},
				tooltip: {
					trigger: 'item'
				},
				legend: {
					orient: 'vertical',
					left: 'left'
				},
				series: [
					{
						name: '',
						type: 'pie',
						radius: '50%',
						minAngle: 10, //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				],
			},
			// 学院指导奖金数据
			collegeTeacherBonus: null,
			// 用于存储用户点击的学院名称
			selectedCollegeName: null,
			// 控制是否显示 AwardsByOffice 组件
			showAwardsByOffice: false
		}
	},
	mounted() {
		const echarts = this.$echarts
		this.chart = echarts.init(this.$refs.chart)
		this.loadData()
		this.renderChart()

		// 监听窗口大小变化事件，调用 resize 方法
		window.addEventListener('resize', () => {
			this.chart.resize();
		});

		// 添加点击事件监听器
		this.chart.on('click', this.handleChartClick);
	},
	methods: {
		async loadData() {
			try {
				const res = await this.$http.get('/echarts/CollegeTeacherBonus', {
					params: {
						endYear: this.valueYear
					}
				})
				this.collegeTeacherBonus = res.data.data
				this.updateChart()
			} catch (error) {
				this.collegeTeacherBonus = []
				console.error(error)
			}
		},
		// 根据后端返回的数据更新图表
		updateChart() {
			if (!this.collegeTeacherBonus) {
				// 如果数据为空，显示"暂无数据"
				// this.chart.clear();
        this.chart.showLoading({
          text: '暂无数据',
          color: '#999',
          textColor: '#333',
          maskColor: 'rgba(255, 255, 255, 0.8)',
          zlevel: 0,
		  textStyle: {
          fontSize: 16 // 调整字体大小
          }
        });
        return;
			}
			// 根据学院指导奖金数据更新图表数据
			const chartData = [];

			// 遍历每个学院
			for (const college in this.collegeTeacherBonus) {
				// 当前学院的总奖金数，初始化为0
				let collegeTotalBonus = 0;
				// 获取当前学院下的所有专业信息
				const departments = this.collegeTeacherBonus[college];

				// 遍历当前学院下的每个专业
				for (const department in departments) {
					// 获取当前专业下的所有指导老师信息
					const teachers = departments[department]
					// 当前专业的总奖金数，初始化为0
					let departmentTotalBonus = 0

					// 遍历当前专业下的每个指导老师
					for (const teacherName in teachers) {
						// 计算当前指导老师的奖金总数
						const teacherTotalBonus = parseInt(teachers[teacherName][0].bonus);						// 累加到当前专业的总奖金数上
						departmentTotalBonus += teacherTotalBonus;
					}

					// 累加到当前学院的总奖金数上
					collegeTotalBonus += departmentTotalBonus;
				}

				// 将当前学院的总奖金数添加到图表数据数组中
				chartData.push({value: collegeTotalBonus, name: college});
			}

			// 更新图表数据
			this.chartOption.series[0].data = chartData;
			// 渲染图表
			this.chart.hideLoading(); // 隐藏加载提示
			this.renderChart();

		},
		renderChart() {
			this.chart.setOption(this.chartOption);
		},

		handleChartClick(params) {
			if (params.data && params.data.name) {
				const collegeName = params.data.name;
				// 将用户点击的学院名称赋值给selectedCollegeName属性
				this.selectedCollegeName = collegeName
				// 点击后显示 AwardsByOffice 组件
				this.showAwardsByOffice = true
				const chartData = this.updateChartData(collegeName);
				if (chartData) {
					// 更新图表数据
					this.chartOption.series[0].data = chartData;
					this.renderChart();
				} else {
					console.error('No department information found for college:', collegeName);
				}
			}
		},
		// 处理点击事件，导航到 AwardsByOffice 组件，并传递学院名称作为路由参数
		navigateToAwardsByOffice() {
			if (this.selectedCollegeName) {
				this.$router.push({name: 'AwardsByOffice', params: {collegeName: this.selectedCollegeName}});
			}
		},
		// 新的方法用于更新特定学院的图表数据
		updateChartData(collegeName) {
			// 获取选择的学院下各个专业的信息
			const departmentInfo = this.collegeTeacherBonus[collegeName];
			if (departmentInfo) {
				const departmentData = {};
				for (const department in departmentInfo) {
					const teachers = departmentInfo[department];
					// 遍历每个老师
					for (const teacher in teachers) {
						const teacherName = teacher.teaName;
						const bonus = parseInt(teacher.bonus);
						// 使用老师姓名作为键，如果已经存在该老师的奖金，则累加奖金；否则，初始化为0
						departmentData[teacherName] = (departmentData[teacherName] || 0) + bonus;
					}
				}
				// 格式化数据为图表所需的格式
				const chartData = Object.entries(departmentData).map(([teacherName, bonus]) => {
					return {value: bonus, name: teacherName};
				})
				return chartData;
			} else {
				return null;
			}
		},
		goBack() {
			// 返回时隐藏 AwardsByOffice 组件
			this.$set(this, 'showAwardsByOffice', false);
			this.loadData()

		},
		// 选择年份的方法
		selectYear(val) {
			this.valueYear = val
			this.loadData()
		}
	},
}
</script>

<template>
	<div class="com-container">
		<el-row>
			<el-col :span="24">
				<el-card class="box-card">
					<el-date-picker
							v-if="!showAwardsByOffice"
							v-model="valueYear"
							placeholder="选择年"
							type="year"
							value-format="yyyy"
							@change="selectYear(valueYear)"
					></el-date-picker>
					<!-- 响应式布局 -->
					<el-row>
						<el-col :span="24">
							<div ref="chart" class="chart"></div>
						</el-col>
					</el-row>
					<el-row v-if="!showAwardsByOffice">
						<el-col :span="24">
							<div ref="chart" class="chart-container"></div>
						</el-col>
					</el-row>
					<el-row v-else>
						<el-col :span="24">
							<!-- 添加点击事件，点击时跳转到 AwardsByOffice 组件，并传递学院名称作为参数 -->
							<AwardsByOffice v-if="showAwardsByOffice"
							                :college-name="selectedCollegeName"
							                :college-teacher-bonus="collegeTeacherBonus"
							                @goBackEvent="goBack"
							                @office-click="navigateToAwardsByOffice"/>

						</el-col>
					</el-row>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<style lang="less" scoped>
.chart-container {
	width: 100%;
	height: 400px; /* 设置初始高度 */
}
</style>