<script>

export default {
	name: 'AwardsByOffice',
	props: {
		collegeName: {
			type: String,
			required: true,
			default: ''
		},
		collegeTeacherBonus: {
			type: Object,
			required: true,
			default: () => ({})
		}
	},
	data() {
		return {
			chart: null,
			chartOption: {
				title: {
					text: '各专业指导奖金',
					subtext: '点击饼状图可以查看详细页面',
					left: 'center'
				},
				tooltip: {
					trigger: 'item'
				},
				legend: {
					orient: 'vertical',
					left: 'left'
				},
				series: [
					{
						name: '',
						type: 'pie',
						radius: '50%',
						minAngle: 10, //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			}
		};
	},

	mounted() {
		const echarts = this.$echarts
		this.chart = echarts.init(this.$refs.chart)
		this.renderChart()
		// 监听窗口大小变化事件，调用 resize 方法
		window.addEventListener('resize', () => {
			this.chart.resize();
		});

	},
	methods: {
		renderChart() {
			const departmentInfo = this.collegeTeacherBonus[this.collegeName];
			if (!departmentInfo) {
				console.error(`No data found for college: ${this.collegeName}`)
				return; // 添加返回语句以避免后续代码执行
			}
			const departmentData = {};
			if (departmentInfo) {
				for (const department in departmentInfo) {
					const teachers = departmentInfo[department];
					let totalBonus = 0;
					for (const teacherName in teachers) {
						totalBonus += parseInt(teachers[teacherName][0].bonus);

					}
					departmentData[department] = totalBonus;
				}
			}

			const chartData = [];
			for (const department in departmentData) {
				chartData.push({value: departmentData[department], name: department});
			}

			this.chartOption.legend.data = chartData.map(item => item.name)
			this.chartOption.series[0].data = chartData
			this.chart.setOption(this.chartOption);
		},
		// 在子组件中点击返回按钮时触发该方法
		goBack() {
			this.$emit('goBackEvent');
		}
	}
}
</script>

<template>
	<div>
		<el-row>
			<el-col :span="24">
				<div ref="chart" class="chart-container"></div>
			</el-col>
		</el-row>
	</div>
</template>

<style lang="less" scoped>
.chart-container {
	width: 100%;
	height: 400px; /* 设置初始高度 */
}
</style>