<template>
  <div>
    <el-row style="padding: 20px">
      <el-col :span="6"
        ><div class="grid-content bg-purple">
          <div class="block">
            <el-date-picker
              v-model="endYear"
              type="year"
              format="yyyy"
              value-format="yyyy"
              placeholder="选择年"
              @change="changeYear"
            >
            </el-date-picker>
          </div></div
      ></el-col>
      <el-col :span="6"
        ><div class="grid-content bg-purple-light">
          <div class="block">
            <el-cascader
              clearable
              placeholder="请选择专业"
              :show-all-levels="false"
              v-model="major"
              :options="options"
              :props="{ expandTrigger: 'hover', emitPath: false }"
              @change="handleChange"
            ></el-cascader>
          </div></div
      ></el-col>
    </el-row>
    <el-button type="primary" @click="addCollege" round>添加</el-button>
    <el-table :data="yearList" style="width: 100%" stripe>
      <el-table-column prop="endYear" label="年份" width="150px" align="center">
      </el-table-column>
      <el-table-column
        prop="college"
        label="所属学院"
        width="300px"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="major" label="专业" width="180px" align="center">
      </el-table-column>
      <el-table-column
        prop="rate"
        label="就业率(%)"
        width="150px"
        align="center"
      >
      </el-table-column>
      <el-table-column prpo="audit" label="操作" width="150px" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            round
            size="mini"
            @click="showEditDialog(scope.row)"
            >修改</el-button
          >
          <el-button
            type="danger"
            round
            size="mini"
            @click="removeRateById(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- <div class="div2">
      <el-pagination layout="prev, pager, next" :total="1000"> </el-pagination>
    </div> -->

    <!-- 增加就业率的对话框 -->
    <el-dialog
      title="添加就业率"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
      :destroy-on-close="true"
    >
      <!-- 主体区 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="80px"
      >
        <el-form-item label="年份" prop="endYear">
          <!-- <el-input v-model="addForm.endYear"></el-input> -->
          <div class="block">
            <el-date-picker
              v-model="addForm.endYear"
              type="year"
              placeholder="选择年"
              value-format="yyyy"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="所属学院" prop="college">
          <!-- <el-input v-model="addForm.college"></el-input> -->
          <el-select
            v-model="addForm.college"
            placeholder="请选择所属学院"
            @change="getMajor"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业" prop="major">
          <!-- <el-input v-model="addForm.major"></el-input> -->
          <el-select v-model="addForm.major" placeholder="请选择专业">
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="就业率" prop="rate">
          <!-- <el-input v-model="addForm.rate"></el-input> -->
          <div class="block">
            <el-slider v-model="addForm.rate" show-input> </el-slider>
          </div>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addFormClose">取 消</el-button>
        <el-button type="primary" @click="addRate">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改就业率的对话框 -->
    <el-dialog
      title="修改就业率"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 主体区 -->
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="90px"
      >
        <el-form-item label="年份" prop="endYear">
          <!-- <el-input v-model="editForm.endYear"></el-input> -->
          <div class="block">
            <el-date-picker
              v-model="editForm.endYear"
              type="year"
              placeholder="选择年"
              value-format="yyyy"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="所属学院" prop="college">
          <!-- <el-input v-model="editForm.college"></el-input> -->
          <el-select
            v-model="editForm.college"
            placeholder="请选择所属学院"
            @change="getMajor2"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业" prop="major">
          <!-- <el-input v-model="editForm.major"></el-input> -->
          <el-select v-model="editForm.major" placeholder="请选择专业">
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="就业率" prop="rate">
          <!-- <el-input v-model="editForm.rate"></el-input> -->
          <div class="block">
            <el-slider v-model="editForm.rate" show-input> </el-slider>
          </div>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editFormClose">取 消</el-button>
        <el-button type="primary" @click="editRate">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分页效果 -->
    <div class="block">
      <el-pagination
        layout="total,prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="handleCurrentChange"
        background
        :current-page.sync="addForm.pageNum"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // label: null,
      endYear: null,
      college: null,
      major: null,
      rate: null,
      pageNum: 1,
      options: [],
      options2: [],
      options3: [],
      // queryInfo: {
      //   // 当前页数
      //   pagenum: 1,
      //   // 当前页面条数
      //   pagesize: 10,
      // },
      yearList: [],
      total: 0,
      // 控制添加就业率对话框的显示与隐藏
      addDialogVisible: false,
      // 控制修改就业率对话框的显示与隐藏
      editDialogVisible: false,
      // 添加就业率的表单数据
      addForm: {
        endYear: null,
        college: null,
        major: null,
        rate: null,
        pageNum: 0,
        pageSize: 10,
      },
      // 添加表单的验证规则对象
      addFormRules: {
        endYear: [
          {
            required: true,
            message: "请输入年份",
            trigger: "blur",
          },
          {
            min: 4,
            max: 4,
            message: "年份必须为四位数",
            trigger: "blur",
          },
        ],
        college: [
          {
            required: true,
            message: "请输入所属学院",
            trigger: "blur",
          },
        ],
        major: [
          {
            required: true,
            message: "请输入专业",
            trigger: "blur",
          },
        ],
        rate: [
          {
            required: true,
            message: "请输入就业率",
            trigger: "blur",
          },
          // {
          //   min: 1,
          //   max: 2,
          //   message: "就业率必须两位数",
          //   trigger: "blur",
          // },
        ],
      },
      // 查询到的就业率信息对象
      editForm: {
        endYear: null,
        rate: null,
        college: null,
        major: null,
      },
      editFormRules: {
        endYear: [
          {
            required: true,
            message: "请输入年份",
            trigger: "blur",
          },

          {
            min: 4,
            max: 4,
            message: "年份必须为四位数",
            trigger: "blur",
          },
        ],
        college: [
          {
            required: true,
            message: "请输入所属学院",
            trigger: "blur",
          },
        ],
        major: [
          {
            required: true,
            message: "请输入专业",
            trigger: "blur",
          },
        ],
        rate: [
          {
            required: true,
            message: "请输入就业率",
            trigger: "blur",
          },
        ],
      },
      deleteForm: {
        id: null,
      },
    };
    // queryAdd{}
  },
  created() {
    this.getYear();
    this.getCollege();
    this.getAllCollege();
  },
  methods: {
    addCollege() {
      this.addForm.endYear = null;
      this.addForm.major = null;
      this.addDialogVisible = true;
    },
    addFormClose() {
      this.addDialogVisible = false;
      this.options3 = null;
    },
    editFormClose() {
      this.editDialogVisible = false;
      this.options3 = null;
    },
    getMajor(res) {
      this.addForm.major = null;
      // console.log(res);
      this.$http({
        url: "/college/allMajor",
        method: "GET",
        params: {
          college: res,
        },
      })
        .then((res) => {
          this.options3 = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMajor2(res) {
      this.editForm.major = null; // console.log(res);
      this.$http({
        url: "/college/allMajor",
        method: "GET",
        params: {
          college: res,
        },
      })
        .then((res) => {
          this.options3 = res.data.data;
          // console.log(this.options3+"33333");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //改变毕业年份
    changeYear(val) {
      this.addForm.endYear = val;
      this.pageNum = 1;
      this.getYear();
    },
    handleChange(val) {
      this.addForm.major = val;
      this.addForm.pageNum = 1;
      this.getYear();
    },
    //查询所有学院
    getAllCollege() {
      this.$http({
        url: "/college/all",
        method: "GET",
      })
        .then((res) => {
          this.options2 = res.data.data;
          // console.log(this.options2+"22222");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //请求专业
    getCollege() {
      this.$http({
        url: "/college/valueLabel",
        method: "GET",
      })
        .then((res) => {
          this.options = res.data.data;
          // console.log("options:", this.options);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getYear() {
      this.$http({
        url: "/jobRate/getByCondition",
        method: "POST",
        params: {
          pageNum: this.addForm.pageNum,
          pageSize: this.addForm.pageSize,
        },
        data: {
          endYear: this.addForm.endYear,
          college: this.addForm.college,
          major: this.addForm.major,
        },
      })
        .then((res) => {
          this.yearList = res.data.data.records;
          // console.log(res.data.data.records);
          this.total = res.data.data.total || 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getYear2() {
      const { data: res } = await this.$http.post(
        "jobRate/getByCondition?pageNum=" +
          this.addForm.pageNum +
          "&pageSize=" +
          this.addForm.pageSize
      );
      if (res.code !== 200) {
        return this.$message.error("获取失败");
      }
      this.yearList = res.data.records;
      this.total = res.data.total || 0;
    },
    // 监听分页条数的事件
    handleSizeChange(newSize) {
      this.addForm.pageSize = newSize;
      this.getYear();
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.addForm.pageNum = newPage;
      this.getYear();
    },
    // 监听对话框关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
      this.options3 = null;
    },

    // 添加新就业率
    addRate() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return;
        // 发起添加新就业率的请求
        const { data: res } = await this.$http.post("jobRate", {
          // id: this.editForm.id,
          endYear: this.addForm.endYear,
          rate: this.addForm.rate,
          college: this.addForm.college,
          major: this.addForm.major,
        });
        if (res.code == 200) {
          this.addDialogVisible = false;
          this.$message.success("添加成功");
          this.getYear2();
          this.options3 = null;
        } else if (res.code == 4006) {
          this.$message.error("该就业率已经存在，不可重复设置");
        } else if (res.code != 200) {
          this.$message.error("添加失败");
        }
        // this.getYear();
        // if ((res.code = 200)) {
        //   this.$message.success("添加成功");
        //   // 成功之后隐藏对话框
        //   this.addDialogVisible = false;
        //   // 刷新用户列表
        //   this.getYear();
        // }
      });
      // this.getYear2();
    },
    // 展示修改就业率的对话框
    async showEditDialog(row) {
      // const { data: res } = await this.$http.get("jobRate");
      // if (res.code != 2000) {
      //   return this.$message.error("获取失败");
      // }
      this.editForm.id = row.id;
      this.editForm.rate = Number(row.rate);
      this.editForm.endYear = row.endYear;
      this.editForm.college = row.college;
      this.editForm.major = row.major;
      // this.editForm = res.data;
      this.editDialogVisible = true;
      this.getMajor(this.editForm.college);
    },
    // 监听修改就业率的对话框关闭事件
    editDialogClosed() {
      // this.$refs.editFormRef.resetFields();
      this.options3 = null;
      // this.addForm.major = null;
    },
    // 修改用户信息并提交
    editRate() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        // 发送修改就业率的请求
        const { data: res } = await this.$http.put("jobRate", {
          id: this.editForm.id,
          endYear: this.editForm.endYear,
          rate: this.editForm.rate,
          college: this.editForm.college,
          major: this.editForm.major,
          // token: window.sessionStorage,
        });
        if (res.code !== 200) {
          return this.$message.error("修改失败");
        }
        // 关闭对话框，刷新用户列表，提示修改成功
        this.editDialogVisible = false;
        this.getYear();
        this.options3 = null;
        this.$message.success("修改成功");
      });
    },
    // 根据id值删除就业率
    async removeRateById(row) {
      this.deleteForm.id = row.id;
      const confirmResult = await this.$confirm(
        "此操作将永久删除该就业率，是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => {
        return err;
      });

      // 确认删除会返回字符串confir
      // 取消删除则是字符串cancel，所以可因此判断是否取消
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete(
        "jobRate?id=" + this.deleteForm.id
      );
      if (res.code !== 200) {
        return this.$message.error("删除失败");
      }
      this.getYear();
      this.$message.success("删除成功");
    },
  },
};
</script>

<style lang="less" scoped>
.div2 {
  text-align: center;
  margin-top: 10px;
}
</style>
