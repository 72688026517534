<template>
  <el-container class="layout-container">
    <el-header>
      <ZhaoJiuHeaderPart></ZhaoJiuHeaderPart>
    </el-header>

    <el-container>
      <ZhaoJiuAsidePart></ZhaoJiuAsidePart>

      <el-main>
        <!-- 路由 HeaderPart、AsidePart 的出口     渲染-->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import ZhaoJiuHeaderPart from "../../components/ZhaoJiuHeaderPart.vue";
import  ZhaoJiuAsidePart from "../../components/ZhaoJiuAsidePart.vue";
export default {
  components: {
    ZhaoJiuHeaderPart,
    ZhaoJiuAsidePart,
  },
};
</script>

<style lang="less" scoped>
.el-header {
  background-color: #409eff;
}
el-aside {
  background-color: #ecf5ff;
}
</style>
