<template>

	<el-row>
    <el-col :span="24">
        <el-card class="map-card" shadow="hover">
	          <el-date-picker v-model="valueYear" placeholder="选择年" type="year" value-format="yyyy"
	                          @change="selectYear(valueYear)">
						</el-date-picker>
            <div id="chinaMap" class="map-container"></div>
        </el-card>
    </el-col>
</el-row>


</template>

<script>
import 'echarts/map/js/china.js'


export default {
	// 在data中新增河南省的就业数据
	data() {
		return {
			valueYear: new Date().getFullYear().toString(),
			mapChart: null,
			// 各个省份的就业人数
			employmentData: {
				'北京': 15000,
				'上海': 10000,
				'广东': 5000,
				'浙江': 90,
				'江苏': 110,
				'重庆': 30,
				'河南': 500
			}
		};
	},
	mounted() {
		this.initMapChart(); // 初始化地图
		window.addEventListener('resize', this.handleResize)
	},
	methods: {
		initMapChart() {
			this.mapChart = this.$echarts.init(document.getElementById('chinaMap'));
			this.renderMap(); // 渲染地图
		},
		renderMap() {
			const maxEmployment = Math.max(...Object.values(this.employmentData)); // 获取最大就业人数
			const option = {
				tooltip: {
					show: false,
					trigger: 'item',
					formatter: '{b} : {c} 人'
				},
				visualMap: {
					min: 0,
					max: maxEmployment,
					calculable: true,
					inRange: {
						color: ['#FFE4B5', '#FFA500'] // 淡橙色到深橙色的渐变
					},
					textStyle: {
						color: '#333'
					}
				},
				geo: {
					map: 'geo',
					roam: false,
					label: {
						show: true,
						color: '#333',
						fontSize: 10
					},
					itemStyle: {
						areaColor: '#f3f3f3',
						borderColor: '#999',
						borderWidth: 1
					},
					emphasis: {
						label: {
							color: '#000'
						},
						itemStyle: {
							areaColor: '#ffd700'
						}
					},
					zoom: 1.3
				},
				series: [
					{
						type: 'map',
						map: 'china',
						data: Object.keys(this.employmentData).map(province => {
							return {
								name: province,
								value: this.employmentData[province]
							}
						}),
						label: {
							show: true,
							color: '#333'
						}
					}
				]
			};
			this.mapChart.setOption(option);
		},
		selectYear(valueYear) {
			// 更新选定的年份
			this.valueYear = valueYear
			// 模拟数据更新
			this.employmentData = this.generateRandomEmploymentData()
			// 重新渲染地图
			this.renderMap()

			// 显示通知
			this.$notify({
				title: '注意',
				message: '无就业地点信息',
				type: 'error',
				duration: 3000,
			});
		},
		// 模拟生成随机的就业数据
		generateRandomEmploymentData() {
			const employmentData = {};
			Object.keys(this.employmentData).forEach(province => {
				employmentData[province] = Math.floor(Math.random() * 200); // 随机生成0到200之间的整数
			});
			return employmentData;
		},
		handleResize() {
			this.mapChart.resize();
		},
	}
};
</script>

<style scoped>
.map-card {
	width: 100%;
}

.map-container {
	height: 800px; /* 调整地图容器的高度 */
}
</style>
