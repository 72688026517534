<template>
  <div class="com-container">
    <el-row>
      <el-col :span="24">
        <el-card class="box-card">
          <div class="year">
            <el-date-picker
              v-model="valueYear"
              type="year"
              placeholder="选择年"
              value-format="yyyy"
              @change="selectYear(valueYear)"
            ></el-date-picker>
          </div>
          <div class="selCollege">
            <el-select v-model="value" placeholder="请选择学院" @change="changeCollege">
              <el-option
                v-for="item in selectCollege"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- <el-button style="margin-left:15px" @click="showAll" type="primary" plain>查看全校</el-button> -->
          </div>
          <div class="com-chart" ref="employment_ref"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      myChart: '',
      valueYear: (new Date().getFullYear()-1).toString(),
      dataX: [],
      dataY: [],
      selectCollege: [],
      value: '',
      startLength: '',
      teacherData: []
    }
  },
  methods: {
    drawBarChart() {
      this.myChart = this.$echarts.init(this.$refs.employment_ref, {
        renderer: 'svg'
      })
      this.myChart.setOption({
        title: {
          text: '年度各专业就业率',
          x: 'center'
          //   top: '0.5%'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['就业率'],
          bottom: '5'
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              interval: 0,
              formatter: function(value) {
                var ret = '' //拼接加\n返回的类目项
                var maxLength = 5 //每项显示文字个数
                var valLength = value.length //X 轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength) //类目项需要换行的行数
                if (rowN > 1) {
                  //如果类目项的文字大于 5,
                  for (var i = 0; i < rowN; i++) {
                    var temp = '' //每次截取的字符串
                    var start = i * maxLength //开始截取的位置
                    var end = start + maxLength //结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + '\n'
                    ret += temp //凭借最终的字符串
                  }
                  return ret
                } else {
                  return value
                }
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '就业率',
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: {
          name: '就业率',
          type: 'bar',
          tooltip: {
            valueFormatter: function(value) {
              return value + '%'
            }
          },
          data: []
          //   barCategoryGap: '35%',
          //   barMinWidth: 60
        },
        dataZoom: [
          {
            type: 'inside',
            start: this.startLength,
            end: 100,
            zoomOnMouseWheel: false
          }
        ]
      })
    },

    async initData(val) {
      this.teacherData = JSON.parse(window.sessionStorage.getItem('teacherData'))
      val = this.teacherData.college
      this.value = val
      const { data: res } = await this.$http.get('/echarts/majorStuJobRate', {
        params: {
          endYear: this.valueYear,
          college: val
        }
      })
      this.startLength = res.data.length
      //先进行赋值，清空缓存
      this.dataX = []
      this.dataY = []
      for (let i = 0; i < res.data.length; i++) {
        this.dataX[i] = res.data[i].major
        this.dataY[i] = res.data[i].rate
      }
      if (this.dataX.length === 0) {
    // 数据为空时显示加载提示
    // this.myChart.clear();
    this.myChart.showLoading({
      text: '暂无数据',
      color: '#999',
      textColor: '#333',
      maskColor: 'rgba(255, 255, 255, 0.8)',
      zlevel: 0,
      textStyle: {
        fontSize: 16 // 调整字体大小
      }
    });
    return;
  }
  // 数据加载完成后隐藏加载提示
  this.myChart.hideLoading();
      this.myChart.setOption({
        xAxis: [{ data: this.dataX }],
        series: [
          {
            type: 'bar',
            data: this.dataY
          }
        ]
      })
    },
    async changeCollege(val) {
      const { data: res } = await this.$http.get('/echarts/majorStuJobRate', {
        params: {
          endYear: this.valueYear,
          college: val
        }
      })
      this.startLength = res.data.length
      //先进行赋值，清空缓存
      this.dataX = []
      this.dataY = []
      for (let i = 0; i < res.data.length; i++) {
        this.dataX[i] = res.data[i].major
        this.dataY[i] = res.data[i].rate
      }
      this.myChart.setOption({
        xAxis: [{ data: this.dataX }],
        series: [
          {
            type: 'bar',
            data: this.dataY
          }
        ]
      })
    },
    async initCollege() {
      const { data: res3 } = await this.$http.get('/college/all')
      this.selectCollege = res3.data
    },
    // 查看全校就业率的方法
    // showAll() {
    //   this.value = ''
    //   this.initData()
    // },
    // 选择年份的方法
    selectYear(val) {
      this.valueYear = val
      this.initData()
    }
  },
  mounted() {
    this.initCollege()
    this.initData()
    this.drawBarChart()
  }
}
</script>
 
<style lang="less" scoped>
.com-container {
  padding-top: 10px;
  .com-chart {
    padding-top: 10px;
    width: 100%;
    height: 500px;
  }
  .selCollege {
    position: absolute;
    top: 20px;
    left: 280px;
  }
}
</style>