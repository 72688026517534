<template>
  <div class="com-container">
    <el-row>
      <el-col :span="24">
        <el-card class="box-card">
          <div class="year">
            <el-date-picker
              v-model="valueYear"
              type="year"
              placeholder="选择年"
              value-format="yyyy"
              @change="selectYear(valueYear)"
            ></el-date-picker>
            <div class="reach-color"> </div>已达标
            <div class="noreach-color"> </div>未达标
          </div>
          <div class="selCollege">
            <el-select
              v-model="value"
              placeholder="信息工程学院"
              @change="changeCollege"
            >
              <el-option
                v-for="item in selectCollege"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- <el-button style="margin-left:15px" @click="showAll" type="primary" plain>查看全校</el-button> -->
          </div>
          <div class="com-chart" ref="employment_ref"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myChart: "",
      copyMychart: null,
      valueYear: (new Date().getFullYear()-1).toString(),
      dataX: [],
      dataY: [],
      reqDataY: [],
      selectCollege: [],
      mycolorList: [ ],
      value: "信息工程学院",
      startLength: "",
    };
  },
  methods: {
    drawBarChart() {
      			
      this.myChart = this.$echarts.init(this.$refs.employment_ref, {
        renderer: "svg",
      });
      this.myChart.setOption({
        title: {
          text: "年度各专业就业率",
          x: "center",
          //   top: '0.5%'
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          data: ["就业率"],
          bottom: "5",
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              interval: 0,
              formatter: function (value) {
                var ret = ""; //拼接加\n返回的类目项
                var maxLength = 5; //每项显示文字个数
                var valLength = value.length; //X 轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1) {
                  //如果类目项的文字大于 5,
                  for (var i = 0; i < rowN; i++) {
                    var temp = ""; //每次截取的字符串
                    var start = i * maxLength; //开始截取的位置
                    var end = start + maxLength; //结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                } else {
                  return value;
                }
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "就业率",
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
              formatter: "{value} %",
            },
          },
        ],
        series: {
          name: "就业率",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return value + "%";
            },
          },
          itemStyle: {
            normal: {
                // 随机显示
                //color:function(d){return "#"+Math.floor(Math.random()*(256*256*256-1)).toString(16);}
              
                // 定制显示（按顺序）
                //红色达标 #BC332F
                //黄色未达标  #EE9201
                color: function(params) { 
                    // var colorList = ['#C33531','#EFE42A','#64BD3D','#EE9201','#29AAE3', '#B74AE5','#0AAF9F',
                    // '#E89589','#16A085','#4A235A','#C39BD3 ','#F9E79F','#BA4A00','#ECF0F1','#616A6B',
                    // '#EAF2F8','#4A235A','#3498DB' ]; 
                    return this.mycolorList[params.dataIndex] 
                }.bind(this)
            },
        },
          data: [],
          //   barCategoryGap: '35%',
          //   barMinWidth: 60
        },
        dataZoom: [
          {
            type: "inside",
            start: this.startLength,
            end: 100,
            zoomOnMouseWheel: false,
          },
        ],
      });
      
    },

    async initData() {
  try {
    // 获取基础数据
    const baseRes = await this.$http.get("/echarts/majorStuJobRate", {
      params: {
        endYear: this.valueYear,
        college: this.value,
      },
    });

    this.startLength = baseRes.data.data.length;

    // 初始化数据容器
    this.dataX = [];
    this.dataY = [];
    this.mycolorList = [];
    this.reqDataY = [];

    // 填充基础数据
    for (let i = 0; i < baseRes.data.data.length; i++) {
      this.dataX[i] = baseRes.data.data[i].major;
      this.dataY[i] = baseRes.data.data[i].rate;
    }

    // 批量处理额外的异步请求
    const requestsPromiseArray = baseRes.data.data.map(async (item, i) => {
      const detailRes = await this.$http({
        url: "/jobRate/getByCondition",
        method: "GET",
        params: {
          endYear: this.valueYear,
          college: this.value,
          major: item.major,
        },
      });
      this.reqDataY[i] = detailRes.data.data.records[0].rate;

      // 根据 reqDataY 和 dataY 计算颜色并填充到 mycolorList
      if (this.dataY[i] >= this.reqDataY[i]) {
        this.mycolorList[i] = '#BC332F';
      } else {
        this.mycolorList[i] = '#FEA443';
      }
    });

    // 等待所有额外请求完成
    await Promise.all(requestsPromiseArray);

    // 设置X轴和系列数据
    this.myChart.setOption({
      xAxis: [{ data: this.dataX }],
      series: [
        {
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return value + "%";
            },
          },
          itemStyle: {
            normal: {
              color: function(params) {
                return this.mycolorList[params.dataIndex];
              }.bind(this),
            },
          },
          data: this.dataY,
        },
      ],
    });

    // 数据为空时的处理
    if (this.dataX.length === 0) {
      this.myChart.showLoading({
        text: '暂无数据',
        color: '#999',
        textColor: '#333',
        maskColor: 'rgba(255, 255, 255, 0.8)',
        zlevel: 0,
        textStyle: {
          fontSize: 16 // 调整字体大小
        }
      });
      return;
    }
    // 数据加载完成后隐藏加载提示
    this.myChart.hideLoading();
  } catch (err) {
    console.log("获取数据异常！");
  }
},

    async changeCollege(val) {
      // const { data: res } = await this.$http.get("/echarts/majorStuJobRate", {
      //   params: {
      //     endYear: this.valueYear,
      //     college: val,
      //   },
      // });
      // this.startLength = res.data.length;
      // //先进行赋值，清空缓存
      // this.dataX = [];
      // this.dataY = [];
      // for (let i = 0; i < res.data.length; i++) {
      //   this.dataX[i] = res.data[i].major;
      //   this.dataY[i] = res.data[i].rate;
      // }
      // this.myChart.setOption({
      //   xAxis: [{ data: this.dataX }],
      //   series: [
      //     {
      //       type: "bar",
      //       data: this.dataY,
      //     },
      //   ],
      // });
      this.initData();
    },
    async initCollege() {
      const { data: res3 } = await this.$http.get("/college/all");
      this.selectCollege = res3.data;
    },
    // 查看全校就业率的方法
    // showAll() {
    //   this.value = ''
    //   this.initData()
    // },
    // 选择年份的方法
    selectYear(val) {
      this.valueYear = val;
      this.initData();
    },
  },
  mounted() {
    this.initCollege();
    this.initData();
    this.drawBarChart();
  },
};
</script>

<style lang="less" scoped>
.reach-color{
  margin-top: 10px;
  background-color: #BC332F;
  width: 40px;
  height: 20px;
  border-radius: 5px;
}
.noreach-color{
  margin-top: 10px;
  background-color: #FEA443;
  width: 40px;
  height: 20px;
  border-radius: 5px;
}
.com-container {
  padding-top: 10px;
  .com-chart {
    padding-top: 10px;
    width: 100%;
    height: 500px;
  }
  .selCollege {
    position: absolute;
    top: 20px;
    left: 280px;
  }
}
</style>
