<template>
  <div>
    <!-- 添加学生按钮区域 -->
    <el-row :gutter="12">
      <el-col :span="2" align="left" style="margin-right: 10px">
        <el-button :disabled="isDisable" size="small" type="primary" @click="choicePDF">导入学生信息</el-button>

        <input id="file" ref="filElemPDF" accept=".xls, .xlsx" name="file" style="display: none" type="file"
               @change="getFilePDF"/>
      </el-col>
      <el-col :span="2" align="left" style="margin-right: 10px">
        <el-button size="small" type="primary" @click="exportExcel">导出学生模板</el-button>
      </el-col>
      <el-col :span="2">
        <span><el-button size="small" type="primary" @click="addList">
            添加学生
          </el-button></span>
      </el-col>
      <el-col :span="2">
        <span><el-button size="small" type="danger" @click="deleteStudents">按照年份删除数据</el-button></span>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin-top: 15px">
      <el-col :span="2" class="">
        <span>
          <el-button align="left" plain size="small" type="danger" @click="delHandle()">
            批量删除
          </el-button>
        </span>
      </el-col>
      <el-col :span="6" style="margin-right: 10px">
        <el-date-picker v-model="endYear" format="yyyy" placeholder="选择年" size="medium" type="year"
                        value-format="yyyy" @change="changeYear">
        </el-date-picker>
      </el-col>
      <el-col :span="6" style="margin-right: 10px">
        <el-cascader v-model="office" :options="options" :props="{ expandTrigger: 'hover', emitPath: false }"
                     :show-all-levels="false" class="dsdv" clearable placeholder="请选择专业" size="medium"
                     @change="handleChangeT"></el-cascader>
      </el-col>
      <el-col :span="6">
        <template>
          <el-select v-model="value" clearable placeholder="请选择就业状态" size="medium" @change="changeStatus">
            <el-option v-for="item in optionsT" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-col>
    </el-row>
    <!-- 学生信息列表 -->
    <!-- v-show="show" -->
    <el-table :data="
        studentList.filter(
          (data) => !search || data.studentId || data.office || data.college
        )
      " @filter-change="filterChange" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column align="center" label="毕业年份" prop="endYear" width="100px">
      </el-table-column>
      <el-table-column align="center" label="姓名" prop="studentName" width="100px"></el-table-column>
      <el-table-column align="center" label="性别" prop="sex" width="70px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.sex | getColorType" size="small">{{
		          scope.row.sex | getColorSex
                                                                    }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="学号" prop="studentId" width="120px"></el-table-column>
      <el-table-column align="center" label="学院" prop="college" width="140px"></el-table-column>
      <el-table-column align="center" label="专业" prop="office" width="150px">
      </el-table-column>
      <el-table-column align="center" label="是否就业" prop="status" width="180px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status | getColorType" size="small">{{
		          scope.row.status | getColorName
                                                                       }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="学生信息" width="100px">
        <!-- 作用域插槽 -->
        <template slot-scope="scope">
          <!-- 查看按钮 -->
          <el-button round size="mini" @click="checkDialog(scope.row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column align="right">
        <template slot="header" slot-scope="scope">
          <el-col>
            <el-input v-model="search" clearable maxlength="10" placeholder="输入学号搜索" show-word-limit size="small"
                      @input="reseek">
              <el-button slot="append" icon="el-icon-search" plain type="primary" @click="seek">
              </el-button>
            </el-input>
          </el-col>
        </template>
	      <!-- 作用域插槽 -->
        <template slot-scope="scope">
          <!-- 修改按钮 -->
          <el-button round size="mini" type="primary" @click="showEditDialog(scope.row)">修改</el-button>
          <!-- 删除按钮 -->
          <el-button round size="mini" type="danger" @click="removeStuMsgById(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="block">
      <el-pagination :page-size="10" :total="total" background layout="total, prev, pager, next"
                     @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <!-- 添加/修改学生的对话框 -->
    <el-dialog :before-close="handleClose" :title="title2" :visible.sync="dialogVisible" width="70%">
      <el-form ref="addForm" :model="addForm" :rules="addFormRules" label-width="140px">
        <el-row>
          <el-col :span="8"><el-form-item label="毕业年份：" prop="endYear">
              <el-input v-model="addForm.endYear"></el-input> </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="姓名：" prop="studentName">
              <el-input v-model="addForm.studentName"></el-input> </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="性别：" prop="sex">
              <el-select v-model="addForm.sex" placeholder="请选择">
                <el-option v-for="item in stuSex" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="学号：" prop="studentId">
              <el-input v-model="addForm.studentId"></el-input> </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="所属学院：" prop="college">
              <el-select v-model="addForm.college" placeholder="请选择" @change="getMajor">
                <el-option v-for="item in options2" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
              </el-select> </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="所属专业：" prop="office">
              <el-select v-model="addForm.office" placeholder="请选择">
                <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select> </el-form-item></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="联系电话：" prop="tel">
              <el-input v-model="addForm.tel"></el-input> </el-form-item></el-col>
              <el-col :span="8">
            <el-form-item label="是否就业：" prop="status">
              <el-select v-model="addForm.status" placeholder="请选择">
                <el-option v-for="item in stuStatus" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
              </el-select> </el-form-item></el-col>
              <el-col :span="8">
            <el-form-item label="就业地" prop="employment">
              <el-input v-model="addForm.employment"></el-input>
            </el-form-item>
            <el-form-item label="就业单位" prop="employmentUnit">
              <el-input v-model="addForm.employmentUnit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item disabled label="生源地：" prop="studentSource">
              <el-input v-model="addForm.studentSource"
                        placeholder="例如：河南省开封市"></el-input> </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="就业意向地一：" prop="intentionPlaceOne">
              <el-input v-model="addForm.intentionPlaceOne" placeholder="例如：上海"></el-input> </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="就业意向地二：" prop="intentionPlaceTwo">
              <el-input v-model="addForm.intentionPlaceTwo" placeholder="例如：北京"></el-input> </el-form-item></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><el-form-item label="就业意向一：" prop="jobIntentionOne">
              <el-input v-model="addForm.jobIntentionOne"></el-input> </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="就业意向二：" prop="jobIntentionTwo">
              <el-input v-model="addForm.jobIntentionTwo"></el-input> </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="就业意向三：" prop="jobIntentionThree">
              <el-input v-model="addForm.jobIntentionThree"></el-input> </el-form-item></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="介绍人一：" prop="introducerOne">
              <el-input v-model="addForm.introducerOne"></el-input> </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="介绍人一工号：" prop="introducerOneWorkId">
              <el-input v-model="addForm.introducerOneWorkId"></el-input> </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="介绍人一手机号：" prop="introducerOneTel">
              <el-input v-model="addForm.introducerOneTel"></el-input> </el-form-item></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="介绍人二：" prop="introducerTwo">
              <el-input v-model="addForm.introducerTwo"></el-input> </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="介绍人二工号：" prop="introducerTwoWorkId">
              <el-input v-model="addForm.introducerTwoWorkId"></el-input> </el-form-item></el-col>
          <el-col :span="8">
            <el-form-item label="介绍人二手机号：" prop="introducerTwoTel">
              <el-input v-model="addForm.introducerTwoTel"></el-input> </el-form-item></el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addStudent">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看学生的对话框 -->
    <el-dialog :before-close="handleClose2" :visible.sync="dialogVisible1" title="查看学生信息" width="60%">
      <el-form ref="checkForm" :model="checkForm">
        <el-descriptions :column="3" :size="size" border class="margin-top">
          <el-descriptions-item prop="endYear">
            <template slot="label">
              <i class="el-icon-user"></i>
              毕业年份
            </template>
            {{ checkForm.endYear }}
          </el-descriptions-item>
          <el-descriptions-item prop="studentName">
            <template slot="label">
              <i class="el-icon-user"></i>
              姓名
            </template>
            {{ checkForm.studentName }}
          </el-descriptions-item>
          <el-descriptions-item prop="sex">
            <template slot="label">
              <i class="el-icon-female"></i>
              性别
            </template>
            {{ checkForm.sex }}
          </el-descriptions-item>
          <el-descriptions-item prop="studentId">
            <template slot="label">
              <i class="el-icon-view"></i>
              学号
            </template>
            {{ checkForm.studentId }}
          </el-descriptions-item>
          <el-descriptions-item prop="college">
            <template slot="label">
              <i class="el-icon-tickets"></i>
              学院
            </template>
            {{ checkForm.college }}
          </el-descriptions-item>
          <el-descriptions-item prop="office">
            <template slot="label">
              <i class="el-icon-tickets"></i>
              专业
            </template>
            {{ checkForm.office }}
          </el-descriptions-item>
          <el-descriptions-item prop="tel">
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              联系电话
            </template>
            {{ checkForm.tel }}
          </el-descriptions-item>
          <el-descriptions-item prop="status">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              是否就业
            </template>
            {{ checkForm.status }}
          </el-descriptions-item>


          <el-descriptions-item prop="employment">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              就业地
            </template>
            {{ checkForm.employment }}
          </el-descriptions-item>

	        <el-descriptions-item prop="employmentUnix">
		        <template slot="label">
			        <i class="el-icon-location-outline"></i>
			        就业单位
		        </template>
		        {{ checkForm.employmentUnit }}
	        </el-descriptions-item>

	        <el-descriptions-item prop="studentSource">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              生源地
            </template>
            {{ checkForm.studentSource }}
          </el-descriptions-item>

          <el-descriptions-item prop="intentionPlaceOne">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              就业意向地一
            </template>
            {{ checkForm.intentionPlaceOne }}
          </el-descriptions-item>
          <el-descriptions-item prop="intentionPlaceTwo">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              就业意向地二
            </template>
            {{ checkForm.intentionPlaceTwo }}
          </el-descriptions-item>
          <el-descriptions-item prop="jobIntentionOne">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              就业意向一
            </template>
            {{ checkForm.jobIntentionOne }}
          </el-descriptions-item>
          <el-descriptions-item prop="jobIntentionTwo">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              就业意向二
            </template>
            {{ checkForm.jobIntentionTwo }}
          </el-descriptions-item>

          <el-descriptions-item prop="jobIntentionThree">
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              就业意向三
            </template>
            {{ checkForm.jobIntentionThree }}
          </el-descriptions-item>



          <el-descriptions-item prop="introducerOne">
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              介绍人一
            </template>
            {{ checkForm.introducerOne }}
          </el-descriptions-item>

          <el-descriptions-item prop="introducerOneWorkId">
            <template slot="label">
              <i class="el-icon-view"></i>
              介绍人一工号
            </template>
            {{ checkForm.introducerOneWorkId }}
          </el-descriptions-item>
          <el-descriptions-item prop="introducerOneTel">
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              介绍人一手机号
            </template>
            {{ checkForm.introducerOneTel }}
          </el-descriptions-item>
          <el-descriptions-item prop="introducerTwo">
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              介绍人二
            </template>
            {{ checkForm.introducerTwo }}
          </el-descriptions-item>
          <el-descriptions-item prop="introducerTwoWorkId">
            <template slot="label">
              <i class="el-icon-view"></i>
              介绍人二工号
            </template>
            {{ checkForm.introducerTwoWorkId }}
          </el-descriptions-item>
          <el-descriptions-item prop="introducerTwoTel">
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              介绍人二手机号
            </template>
            {{ checkForm.introducerTwoTel }}
          </el-descriptions-item>
        </el-descriptions>
      </el-form>
      <div style="padding-bottom: 5px"></div>
	    <!-- 展示图片 -->
      <el-button :loading="loading" icon="el-icon-search" size="small" type="primary"
                 @click="echoPicture">查看相关附件</el-button>

      <el-dialog :before-close="handleClose3" :modal="false" :visible.sync="dialogVisible3" title="就业信息图片"
                 width="60%">
        <div class="demo-image">
          <div v-for="item in img64Best" :key="item.index" class="block">
            <el-image :preview-src-list="img64Best" :src="item" style="width: 100px; height: 100px"></el-image>
          </div>
        </div>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
	//  是否就业状态
	filters: {
		// Tag类型--不同状态不同颜色
		getColorType(status) {
			const colorMap = {
				0: 'danger',
				1: '',
				2: 'warning',
				3: 'success',
				4: 'info',
				5: 'yellow'
			}
			return colorMap[status]
		},
		// 不同状态的颜色的名称
		getColorName(status) {
			const colorMap = {
				0: '未就业',
				1: '研究生',
				2: '公务员/选调生/参军入伍',
				3: '已就业（三方协议/劳务合同）',
				4: '自主创业',
				5: '灵活就业',
				6: '自定义就业'
			}
			return colorMap[status]
		},
		// 不同状态的颜色的名称
		getColorSex(status) {
			const colorMap = {
				0: '女',
				1: '男'
			}
			return colorMap[status]
		}
	},
	data() {
		return {
			loading: false,
			imageUrl: [],
			imgUrl: [],
			imgUrlNew: '', //参数
			img64Best: [],
			isDisable: false,
			value: null,
			options2: [],
			multipleSelection: [], // 批量选择中选择的记录列表
			size: '',
			sex: 1,
			stuSex: [
				{value: 1, label: '男'},
				{value: 0, label: '女'}
			],
			status: 0,
			stuStatus: [
				{value: 0, label: '未就业'},
				{value: 1, label: '研究生'},
				{value: 2, label: '公务员/选调生/参军入伍'},
				{value: 3, label: '已就业（三方协议/劳务合同）'},
				{value: 4, label: '自主创业'},
				{value: 5, label: '灵活就业'},
				{value: 6, label: '自定义就业'}
			],
			id: '', //修改时
			ids: [], //删除时
			dialogVisible: false, //关闭对话框
			dialogVisible1: false,
			dialogVisible3: false,
			modelType: 0, //0表示添加，1表示修改
			//获取学生列表的参数对象
			total: 0, //数组总数
			currentPage: 1, //当前页码
			studentList: [], //每一页从后端获取的数据

			search: '',
			title2: '添加',
			//添加学生的表单数据
			addForm: {
				endYear: null,
				studentName: null,
				sex: null,
				studentId: null,
				college: null,
				office: null,
				tel: null,
				status: null,
				studentSource: null,
				employment: null,
				employmentUnit: null,
				intentionPlaceOne: null,
				intentionPlaceTwo: null,
				jobIntentionOne: null,
				jobIntentionTwo: null,
				jobIntentionThree: null,
				introducerOne: null,
				introducerOneWorkId: null,
				introducerOneTel: null,
				introducerTwo: null,
				introducerTwoWorkId: null,
				introducerTwoTel: null,
				currentPage: 1
			},
			//查看学生的表单数据
			checkForm: {
				endYear: '',
				studentName: '',
				sex: '',
				studentId: '',
				college: '',
				office: '',
				tel: '',
				status: '',
				studentSource: '',
				employment: '',
				employmentUnit: '',
				intentionPlaceOne: '',
				intentionPlaceTwo: '',
				jobIntentionOne: '',
				jobIntentionTwo: '',
				jobIntentionThree: '',
				introducerOne: '',
				introducerOneWorkId: '',
				introducerOneTel: '',
				introducerTwo: '',
				introducerTwoWorkId: '',
				introducerTwoTel: ''
			},
			//添加学生表单的验证规则对象
			addFormRules: {
				endYear: [
					{required: true, message: '请输入毕业年份', trigger: 'blur'},
					{min: 4, max: 4, message: '毕业年份为4位数', trigger: 'blur'}
				],
				studentName: [
					{required: true, message: '请输入学生姓名', trigger: 'blur'}
				],
				sex: [
					{required: true, message: '请输入学生性别', trigger: 'blur'}
					// {message: "", trigger: "blur" },
				],
				studentId: [
					{required: true, message: '请输入学生学号', trigger: 'blur'}
				],
				college: [
					{required: true, message: '请输入学生所属学院', trigger: 'blur'}
				],
				office: [
					{required: true, message: '请输入学生的专业', trigger: 'blur'}
				],
				tel: [
					{required: true, message: '请输入手机号码', trigger: 'blur'},
					{
						validator:
								/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/,
						trigger: 'blur'
					}
				],
				status: [
					{required: true, message: '请输入就业状态', trigger: 'blur'}
				],
				studentSource: [
					{required: true, message: '请输入生源地', trigger: 'blur'}
				],
				employment: [
					{required: true, message: '请输入就业地', trigger: 'blur'}
				],
				employmentUnit: [
					{required: true, message: '请输入就业单位', trigger: 'blur'}
				],
				intentionPlaceOne: [
					{required: false, message: '请输入就业意向地一', trigger: 'blur'}
				],
				intentionPlaceTwo: [
					{required: false, message: '请输入就业意向地二', trigger: 'blur'}
				],
				jobIntentionOne: [
					{required: false, message: '请输入就业意向一', trigger: 'blur'}
				],
				jobIntentionTwo: [
					{required: false, message: '请输入就业意向二', trigger: 'blur'}
				],
				jobIntentionThree: [
					{required: false, message: '请输入就业意向三', trigger: 'blur'}
				],
				introducerOne: [
					{required: false, message: '请输入介绍人一', trigger: 'blur'}
				],
				introducerOneWorkId: [
					{required: false, message: '请输入介绍人一工号', trigger: 'blur'}
				],
				introducerOneTel: [
					{required: false, message: '请输入介绍人一手机号', trigger: 'blur'},
					{
						validator:
								/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/,
						trigger: 'blur'
					}
				],
				introducerTwo: [
					{required: false, message: '请输入介绍人二', trigger: 'blur'}
				],
				introducerTwoWorkId: [
					{required: false, message: '请输入介绍人二工号', trigger: 'blur'}
				],
				introducerTwoTel: [
					{required: false, message: '请输入介绍人二手机号', trigger: 'blur'},
					{
						validator:
								/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/,
						trigger: 'blur'
					}
				]
			},
			value: [],
			options: [],
			options3: [],
			office: null,
			endYear: null,
			statusNew: '',
			optionsT: [
				{
					value: '0',
					label: '未就业'
				},
				{
					value: '1',
					label: '研究生'
				},
				{
					value: '2',
					label: '公务员/选调生/参军入伍'
				},
				{
					value: '3',
					label: '已就业(三方协议/劳务合同)'
				},
				{
					value: '4',
					label: '自主创业'
				},
				{
					value: '5',
					label: '灵活就业'
				},
				{
					value: '6',
					label: '自定义就业'
				}
			]
		}
	},

	methods: {
		//批量删除
		deleteStudents() {
			this.$confirm('此操作将永久删除选中的学生, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'error',
				center: true
			})
					.then(async () => {
						// 发送删除请求
						await this.$http
								.delete('/students/delStudentsByEndYear', {
									params: {
										endYear: this.endYear
									}
								})
								.then((res) => {
									if (res.data.code !== 200) {
										return this.$message.error('批量删除失败！')
									}
									this.$message.success('批量删除成功！')
									this.getStudentList()
								})
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						})
					})
		},
		handleClose3() {
			this.loading = false
			this.dialogVisible3 = false
		},
		//查看相关附件
		echoPicture() {
			if (this.imgUrl.length == 0) {
				this.$message({
					message: '该学生暂无相关附件！',
					type: 'warning'
				})
			} else {
				this.loading = true
				for (let i = 0; i < this.imgUrl.length - 1; i++) {
					this.imgUrlNew = this.imgUrl[i] //参数imgUrl
					this.downloadPicture(i) //图片下载请求
				}
				setTimeout(() => {
					this.dialogVisible3 = true
				}, 1500)
			}
		},
		downloadPicture(i) {
			this.$http({
				url: '/common/imgDownload',
				method: 'GET',
				responseType: 'blob',
				params: {
					imgUrl: this.imgUrlNew
				}
			}).then((res) => {
				this.getBase64(res.data).then((base64) => {
					this.img64Best[i] = base64
				})
			})
		},
		getBase64(data) {
			return new Promise((resolve, reject) => {
				const blob = new Blob([data], {type: 'image/jpg'}) // 必须指定type类型
				const reader = new FileReader()
				reader.readAsDataURL(blob)
				reader.onload = () => resolve(reader.result)
				reader.onerror = (error) => reject(error)
			})
		},
		// 用于保存选中的行
		handleSelectionChange(val) {
			this.multipleSelection = val
			this.idStr = val.map((item) => item.id).toString()
		},
		//批量删除
		delHandle() {
			this.$confirm('此操作将永久删除选中的学生, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'error',
				center: true
			})
					.then(() => {
						this.$http({
							url: '/students',
							method: 'DELETE',
							params: {
								ids: this.idStr
							}
						}).then((res) => {
							if (res.data.code !== 200) {
								return this.$message.error('批量删除失败！')
							}
							this.$message.success('批量删除成功！')
							this.getStudentList()
						})
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						})
					})
		},
		// 年份筛选
		changeYear(val) {
			this.endYear = val
			this.pageNum = 1
			this.getStudentList()
		},
		// 专业筛选
		handleChangeT(val) {
			this.office = val
			this.currentPage = 1
			this.getStudentList()
		},
		//就业状态筛选
		changeStatus(val) {
			this.statusNew = val
			this.pageNum = 1
			this.getStudentList()
		},
		// 导入
		choicePDF() {
			//给输入框添加自定义触发事件，使用dispatchEvent来进行手动触发
			this.$refs.filElemPDF.dispatchEvent(new MouseEvent('click'))
		},
		getFilePDF(e) {
			let file = e.target.files
			var arr = ['pdf', 'doc', 'docx']
			for (let i = 0; i < file.length; i++) {
				//取出上传文件的扩展名
				let flag = false
				var index = file[i].name.lastIndexOf('.')
				var ext = file[i].name.substr(index + 1)
				var name = file[i].name
				if (ext == 'xls' || ext == 'xlsx') {
					let formData = new FormData()
					formData.append('file', file[i]) // 文件对象
					//调用导入后台接口
					axios
							.post('/stuExcel/import', formData, {
								headers: {
									'Content-Type': 'multipart/form-data',
									access_token: sessionStorage.getItem('token')
								}
							})
							.then((res) => {
								// console.log(res);
								if (res.data.code === 200) {
									return this.$message.success(
											{
												message: '文件导入成功！',
												type: 'success'
											},
											this.getStudentList() //数据上传成功刷新表格数据
									)
								}
								if (res.data.code !== 200) {
									return this.$message.error(
											{
												message: '文件导入失败！',
												type: 'warning'
											},
											this.getStudentList() //数据上传成功刷新表格数据
									)
								}
							})
				}
			}
			this.$refs.filElemPDF.value = null
		},


		//查询所有学院
		getAllCollege() {
			this.$http({
				url: '/college/all',
				method: 'GET'
			})
					.then((res) => {
						this.options2 = res.data.data
					})
					.catch((err) => {
						console.log(err)
					})
		},
		getMajor(res) {
			this.valueMajor = null
			this.addForm.office = null
			// console.log(res);
			this.$http({
				url: '/college/allMajor',
				method: 'GET',
				params: {
					college: res
				}
			})
					.then((res) => {
						this.options3 = res.data.data
					})
					.catch((err) => {
						console.log(err)
					})
		},
		getStuCollege() {
			this.$http({
				methods: 'GET',
				url: '/college/valueLabel'
			})
					.then((res) => {
						this.options = res.data.data
					})
					.catch((err) => {
						console.log(err)
					})
		},
		handleChange(res) {
			this.addForm.office = res[1]
		},
		//  filter-method方法 ---- 筛选毕业年份
		filterHandlerEndYear(value, row) {
			// const property = column["property"];
			return row.endYear === value
		},
		filterChange(res) {
			if (res.status) {
				this.statusNew = res.status[0]
			}
			if (res.endYear) {
				this.endYear = res.endYear[0]
			}
			this.pageNum = 1
			this.getStudentList()
		},
		seek() {
			if (this.search) {
				this.studentId = this.search
				this.getStudentList()
			}
		},
		reseek(res) {
			if (!this.search) {
				this.studentId = null
				this.getStudentList()
			}
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.currentPage = newPage
			this.getStudentList()
		},
		//点击 '添加学生' or '修改' 按钮 ,添加/修改学生（预校验）
		addStudent() {
			this.$refs.addForm.validate((valid) => {
				// console.log(valid);
				if (valid) {
					if (this.modelType === 0) {
						//  添加学生
						this.$http({
							url: '/students/add',
							method: 'POST',
							data: {
								endYear: this.addForm.endYear,
								studentName: this.addForm.studentName,
								studentId: this.addForm.studentId,
								sex: this.addForm.sex,
								college: this.addForm.college,
								office: this.addForm.office,
								tel: this.addForm.tel,
								status: this.addForm.status,
								studentSource: this.addForm.studentSource,
								employment: this.addForm.employment,
								employmentUnit: this.addForm.employmentUnit,
								intentionPlaceOne: this.addForm.intentionPlaceOne,
								intentionPlaceTwo: this.addForm.intentionPlaceTwo,
								jobIntentionOne: this.addForm.jobIntentionOne,
								jobIntentionTwo: this.addForm.jobIntentionTwo,
								jobIntentionThree: this.addForm.jobIntentionThree,
								introducerOne: this.addForm.introducerOne,
								introducerOneWorkId: this.addForm.introducerOneWorkId,
								introducerOneTel: this.addForm.introducerOneTel,
								introducerTwo: this.addForm.introducerTwo,
								introducerTwoWorkId: this.addForm.introducerTwoWorkId,
								introducerTwoTel: this.addForm.introducerTwoTel
							}
						}).then((res) => {
							if (res.data.code === 200) {
								this.$message({
									type: 'success',
									message: '添加成功！'
								})
								this.options3 = null
							} else {
								this.$message({
									type: 'error',
									message: '数据异常，添加失败！'
								})
							}
							this.getStudentList()
						})
					} else {
						//  修改学生
						this.$http({
							url: '/students/update',
							method: 'PUT',
							data: {
								endYear: this.addForm.endYear,
								studentName: this.addForm.studentName,
								studentId: this.addForm.studentId,
								college: this.addForm.college,
								office: this.addForm.office,
								tel: this.addForm.tel,
								sex: this.addForm.sex,
								id: this.id,
								status: this.addForm.status,
								employment: this.addForm.employment,
								employmentUnit: this.addForm.employmentUnit,
								studentSource: this.addForm.studentSource,
								intentionPlaceOne: this.addForm.intentionPlaceOne,
								intentionPlaceTwo: this.addForm.intentionPlaceTwo,
								jobIntentionOne: this.addForm.jobIntentionOne,
								jobIntentionTwo: this.addForm.jobIntentionTwo,
								jobIntentionThree: this.addForm.jobIntentionThree,
								introducerOne: this.addForm.introducerOne,
								introducerOneWorkId: this.addForm.introducerOneWorkId,
								introducerOneTel: this.addForm.introducerOneTel,
								introducerTwo: this.addForm.introducerTwo,
								introducerTwoWorkId: this.addForm.introducerTwoWorkId,
								introducerTwoTel: this.addForm.introducerTwoTel
							}
						}).then((res) => {
							if (res.data.code == 200) {
								this.$message({
									type: 'success',
									message: '修改成功！'
								})
								this.options3 = null
							} else {
								this.$message({
									type: 'error',
									message: '数据异常，修改失败！'
								})
							}
							this.getStudentList()
						})
					}
					this.value2 = ''
					this.value = ''
					this.dialogVisible = false
					this.$refs.addForm.resetFields()
				}
			})
		},
		//根据id删除对应学生的信息
		removeStuMsgById(row) {
			//  console.log('学生ID',row.id);
			this.$confirm('此操作将永久删除该学生，是否继续？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
					.then(() => {
						this.$http({
							url: '/students',
							method: 'DELETE',
							params: {
								ids: row.id
							}
						})
								.then((res) => {
									this.$message({
										type: 'success',
										message: '删除成功'
									})
									this.getStudentList()
								})
								.catch((err) => {
									this.$message({
										type: 'error',
										message: '删除失败'
									})
								})
						this.getStudentList()
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						})
					})
		},
		//打开添加时对话框
		addList() {
			this.modelType = 0
			this.title2 = '添加学生信息'
			this.dialogVisible = true
			this.addForm.college = null
			this.addForm.office = null
		},
		//展示修改学生的对话框
		showEditDialog(row) {
			this.dialogVisible = true
			this.title2 = '修改学生信息'
			this.modelType = 1
			//再次点击时
			this.$nextTick(() => {
				this.addForm = JSON.parse(JSON.stringify(row))
			})
			this.id = row.id
			this.addForm.college = row.college
			this.addForm.office = row.office
			this.getMajor(this.addForm.college)
		},
		// 打开查看时的对话框
		checkDialog(row) {
			if (row.sex == 0) {
				row.sex = '女'
			} else {
				row.sex = '男'
			}
			if (row.status == 0) {
				row.status = '未就业'
			} else if (row.status == 1) {
				row.status = '研究生'
			} else if (row.status == 2) {
				row.status = '公务员/选调生/参军入伍'
			} else if (row.status == 3) {
				row.status = '已就业（三方协议/劳务合同）'
			} else if (row.status == 4) {
				row.status = '自主创业'
			} else if (row.status == 5) {
				row.status = '灵活就业'
			} else if (row.status == 6) {
				row.status = '自定义就业'
			}
			this.dialogVisible1 = true
			this.checkForm = JSON.parse(JSON.stringify(row))
			if (row.imageUrl) {
				this.imgUrl = row.imageUrl.split(',')
			}
			this.img64Best = [] //重置数组
			this.id = row.id
		},
		//点击取消时，清空表单内容，并关闭对话框
		handleClose() {
			this.addForm.college = null
			this.addForm.office = null
			this.$refs.addForm.resetFields()
			this.dialogVisible = false
			this.options3 = null
		},
		//点击取消时，清空表单内容，并关闭对话框
		handleClose2() {
			this.getStudentList()
			this.$refs.checkForm.resetFields()
			this.imgUrl = []

			this.dialogVisible1 = false
		},
		//获取所有学生信息列表
		getStudentList() {
			// 获取所有学生信息列表的请求
			this.$http({
				method: 'POST',
				url: '/students',
				params: {
					pageNum: this.currentPage,
					pageSize: 10
				},
				data: {
					studentId: this.studentId,
					endYear: this.endYear,
					office: this.office,
					status: this.statusNew,
					ids: this.ids
				}
			})
					.then((res) => {
						// console.log(res.data.data.total, "获取到的学生信息条数");
						this.studentList = res.data.data.records
						this.total = res.data.data.total
					})
					.catch(() => {
						this.$message({
							type: 'error',
							message: '获取学生信息失败!'
						})
					})
		},
		//导出学生信息的点击事件
		exportExcel() {
			this.$http({
				method: 'GET',
				url: '/download/studentTemplate',
				responseType: 'blob',
				data: {
					student: ''
				}
			}).then((res) => {
				const blob = new Blob([res.data], {
					type: 'application/vnd.ms-excel'
				})
				let fileName = '学生信息模板.xlsx'
				if ('download' in document.createElement('a')) {
					const elink = document.createElement('a')
					elink.download = fileName
					elink.style.display = 'none'
					elink.href = URL.createObjectURL(blob)
					document.body.appendChild(elink)
					elink.click()
					URL.revokeObjectURL(elink.href) // 释放URL 对象
					document.body.removeChild(elink)
				} else {
					navigator.msSaveBlob(blob, fileName)
				}
			})
		}
	},
	mounted() {
		this.getStudentList()
		this.getStuCollege()
		this.getAllCollege()
	},
	computed: {
		label() {
			if (this.title2 == '添加') {
				return '添加学生信息'
			} else {
				return '修改学生信息'
			}
		}
	}
}
</script>

<style lang="less" scoped>
body {
	margin: 0;
}

.dsdv {
	margin-left: 20px;
}

.block {
	text-align: center;
	margin-top: 30px;
}

.item {
	padding: 18px 0;
}

.bottom {
	clear: both;
	text-align: center;
}

.item1 {
	margin: 1px;
}
</style>
