<template>
  <div>
    <el-row class="demo-avatar demo-basic">
      <el-col :span="24" style="background-color:#ecf5ff">
        <div class="demo-basic--circle">
          <div>
            <el-avatar shape="square" :size="10"></el-avatar>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-menu
          :default-active="$route.path"
          class="el-menu-vertical-demo"
          :collapse="isCollapse"
          background-color="#ecf5ff"
          text-color="#000000"
          active-text-color="#409EFF"
          :router="true"
        >
          <el-menu-item index="/teacher/CollegeEmployment">
            <i class="el-icon-menu"></i>
            <span slot="title">学生就业情况</span>
          </el-menu-item>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-edit"></i>
              <span slot="title">学生信息</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/teacher/ChangeStatus">修改学生信息</el-menu-item>
              <el-menu-item index="/teacher/InquireAudit">查看审核进度</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-s-data"></i>
              <span slot="title">学生就业统计</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/teacher/TeacherGuidance">年度各专业就业率</el-menu-item>
              <el-menu-item index="/teacher/EmploymentRate">学生就业指导情况</el-menu-item>
              <el-menu-item index="/teacher/TeacherBonus">指导教师奖金</el-menu-item>
              <el-menu-item index="/teacher/collegeBonus">学院指导教师奖金</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <i class="el-icon-s-custom"></i>
              <span slot="title">公司人</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/teacher/CompanyMessage">招生就业公司信息</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">
              <i class="el-icon-s-management"></i>
              <span slot="title">公海</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/teacher/HighSeas">公海学生信息</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>
    
  </div>
</template>


<script>
export default {
  data() {
    return {
      isCollapse: false,
    }
  },
  methods: {
  
  }
}
</script>

<style>
.demo-basic--circle {
  margin: 2px 25px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-height: 100vh;
  width: 200px;
}
.el-avatar {
  background: #ecf5ff;
}
</style>