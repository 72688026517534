<template>
  <div>
    <el-card>
      <!-- tab页签区域 -->
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="待审核" name="0">
          <el-table :data="notAuditTableData">
            <el-table-column
              prop="studentName"
              label="姓名"
              width="80px"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="sex" label="性别" width="90" align="center">
              <template slot-scope="scope">
                {{ scope.row.sex === 1 ? "男" : "女" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="studentId"
              label="学号"
              width="105"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="college"
              label="学院"
              width="110"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="endYear"
              label="毕业学年"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="office"
              label="专业"
              width="160"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="tel" label="电话" width="115" align="center">
            </el-table-column>

            <el-table-column
              prpo="audit"
              label="操作"
              width="210px"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  round
                  size="mini"
                  @click="showAccessDialog(scope.row)"
                  >通过</el-button
                >
                <el-button
                  type="danger"
                  round
                  size="mini"
                  @click="showNOTAccessDialog(scope.row)"
                  >驳回</el-button
                >
                <el-button
                  type="info"
                  round
                  size="mini"
                  @click="showDetailAccessDialog(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="block">
            <el-pagination
              layout="total,prev, pager, next"
              :total="total"
              :page-size="10"
              @current-change="handleCurrentChange"
              background
              :current-page.sync="queryInfo.pageNum"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="审核通过" name="1">
          <el-table :data="auditedTableData">
            <el-table-column
              prop="studentName"
              label="姓名"
              width="90"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="sex" label="性别" width="90" align="center">
              <template slot-scope="scope">
                {{ scope.row.sex === 1 ? "男" : "女" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="studentId"
              label="学号"
              width="105"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="college"
              label="学院"
              width="110"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="endYear"
              label="毕业学年"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="office"
              label="专业"
              width="160"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="tel" label="电话" width="115" align="center">
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              layout="total,prev, pager, next"
              :total="total"
              :page-size="10"
              @current-change="handleCurrentChange"
              background
              :current-page.sync="queryInfo.pageNum"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="审核未通过" name="2">
          <el-table :data="notAuditedTableData">
            <el-table-column
              prop="studentName"
              label="姓名"
              width="90"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="sex" label="性别" width="90" align="center">
              <template slot-scope="scope">
                {{ scope.row.sex === 1 ? "男" : "女" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="studentId"
              label="学号"
              width="105"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="college"
              label="学院"
              width="110"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="endYear"
              label="毕业学年"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="office"
              label="专业"
              width="160"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="tel" label="电话" width="115" align="center">
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              layout="total,prev, pager, next"
              :total="total"
              :page-size="10"
              @current-change="handleCurrentChange"
              background
              :current-page.sync="queryInfo.pageNum"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 通过审核的对话框 -->
      <el-dialog
        title="通过审核"
        :visible.sync="accessDialogVisible"
        width="50%"
        :before-close="accessDialogClose"
      >
        <!-- 主体区 -->
        <el-form
          :model="accessForm"
          :rules="accessFormRules"
          ref="notAuditTableDataRef"
          label-width="100px"
        >
          <el-form-item label="姓名">
            <el-input v-model="accessForm.studentName" disabled></el-input>
          </el-form-item>

          <el-form-item label="学号">
            <el-input v-model="accessForm.studentId" disabled></el-input>
          </el-form-item>
          <el-form-item label="毕业年份">
            <el-input v-model="accessForm.endYear" disabled></el-input>
          </el-form-item>
          <el-form-item label="专业">
            <el-input v-model="accessForm.studentId" disabled></el-input>
          </el-form-item>
          <el-form-item label="所属学院">
            <el-input v-model="accessForm.college" disabled></el-input>
          </el-form-item>
          <el-form-item label="评价">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="可选择性输入评价或通过理由"
              v-model="accessForm.auditSuggestion"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="accessDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="accessAudit">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 驳回审核的对话框 -->
      <el-dialog
        title="驳回审核"
        :visible.sync="notAccessDialogVisible"
        width="50%"
        :before-close="notAccessDialogClose"
      >
        <!-- 主体区 -->
        <el-form
          :model="accessForm"
          :rules="accessFormRules"
          ref="notAuditTableDataRef"
          label-width="100px"
        >
          <el-form-item label="姓名">
            <el-input v-model="accessForm.studentName" disabled></el-input>
          </el-form-item>

          <el-form-item label="学号">
            <el-input v-model="accessForm.studentId" disabled></el-input>
          </el-form-item>
          <el-form-item label="毕业年份">
            <el-input v-model="accessForm.endYear" disabled></el-input>
          </el-form-item>
          <el-form-item label="专业">
            <el-input v-model="accessForm.studentId" disabled></el-input>
          </el-form-item>
          <el-form-item label="所属学院">
            <el-input v-model="accessForm.college" disabled></el-input>
          </el-form-item>
          <el-form-item label="原因">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入驳回原因"
              v-model="accessForm.auditSuggestion"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="notAccessDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="notAccessAudit">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 详细信息的对话框 -->

      <el-dialog
        title="学生详细信息"
        :visible.sync="detailAccessDialogVisible"
        width="100%"
        :before-close="detailAccessDialogClose"
      >
        <el-row :gutter="20" style="padding-bottom: 5px">
          <el-col :span="12"><el-tag>初始学生信息</el-tag></el-col>
          <el-col :span="12"
            ><el-tag type="success">申请变动的学生信息</el-tag></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <!-- <span>初始学生信息</span> -->

              <el-form :model="accessForm2" ref="accessForm2">
                <el-descriptions
                  class="margin-top"
                  :column="3"
                  :size="size"
                  border
                >
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-user"></i>
                      姓名
                    </template>
                    {{ accessForm2.studentName }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-s-grid"></i>
                      学号
                    </template>
                    {{ accessForm2.studentId }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-phone-outline"></i>
                      手机号
                    </template>
                    {{ accessForm2.tel }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-user-solid"></i>
                      性别
                    </template>
                    {{ accessForm2.sex === 1 ? "男" : "女" }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-collection-tag"></i>
                      专业
                    </template>
                    {{ accessForm2.office }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-office-building"></i>
                      所在学院
                    </template>
                    {{ accessForm2.college }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-date"></i>
                      毕业学年
                    </template>
                    {{ accessForm2.endYear }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-location"></i>
                      生源地
                    </template>
                    {{ accessForm2.student_source }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-location-outline"></i>
                      就业意向地一
                    </template>
                    {{ accessForm2.intentionPlaceOne }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-location-information"></i>
                      就业意向地二
                    </template>
                    {{ accessForm2.intentionPlaceTwo }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-chat-round"></i>
                      就业意向一
                    </template>
                    {{ accessForm2.jobIntentionOne }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-chat-line-round"></i>
                      就业意向二
                    </template>
                    {{ accessForm2.jobIntentionTwo }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-chat-dot-round"></i>
                      就业意向三
                    </template>
                    {{ accessForm2.jobIntentionThree }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-suitcase"></i>
                      介绍人一
                    </template>
                    {{ accessForm2.introducerOne }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-document-checked"></i>
                      介绍人一工号
                    </template>
                    {{ accessForm2.introducerOneWorkId }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-mobile"></i>
                      介绍人一手机号
                    </template>
                    {{ accessForm2.introducerOneTel }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-suitcase-1"></i>
                      介绍人二
                    </template>
                    {{ accessForm2.introducerTwo }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-document"></i>
                      介绍人二工号
                    </template>
                    {{ accessForm2.introducerTwoWorkId }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-mobile-phone"></i>
                      介绍人二手机号
                    </template>
                    {{ accessForm2.introducerTwoTel }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-s-flag"></i>
                      就业状态
                    </template>
                    {{
                      accessForm2.status === 0
                        ? "未就业"
                        : accessForm2.status === 1
                        ? "研究生"
                        : accessForm2.status === 2
                        ? "公务员/选调生/参军入伍"
                        : accessForm2.status === 3
                        ? "已就业（三方协议/劳务合同）"
                        : accessForm2.status === 4
                        ? "自主创业"
                        : accessForm2.status === 5
                        ? "灵活就业"
                        : "自定义就业"
                    }}
                  </el-descriptions-item>
                </el-descriptions>
              </el-form>
            </div></el-col
          >
          <el-col :span="12"
            ><div
              class="grid-content bg-purple-light"
              style="
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
                  0 0 6px rgba(0, 0, 0, 0.04);
                padding-right: 2px;
              "
            >
              <!-- 主体区 -->
              <el-form :model="accessForm" ref="accessForm">
                <el-descriptions
                  class="margin-top"
                  :column="3"
                  :size="size"
                  border
                >
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-user"></i>
                      姓名
                    </template>
                    {{ accessForm.studentName }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-s-grid"></i>
                      学号
                    </template>
                    {{ accessForm.studentId }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-phone-outline"></i>
                      手机号
                    </template>
                    {{ accessForm.tel }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-user-solid"></i>
                      性别
                    </template>
                    {{ accessForm.sex === 1 ? "男" : "女" }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-collection-tag"></i>
                      专业
                    </template>
                    {{ accessForm.office }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-office-building"></i>
                      所在学院
                    </template>
                    {{ accessForm.college }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-date"></i>
                      毕业学年
                    </template>
                    {{ accessForm.endYear }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-location"></i>
                      生源地
                    </template>
                    {{ accessForm.student_source }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-location-outline"></i>
                      就业意向地一
                    </template>
                    {{ accessForm.intentionPlaceOne }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-location-information"></i>
                      就业意向地二
                    </template>
                    {{ accessForm.intentionPlaceTwo }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-chat-round"></i>
                      就业意向一
                    </template>
                    {{ accessForm.jobIntentionOne }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-chat-line-round"></i>
                      就业意向二
                    </template>
                    {{ accessForm.jobIntentionTwo }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-chat-dot-round"></i>
                      就业意向三
                    </template>
                    {{ accessForm.jobIntentionThree }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-suitcase"></i>
                      介绍人一
                    </template>
                    {{ accessForm.introducerOne }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-document-checked"></i>
                      介绍人一工号
                    </template>
                    {{ accessForm.introducerOneWorkId }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-mobile"></i>
                      介绍人一手机号
                    </template>
                    {{ accessForm.introducerOneTel }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-suitcase-1"></i>
                      介绍人二
                    </template>
                    {{ accessForm.introducerTwo }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-document"></i>
                      介绍人二工号
                    </template>
                    {{ accessForm.introducerTwoWorkId }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-mobile-phone"></i>
                      介绍人二手机号
                    </template>
                    {{ accessForm.introducerTwoTel }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-edit"></i>
                      修改老师名字
                    </template>
                    {{ accessForm.updateTeacher }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-tickets"></i>
                      修改老师工号
                    </template>
                    {{ accessForm.workIdUpdate }}
                  </el-descriptions-item>
                  <el-descriptions-item prop="studentName">
                    <template slot="label">
                      <i class="el-icon-s-flag"></i>
                      就业状态
                    </template>
                    {{
                      accessForm.status === 0
                        ? "未就业"
                        : accessForm.status === 1
                        ? "研究生"
                        : accessForm.status === 2
                        ? "公务员/选调生/参军入伍"
                        : accessForm.status === 3
                        ? "已就业（三方协议/劳务合同）"
                        : accessForm.status === 4
                        ? "自主创业"
                        : accessForm.status === 5
                        ? "灵活就业"
                        : "自定义就业"
                    }}
                  </el-descriptions-item>
                </el-descriptions>
              </el-form>
            </div></el-col
          >
        </el-row>

        <!-- 底部 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="echoPicture" :loading="loading"
            >查看相关附件</el-button
          >
        </span>
      </el-dialog>
      <!-- 图片展示对话框 -->
      <el-dialog
        title="就业信息图片"
        :visible.sync="dialogVisible3"
        width="60%"
        :before-close="handleClose2"
      >
        <div class="demo-image">
          <div class="block" v-for="item in img64Best" :key="item.index">
            <el-image
              style="width: 100px; height: 100px"
              :src="item"
              :preview-src-list="img64Best"
            ></el-image>
          </div>
        </div>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      accessTextarea: "",
      // img: [],
      majorOptions: [
        {
          value: "rongmeixueyuan",
          label: "传媒学院",
          children: [
            {
              value: "boyinyuzhuchiyishu",
              label: "播音与主持艺术",
            },
            {
              value: "guangbodianshibiandao",
              label: "广播电视编导",
            },
            {
              value: "",
              label: "新闻学",
            },
            {
              value: "",
              label: "广告学",
            },

            {
              value: "wangluoyuxinmeiti",
              label: "网络与新媒体",
            },
          ],
        },
        {
          value: "renwenxueyuan",
          label: "人文学院",
          children: [
            {
              value: "yingyu",
              label: "英语",
            },
            {
              value: "yingyongxinlixue",
              label: "应用心理学",
            },
            {
              value: "faxue",
              label: "法学",
            },
            {
              value: "xueqianjiaoyu",
              label: "学前教育",
            },
            {
              value: "hanyuyanwenxue",
              label: "汉语言文学",
            },
          ],
        },
        {
          value: "yishuxueyuan",
          label: "艺术学院",
          children: [
            {
              value: "huanjingsheji",
              label: "环境设计",
            },
          ],
        },
        {
          value: "yixueyuan",
          label: "医学院",
        },

        {
          value: "jingjixueyuan",
          label: "经济学院",
        },
        {
          value: "xinxigongchengxueyuan",
          label: "信息工程学院",
        },
        {
          value: "shangxueyuan",
          label: "商学院",
        },
      ],

      // 被激活的页签名称
      activeName: "0",
      search: "",
      // 待审核数据表格T
      notAuditTableData: [],
      // 审核通过数据表格
      auditedTableData: [],
      // 审核未通过数据表格
      notAuditedTableData: [],
      // 审核通过的表单验证规则对象
      accessFormRules: {},
      // 控制通过审核对话框的显示与隐藏
      accessDialogVisible: false,
      // 控制驳回审核对话框的显示与隐藏
      notAccessDialogVisible: false,
      // 控制详细信息对话框的显示和隐藏
      detailAccessDialogVisible: false,
      dialogVisible3: false,
      imgUrl: [],
      imgUrlNew: "", //参数
      //  img64: "", //存储64
      img64Best: [],
      loading: false,
      size: "",
      queryInfo: {
        page: 1,
        pageSize: 10,
        studentName: "",
        auditStatus: 0,
      },
      // 通过/驳回的表单数据(修改前)
      accessForm: {
        token: "",
        id: "",
        studentNumber: "",
        studentId: "",
        studentName: "",
        tel: "",
        sex: "",
        office: "",
        college: "",
        endYear: "",
        studentSource: "",
        intentionPlaceOne: "",
        intentionPlaceTwo: "",
        jobIntentionOne: "",
        jobIntentionTwo: "",
        jobIntentionThree: "",
        introducerOne: "",
        introducerOneWorkId: "",
        introducerOneTel: "",
        introducerTwo: "",
        introducerTwoWorkId: "",
        introducerTwoTel: "",
        workIdUpdate: "",
        updateTeacher: "",
        auditStatus: "",
        status: "",
        imageUrl: "",
        modifyType: "",
        modify_detail: "",
        auditSuggestion: "",
      },
      // 修改后
      accessForm2: {},
    };
  },
  created() {
    this.getNotAudited();
  },
  methods: {
    cancel() {
      this.imgUrl = [];
      this.detailAccessDialogVisible = false;
    },
    echoPicture() {
      if (this.imgUrl.length == 0) {
        this.$message.info("该学生暂无相关数据");
      } else {
        this.loading = true;
        for (let i = 0; i < this.imgUrl.length - 1; i++) {
          this.imgUrlNew = this.imgUrl[i]; //参数
          this.downloadPicture(i); //图片下载请求
        }
        setTimeout(() => {
          this.dialogVisible3 = true;
        }, 1500);
      }
    },
    downloadPicture(i) {
      this.$http({
        url: "/common/imgDownload",
        method: "GET",
        responseType: "blob",
        params: {
          imgUrl: this.imgUrlNew,
        },
      }).then((res) => {
        //  console.log("请求完成。。。");
        this.getBase64(res.data).then((base64) => {
          //  this.img64 = base64;
          //  console.log("img64存储：", this.img64);
          this.img64Best[i] = base64;
          //  console.log("转换完成");
        });
      });
    },
    getBase64(data) {
      return new Promise((resolve, reject) => {
        const blob = new Blob([data], { type: "image/jpg" }); // 必须指定type类型
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleClose2() {
      this.loading = false;
      this.dialogVisible3 = false;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    filterHandlerEmployment(value, row) {
      return row.EmploymentState === value;
    },
    handleClick(row) {
      // console.log(row);
    },
    // 选择具体专业用到的方法
    handleChange(value) {
      // console.log(value);
    },
    // tab页签点击事件处理函数
    handleTabClick() {
      this.getNotAudited();
    },
    //查询待审核学生信息(修改前)
    async getNotAudited() {
      const { data: res } = await this.$http.get("students/auditedMsg", {
        params: {
          page: this.queryInfo.page,
          pageSize: this.queryInfo.pageSize,
          auditStatus: this.activeName,
          studentName: this.queryInfo.studentName,
        },
      });
      if (res.code != 200) {
        this.$message.error("查询失败");
      }
      // console.log(res.data.records);
      this.notAuditTableData = res.data.records;
      this.auditedTableData = res.data.records;
      this.notAuditedTableData = res.data.records;
      this.total = res.data.total || 0;
    },
    // 修改后
    async getNotAudited2() {
      const { data: res } = await this.$http.post(
        "/students/byId?id=" + this.accessForm.studentNumber
      );
      if (res.code != 200) {
        this.$message.error("查询失败");
      }
      this.accessForm2 = res.data;
    },
    accessDialogClose() {
      this.accessDialogVisible = false;
    },
    notAccessDialogClose() {
      this.notAccessDialogVisible = false;
    },
    detailAccessDialogClose() {
      this.detailAccessDialogVisible = false;
    },
    // 监听分页条数改变的处理函数
    // 监听分页条数的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getNotAudited();
    },
    // 监听分页页码值改变的处理函数
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getNotAudited();
    },
    // 展示审核通过的对话框
    showAccessDialog(row) {
      this.accessForm.id = row.id;
      this.accessForm.studentNumber = row.studentNumber;
      this.accessForm.studentId = row.studentId;
      this.accessForm.studentName = row.studentName;
      this.accessForm.tel = row.tel;
      this.accessForm.sex = row.sex;
      this.accessForm.office = row.office;
      this.accessForm.college = row.college;
      this.accessForm.endYear = row.endYear;
      this.accessForm.studentSource = row.studentSource;
      this.accessForm.intentionPlaceOne = row.intentionPlaceOne;
      this.accessForm.intentionPlaceTwo = row.intentionPlaceTwo;
      this.accessForm.jobIntentionOne = row.jobIntentionOne;
      this.accessForm.jobIntentionTwo = row.jobIntentionTwo;
      this.accessForm.jobIntentionThree = row.jobIntentionThree;
      this.accessForm.introducerOne = row.introducerOne;
      this.accessForm.introducerOneWorkId = row.introducerOneWorkId;
      this.accessForm.introducerOneTel = row.introducerOneTel;
      this.accessForm.workIdUpdate = row.workIdUpdate;
      this.accessForm.updateTeacher = row.updateTeacher;
      this.accessForm.auditStatus = row.auditStatus;
      this.accessForm.status = row.status;
      this.accessForm.imageUrl = row.imageUrl;
      this.accessForm.modifyType = row.modifyType;
      this.accessForm.modify_detail = row.modify_detail;
      this.accessForm.auditSuggestion = row.auditSuggestion;
      this.accessDialogVisible = true;
    },
    // 展示驳回审核的对话框
    showNOTAccessDialog(row) {
      this.accessForm.id = row.id;
      this.accessForm.studentNumber = row.studentNumber;
      this.accessForm.studentId = row.studentId;
      this.accessForm.studentName = row.studentName;
      this.accessForm.tel = row.tel;
      this.accessForm.sex = row.sex;
      this.accessForm.office = row.office;
      this.accessForm.college = row.college;
      this.accessForm.endYear = row.endYear;
      this.accessForm.studentSource = row.studentSource;
      this.accessForm.intentionPlaceOne = row.intentionPlaceOne;
      this.accessForm.intentionPlaceTwo = row.intentionPlaceTwo;
      this.accessForm.jobIntentionOne = row.jobIntentionOne;
      this.accessForm.jobIntentionTwo = row.jobIntentionTwo;
      this.accessForm.jobIntentionThree = row.jobIntentionThree;
      this.accessForm.introducerOne = row.introducerOne;
      this.accessForm.introducerOneWorkId = row.introducerOneWorkId;
      this.accessForm.introducerOneTel = row.introducerOneTel;
      this.accessForm.workIdUpdate = row.workIdUpdate;
      this.accessForm.updateTeacher = row.updateTeacher;
      this.accessForm.auditStatus = row.auditStatus;
      this.accessForm.status = row.status;
      this.accessForm.imageUrl = row.imageUrl;
      this.accessForm.modifyType = row.modifyType;
      this.accessForm.modify_detail = row.modify_detail;
      this.accessForm.auditSuggestion = row.auditSuggestion;
      this.notAccessDialogVisible = true;
    },
    // 展示详细信息对话框
    showDetailAccessDialog(row) {
      // 修改后表格的数据
      this.accessForm.id = row.id;
      this.accessForm.studentNumber = row.studentNumber;
      this.accessForm.studentId = row.studentId;
      this.accessForm.studentName = row.studentName;
      this.accessForm.tel = row.tel;
      this.accessForm.sex = row.sex;
      this.accessForm.office = row.office;
      this.accessForm.college = row.college;
      this.accessForm.endYear = row.endYear;
      this.accessForm.studentSource = row.studentSource;
      this.accessForm.intentionPlaceOne = row.intentionPlaceOne;
      this.accessForm.intentionPlaceTwo = row.intentionPlaceTwo;
      this.accessForm.jobIntentionOne = row.jobIntentionOne;
      this.accessForm.jobIntentionTwo = row.jobIntentionTwo;
      this.accessForm.jobIntentionThree = row.jobIntentionThree;
      this.accessForm.introducerOne = row.introducerOne;
      this.accessForm.introducerOneWorkId = row.introducerOneWorkId;
      this.accessForm.introducerOneTel = row.introducerOneTel;
      this.accessForm.workIdUpdate = row.workIdUpdate;
      this.accessForm.updateTeacher = row.updateTeacher;
      this.accessForm.auditStatus = row.auditStatus;
      this.accessForm.status = row.status;
      this.accessForm.imageUrl = row.imageUrl;
      this.accessForm.modifyType = row.modifyType;
      this.accessForm.modify_detail = row.modify_detail;
      this.accessForm.auditSuggestion = row.auditSuggestion;
      // 修改前表格的数据
      this.getNotAudited2();

      this.detailAccessDialogVisible = true;
      // console.log("XUESHENG", row);
      // console.log(this.accessForm.imageUrl);
      if (row.imageUrl) {
        this.imgUrl = row.imageUrl.split(",");
      }
    },
    // 通过审核
    async accessAudit() {
      this.$refs.notAuditTableDataRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.put("students/auditedMsg?", {
          id: this.accessForm.id,
          studentNumber: this.accessForm.studentNumber,
          studentId: this.accessForm.studentId,
          studentName: this.accessForm.studentName,
          tel: this.accessForm.tel,
          sex: this.accessForm.sex,
          office: this.accessForm.office,
          college: this.accessForm.college,
          endYear: this.accessForm.endYear,
          studentSource: this.accessForm.studentSource,
          intentionPlaceOne: this.accessForm.intentionPlaceOne,
          intentionPlaceTwo: this.accessForm.intentionPlaceTwo,
          jobIntentionOne: this.accessForm.jobIntentionOne,
          jobIntentionTwo: this.accessForm.jobIntentionTwo,
          jobIntentionThree: this.accessForm.jobIntentionThree,
          introducerOne: this.accessForm.introducerOne,
          introducerOneWorkId: this.accessForm.introducerOneWorkId,
          introducerOneTel: this.accessForm.introducerOneTel,
          introducerTwo: this.accessForm.intentionPlaceTwo,
          introducerTwoWorkId: this.accessForm.introducerOneWorkId,
          introducerTwoTel: this.accessForm.introducerTwoTel,
          workIdUpdate: this.accessForm.workIdUpdate,
          updateTeacher: this.accessForm.updateTeacher,
          auditStatus: this.accessForm.auditStatus + 1,
          status: this.accessForm.status,
          imageUrl: this.accessForm.imageUrl,
          modifyType: this.accessForm.modifyType,
          modify_detail: this.accessForm.modify_detail,
          auditSuggestion: this.accessForm.auditSuggestion,
        });
        if (res.code !== 200) {
          return this.$message.error("通过审核失败");
        }
        // this.accessForm.auditStatus = 2;
        // 关闭对话框，刷新用户列表，提示修改成功
        this.accessDialogVisible = false;
        this.getNotAudited();
        this.$message.success("通过审核成功");
      });
    },
    // 驳回审核
    async notAccessAudit() {
      this.$refs.notAuditTableDataRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.put("students/auditedMsg?", {
          id: this.accessForm.id,
          studentNumber: this.accessForm.studentNumber,
          studentId: this.accessForm.studentId,
          studentName: this.accessForm.studentName,
          tel: this.accessForm.tel,
          sex: this.accessForm.sex,
          office: this.accessForm.office,
          college: this.accessForm.college,
          endYear: this.accessForm.endYear,
          studentSource: this.accessForm.studentSource,
          intentionPlaceOne: this.accessForm.intentionPlaceOne,
          intentionPlaceTwo: this.accessForm.intentionPlaceTwo,
          jobIntentionOne: this.accessForm.jobIntentionOne,
          jobIntentionTwo: this.accessForm.jobIntentionTwo,
          jobIntentionThree: this.accessForm.jobIntentionThree,
          introducerOne: this.accessForm.introducerOne,
          introducerOneWorkId: this.accessForm.introducerOneWorkId,
          introducerOneTel: this.accessForm.introducerOneTel,
          introducerTwo: this.accessForm.intentionPlaceTwo,
          introducerTwoWorkId: this.accessForm.introducerOneWorkId,
          introducerTwoTel: this.accessForm.introducerTwoTel,
          workIdUpdate: this.accessForm.workIdUpdate,
          updateTeacher: this.accessForm.updateTeacher,
          auditStatus: this.accessForm.auditStatus + 2,
          status: this.accessForm.status,
          imageUrl: this.accessForm.imageUrl,
          modifyType: this.accessForm.modifyType,
          modify_detail: this.accessForm.modify_detail,
          auditSuggestion: this.accessForm.auditSuggestion,
        });
        if (res.code !== 200) {
          return this.$message.error("驳回审核失败");
        }
        // 关闭对话框，刷新用户列表，提示修改成功
        this.notAccessDialogVisible = false;
        this.getNotAudited();
        this.$message.success("驳回审核成功");
      });
    },
  },
};
</script>

<style>
.div2 {
  text-align: center;
}
.cat_opt {
  margin: 15px 0px;
}
</style>
