<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="com-container">
    <el-row>
      <el-col :span="24">
        <el-card class="box-card">
          <div class="year">
            <el-date-picker
              v-model="valueYear"
              type="year"
              placeholder="选择年"
              value-format="yyyy"
              @change="selectYear(valueYear)"
            ></el-date-picker>
          </div>
          <div class="com-chart" ref="employment_ref"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myChart: "",
      valueYear: (new Date().getFullYear()-1).toString(),
      dataX: [],
      dataY: [],
      colorList: ['#C23531'],
    };
  },
  methods: {
    // getYear() {
    //   this.$http({
    //     url: "/jobRate/getByCondition",
    //     method: "POST",
    //     params: {
    //       pageNum: this.addForm.pageNum,
    //       pageSize: this.addForm.pageSize,
    //     },
    //     data: {
    //       endYear: this.valueYear,
    //       college: this.addForm.college,
    //       major: this.addForm.major,
    //     },
    //   })
    //     .then((res) => {
    //       this.yearList = res.data.data.records;
    //       // console.log(res.data.data.records);
    //       this.total = res.data.data.total || 0;
    //       console.log(this.yearList)
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    drawBarChart() {
      this.myChart = this.$echarts.init(this.$refs.employment_ref, {
        renderer: "svg",
      });
      this.myChart.setOption({
        title: {
          text: "各学院就业率",
          x: "center",
          //   top: '0.5%'
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          data: ["就业率"],
          bottom: "20",
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "就业率",
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
              formatter: "{value} %",
            },
          },
          //   {
          //     type: 'value',
          //     name: '人数',
          //     min: 0,
          //     max: 100,
          //     interval: 5,
          //     axisLabel: {
          //       formatter: '{value} 位'
          //     }
          //   }
        ],
        series: [
          {
            name: "就业率",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + "%";
              },
            },
            data: [],
            itemStyle: {   
                    //通常情况下：
                    normal:{  
        //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
              color: function(params) {
              return this.colorList[params.dataIndex % this.colorList.length];
            }.bind(this) // 注意这里需要 bind(this) 来确保回调函数能访问到组件实例的上下
                    }},
          },

          //   {
          //     name: '人数',
          //     type: 'line',
          //     yAxisIndex: 1,
          //     tooltip: {
          //       valueFormatter: function(value) {
          //         return value + '位'
          //       }
          //     },
          //     data: []
          //   }
        ],
      });
    },

    async initData() {
      // this.getYear();
      // console.log("1111111")
      const { data: res } = await this.$http.get("/echarts/collegeStuJobRate", {
        params: {
          endYear: this.valueYear,
        },
      });
      this.dataX = [];
      this.dataY = [];
      //先进行赋值
      for (let i = 0; i < res.data.length; i++) {
        this.dataX[i] = res.data[i].college;
        this.dataY[i] = res.data[i].rate;
      }
      if (this.dataX.length === 0) {
    // 数据为空时显示加载提示
    // this.myChart.clear();
    this.myChart.showLoading({
      text: '暂无数据',
      color: '#999',
      textColor: '#333',
      maskColor: 'rgba(255, 255, 255, 0.8)',
      zlevel: 0,
      textStyle: {
        fontSize: 16 // 调整字体大小
      }
    });
    return;
  }
  // 数据加载完成后隐藏加载提示
  this.myChart.hideLoading();
      this.dataY.co1
      this.myChart.setOption({
        xAxis: [{ data: this.dataX }],
        series: [
          {
            type: "bar",
            data: this.dataY,
          },

          //   {
          //     type: 'line',
          //     data: this.dataY
          //   }
        ],
      });
    },
    // 选择年份的方法
    selectYear(val) {
      this.valueYear = val;
      this.initData();
    },
  },
  mounted() {
    this.initData();
    // this.drawCharts()
    this.drawBarChart();
  },
};
</script>

<style lang="less" scoped>
.com-container {
  padding-top: 10px;
  .com-chart {
    padding-top: 60px;
    width: 100%;
    height: 400px;
  }
}
</style>
