<template>
  <div ref="container" class="container">

    <ul>
      <li>
        <div class="panels-container">
          <div class="panel left-panel">
            <div class="content">
              <h1>河南开封科技传媒学院</h1>
              <h2 class="h2">招就系统</h2>
            </div>
            <img alt="" class="image" src="../../assets/images/log.svg"/>
          </div>
        </div>
      </li>
      <li class="li-er">
        <div class="forms-containersignin-signup">
          <div class="">
            <img alt="" class="pnj-school" src="../../assets/images/logo.png">

            <form action="#" class="sign-in-form">
              <h2 class="title">登录</h2>
              <br>
              <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules">
                <!-- 用户名 -->
                <el-form-item class="input-field" prop="username">
                  <i class="style1"></i>
                  <el-input v-model="loginForm.username" class="jhlyanse" placeholder="账号" prefix-icon="el-icon-user"
                      type="text">
                  </el-input>
                </el-form-item>
                <br>
                <!-- 密码 -->
                <el-form-item class="input-field" prop="password">
                  <i class="style1"></i>
                  <el-input

                      v-model="loginForm.password" clearable placeholder="密码" prefix-icon="el-icon-unlock"
                      show-password
                      type="password">
                  </el-input>
                </el-form-item>
                <el-form-item class="btns">
                  <br>
                  <el-button class="sign-in-btn" round type="primary" @click="login">登录
                  </el-button>
                </el-form-item>
                <div class="wmsg">
                  <br />
                  <p class="wmsg-p">系统技术支持：信息工程学院；系统版本：V2.0</p>
                  <el-link class="wmsg-p" href="https://beian.miit.gov.cn/" target="_blank">ICP备案号: 豫ICP备17027277号-2
                  </el-link>
                </div>
              </el-form>


            </form>

          </div>
        </div>

      </li>
    </ul>

  </div>
</template>

<script>
export default {
  data() {
    return {
      managerData: {},
      teacherData: {},
      leaderData: {},
      workIdUpdate: '',
      // 登陆表单的数据绑定对象
      loginForm: {
        username: '',
        password: '',
        role: '0'
      },

      // 表单的验证规则对象
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 10,
            message: '长度在 3 到 10 个字符',
            trigger: 'blur'
          }
        ],
        // 验证密码是否合法
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown);
  },
  methods: {
    keyDown(e) {
      if (e.keyCode == 13) {
        this.login();
      }
    },
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        this.loginForm.role = 0;
        const firstRes = await this.$http.post('/Login', this.loginForm);
        console.log(firstRes.data);
        var lastRes = firstRes;
        if (firstRes.data.code !== 200) {
          return this.$message.error('登录失败');
        } else if (firstRes.data.data == null) {
          this.loginForm.role = 1;
          const secondRes = await this.$http.post('/Login', this.loginForm);
          lastRes = secondRes;
          console.log(secondRes.data);
          if (secondRes.data.code !== 200) {
            return this.$message.error('登录失败');
          } else if (secondRes.data.data == null) {
            this.loginForm.role = 2;
            const thirdRes = await this.$http.post('/Login', this.loginForm);
            lastRes = thirdRes;
            if (thirdRes.data.code !== 200) {
              return this.$message.error('登录失败');
            } else if (thirdRes.data.data == null) {
              return this.$message.error('账号或密码错误');
            }
          }
        }
        this.$message.success('欢迎登入');
        window.sessionStorage.setItem('token', lastRes.data.data.token);
        if (lastRes.data.data.role == 0) {
          this.leaderData = lastRes.data.data;
          window.sessionStorage.setItem(
              'leaderData',
              JSON.stringify(this.leaderData)
          );
          this.$router.push('/leader');
        } else if (lastRes.data.data.role == 1) {
          this.managerData = lastRes.data.data;
          this.$router.push('/manager');
          window.sessionStorage.setItem(
              'managerData',
              JSON.stringify(this.managerData)
          );
        } else if (lastRes.data.data.role == 2) {
          this.$router.push('/teacher');
          this.teacherData = lastRes.data.data;
          this.workIdUpdate = lastRes.data.data.worker_id;
          window.sessionStorage.setItem('workIdUpdate', this.workIdUpdate);
          window.sessionStorage.setItem(
              'teacherData',
              JSON.stringify(this.teacherData)
          );
        }
      });
    }
  }
};
</script>

<style scoped>
.li-er {
  position: relative;

  width: 800px;
  height: 800px;
}

* {
  user-select: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wmsg {
  text-align: left;
  vertical-align: text-top;
}

.wmsg-p {
  font-size: 12px;
  color: #666363;
}

.jhlyanse {
  border-radius: 30px !important;
  background-color: #f0f0f0;
}

ul {
  user-select: none;

  float: right;
}

body,
input {
  font-family: "Poppins", sans-serif;
}


h1 {
  user-select: none;
  font-family: cursive;
  width: 500px;
  font-size: 50px;
  line-height: 150px;
  text-shadow: 2px 2px 3px rgb(14, 13, 13);

  color: #ffffff;
}

.pnj-school {
  position: absolute;
  width: 200px;
  top: 50px;
  right: 388px;
}

.h2 {
  user-select: none;
  width: 500px;
  text-shadow: 2px 2px 3px rgb(14, 13, 13);
  font-size: 40px;
  line-height: 130px;
  font-family: cursive;
  color: #ffffff;
}

.container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  position: absolute;
  height: 600px;
  top: 200px;
  left: -50px;

  background-size: 100%;


}

.title {
  font-size: 2.2rem;
  color: #000000;
  margin-bottom: 10px;

  font-family: cursive;
}

.input-field {
  /* max-width: 380px; */
  width: 400px;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 30px;
  /* display: grid; */
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.style1 {
  /* text-align: left; */
  line-height: 55px;
  /* color: #acacac; */
  /* transition: 0.5s; */
  /* font-size: 1.1rem; */
}

.input-field input {
  background: none;
  outline: none;
  border-radius: 30px;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}

.btns {
  margin-top: 8px;
}

.sign-in-btn {
  height: 49px;
  width: 400px;
  border-radius: 30px;
}

.btn {
  width: 150px;
  background-color: #5995fd;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btn:hover {
  background-color: #4d84e2;
}

.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #4481eb 0%, #04befe 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container.sign-up-mode .left-panel .image,
.container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container.sign-up-mode .signin-signup {
  left: 25%;
}

.container.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.container.sign-up-mode .right-panel .image,
.container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container.sign-up-mode .left-panel {
  pointer-events: none;
}

.container.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .container {
    min-height: 800px;
    height: 100vh;
  }

  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .container.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container.sign-up-mode .left-panel .image,
  .container.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container.sign-up-mode .right-panel .image,
  .container.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }

  .panel .content {
    padding: 0.5rem 1rem;
  }

  .container {
    padding: 1.5rem;
  }

  .container:before {
    bottom: 72%;
    left: 50%;
  }

  .container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}
</style>

