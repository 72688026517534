<template>
	<div ref="tableBox" class="teacher-table">
    <el-col :span="6">
      <el-date-picker v-model="value" placeholder="选择年" type="year" value-format="yyyy" @change="selectYear(value)">
      </el-date-picker>
    </el-col>
    <el-col :span="6">
      <el-button type="primary" @click="exportExcel">导出师生指导就业对照表</el-button>
    </el-col>

		<br>
		<br>
		<el-table v-if="tableHeight" :data="tableData" :max-height="tableHeight + 'px'" border stripe>
			<el-table-column fixed label="学生姓名" prop="studentName"></el-table-column>
			<el-table-column label="学号" prop="studentId"></el-table-column>
			<el-table-column label="学院" prop="college"></el-table-column>
			<el-table-column label="专业" prop="office"></el-table-column>
			<el-table-column label="就业状态" prop="jobType"></el-table-column>
			<el-table-column label="推荐人一" prop="introducerOne"></el-table-column>
			<el-table-column label="推荐人一工号" prop="introducerOneWorkId"></el-table-column>
			<el-table-column label="推荐人二" prop="introducerTwo"></el-table-column>
			<el-table-column label="推荐人二工号" prop="introducerTwoWorkId"></el-table-column>
		</el-table>

		<el-skeleton :loading="dataLoading" :rows="6" animated/>
	</div>
</template>
<script>
export default {
	data() {
		return {
			file: '',
			filename: '',
			value: new Date().getFullYear().toString(),
			year: '',
			dataLoading: true,
			tableHeight: 0,
			tableData: []
		};
	},
	mounted() {
		this.loadData()
		//减去翻页的高度，因为容器包含了表格和翻页
		this.tableHeight = this.$refs.tableBox.clientHeight - 40
	},
	methods: {
		//导出就业奖励总计表的点击事件
		exportExcel() {
			this.$http({
				method: 'GET',
				url: '/download/TeaStuRelationshipExcel',
				responseType: 'blob',
				params: {
					year: this.value
				}

			})
					.then((res) => {
						const blob = new Blob([res.data], {
							type: 'application/vnd.ms-excel'
						});
						let fileName = '师生指导就业对照表.xlsx';
						if ('download' in document.createElement('a')) {
							const elink = document.createElement('a');
							elink.download = fileName;
							elink.style.display = 'none';
							elink.href = URL.createObjectURL(blob);
							document.body.appendChild(elink);
							elink.click();
							URL.revokeObjectURL(elink.href); // 释放URL 对象
							document.body.removeChild(elink);
						} else {
							navigator.msSaveBlob(blob, fileName);
						}
						if (res.status === 200) {
							this.$message({
								type: 'success',
								message: '文件导出成功！'
							});
						} else {
							this.$message({
								type: 'error',
								message: '数据异常，文件导出失败！'
							});
						}
					})
					.catch((error) => {
						console.log(error);
						this.$message({
							type: 'error',
							message: '数据异常，文件导出失败！'
						});
					});
		},
		async loadData() {
			try {
				const res = await this.$http.get('/students/teaStuGuidanceRelationship', {
					params: {
						endYear: this.value
					}
				})
				if (res?.data) {
					this.dataLoading = false
					this.tableData = res.data.data

				}
			} catch (error) {
				console.error('Failed to load data:', error)
			}
		},
		selectYear(valueYear) {
			this.value = valueYear
			this.dataLoading = false
			this.loadData()
		}
	}
};
</script>

<style lang="less" scoped>
.teacher-table {
	height: calc(100vh - 190px); /*示例中顶部区域固定高度190px*/
}
</style>
