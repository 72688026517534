<template>
  <div>
    <!-- 添加校领导按钮区域 -->
    <el-row :gutter="10">
      <el-col :span="4">
        <span
          ><el-button type="primary" size="small" @click="handleAdd"
            >添加校领导</el-button
          ></span
        >
      </el-col>
      <el-col :span="4">
        <el-input
          clearable
          v-model="search"
          placeholder="输入姓名搜索"
          @change="searchHandle"
        ></el-input>
      </el-col>
    </el-row>
    <!-- 校领导信息列表 -->
    <el-table
      :data="leaderlist.filter((data) => !search || data.name.includes(search))"
      style="width: 100%"
    >
      <el-table-column
        label="姓名"
        prop="name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="工号"
        prop="workerId"
        align="center"
      ></el-table-column>
      <el-table-column
        label="密码"
        prop="password"
        align="center"
      ></el-table-column>
      <el-table-column label="所属学院" prop="college" align="center">
      </el-table-column>
      <el-table-column
        label="联系电话"
        prop="tel"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="250px">
        <!-- 作用域插槽 -->
        <template slot-scope="scope">
          <!-- 编辑按钮 -->
          <el-button
            type="primary"
            size="mini"
            round
            @click="showEditDialog(scope.row)"
            >修改</el-button
          >
          <!-- 删除按钮 -->
          <el-button
            type="danger"
            size="mini"
            round
            @click="removeLeaderById(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加、编辑对话框 -->
    <el-dialog
      :title="title2"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="120px"
      >
        <el-row>
          <el-col :span="22">
            <el-form-item label="姓名：" prop="name">
              <el-input v-model="ruleForm.name"></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="工号：" prop="workerId">
              <el-input v-model="ruleForm.workerId"></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="密码：" prop="password">
              <el-input v-model="ruleForm.password"></el-input> </el-form-item
          ></el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="22">
            <el-form-item label="所属学院：" prop="college">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in selectCollege"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row> -->
        <el-row>
          <el-col :span="22">
            <el-form-item label="联系电话：" prop="tel">
              <el-input v-model="ruleForm.tel"></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addEditLeader">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectCollege: [],
      value: "",
      id: "",
      leaderlist: [],
      dialogVisible: false,
      modolType: 0, //0表示添加 1表示修改
      search: "",
      title2: "添加",
      // options: [],
      ruleForm: {
        name: null,
        workerId: null,
        password: null,
        college: null,
        tel: null,
      },
      rules: {
        name: [{ required: true, message: "请输入领导姓名", trigger: "blur" }],
        workerId: [{ required: true, message: "请输入工号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        college: [
          { required: true, message: "请输入所属学院", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "所属学院的长度在3~10个字符之间",
            trigger: "blur",
          },
        ],
        tel: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            validator:
              /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async initCollege() {
      const { data: res3 } = await this.$http.get("/college");
      for (let i = 0; i < res3.data.length; i++) {
        var obj1 = new Object();
        obj1.value = res3.data[i].college;
        this.selectCollege[i] = obj1;
      }
      // console.log("dddddddddddd", res3.data);
    },
    //搜索的按钮
    searchHandle() {
      this.getLeaderList();
    },
    filterHandlerCollege(value, row) {
      return row.college === value;
    },
    //点击取消时，清空表单内容，并关闭对话框
    handleClose() {
      this.value = "";
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
    //添加领导时使modolType=0,处于添加状态，并打开对话框
    handleAdd() {
      this.modolType = 0;
      this.title2 = "添加校领导信息";
      this.dialogVisible = true;
      // this.$refs.ruleForm.resetFields();
    },
    //修改领导时，
    showEditDialog(row) {
      this.value = row.college;
      this.title2 = "修改校领导信息";
      this.dialogVisible = true;
      this.modolType = 1;
      //再次点击时
      this.$nextTick(() => {
        this.ruleForm = JSON.parse(JSON.stringify(row));
      });
      this.id = row.id
    },
    //点击 “添加” or “修改” 按钮时，添加/修改领导（预校验）
    addEditLeader() {
      this.$refs.ruleForm.validate((valid) => {
        // console.log(valid);
        if (valid) {
          if (this.modolType == 0) {
            //添加领导
            this.$http({
              url: "/leaders",
              method: "POST",
              data: {
                name: this.ruleForm.name,
                workerId: this.ruleForm.workerId,
                password: this.ruleForm.password,
                college: this.ruleForm.college,
                tel: this.ruleForm.tel,
              },
            }).then((res) => {
              this.$message({
                type: "success",
                message: "添加成功！",
              });
              //添加后刷新数据列表
              this.getLeaderList();
              this.$refs.ruleForm.resetFields();
            });
          } else {
            //修改领导
            this.$http({
              url: "/leaders",
              method: "PUT",
              data: {
                id: this.id,
                name: this.ruleForm.name,
                workerId: this.ruleForm.workerId,
                password: this.ruleForm.password,
                college: this.ruleForm.college,
                tel: this.ruleForm.tel,
              },
            }).then((res) => {
              this.$message({
                type: "success",
                message: "修改成功！",
              });
              this.getLeaderList();
              this.$refs.ruleForm.resetFields();
            });
          }
          this.value = "";
          this.dialogVisible = false;
          // this.$refs.ruleForm.resetFields();
        }
      });
    },
    //根据Id删除对应的用户信息
    async removeLeaderById(id) {
      //弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        "此操作将永久删除该校领导, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除，则返回值为字符串 confirm
      //如果用户取消了删除，则返回值为字符串 cancel
      // console.log(confirmResult);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("/leaders/" + id);

      if (res.code !== 200) {
        return this.$message.error("删除领导失败！");
      }

      this.$message.success("删除领导成功！");
      //删除成功以后，刷新数据列表
      this.getLeaderList();
    },

    //获取所有院领导信息列表
    async getLeaderList() {
      const { data: res } = await this.$http.get("/leaders/all");
      // console.log('111',res);
      if (res.code !== 200) {
        return this.$message.error("获取院领导信息列表失败！");
      }
      // this.$message.success("获取院领导信息成功！");
      this.leaderlist = res.data;
    },
  },
  mounted() {
    this.initCollege();
    this.getLeaderList();
  },
  computed: {
    label() {
      if (this.title2 == "添加") {
        return "添加校领导信息";
      } else {
        return "修改院校领导信息";
      }
    },
  },
};
</script>

<style lang="less" scoped>
body {
  margin: 0;
}
.block {
  text-align: center;
  padding: 30px 0;
}
.item {
  padding: 18px 0;
}
</style>
