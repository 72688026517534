<template>
  <div style="width: 200px; min-height: 100vh">
    <div style="background-color: #ecf5ff; width: 201px">
      <el-avatar :size="10" shape="square"></el-avatar>
    </div>

    <el-menu :default-active="$route.path" :router="true" active-text-color="#409eff" background-color="#ecf5ff"
             text-color="black" unique-opened>
      <el-aside style="width: 200px; min-height: 100vh">
        <div class="toggle-button" style="width: 200px"></div>
        <el-menu-item index="/manager/College">
          <i class="el-icon-office-building"></i>
          <span slot="title">学院专业信息</span>
        </el-menu-item>
        <el-menu-item index="/manager/Indicators">
          <i class="el-icon-setting"></i>
          <span slot="title">就业率</span>
        </el-menu-item>
        <el-menu-item index="/manager/PickLesson">
          <i class="el-icon-notebook-1"></i>
          <span slot="title">接课率</span>
        </el-menu-item>
        <el-submenu index="">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>信息管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/manager/StudentMsg">学生信息管理</el-menu-item>
            <el-menu-item index="/manager/TeacherMsg">教师信息管理</el-menu-item>
            <el-menu-item index="/manager/LeaderMsg">校领导信息管理</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="/manager/Audit">
          <i class="el-icon-reading"></i>
          <span slot="title">审核</span>
        </el-menu-item>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>就业情况</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/manager/SituationAll">总就业率</el-menu-item>
	          <el-menu-item index="/manager/echarts">就业地区分布</el-menu-item>
            <el-menu-item index="/manager/SituationCollege">各学院就业率</el-menu-item>
            <el-menu-item index="/manager/SituationMajor">各教研室就业情况</el-menu-item>
            <el-menu-item index="/manager/TeacherBonus">教师指导奖金</el-menu-item>
            <el-menu-item index="/manager/collegeBonus">学院指导奖金</el-menu-item>

          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="/manager/EmploymentAwards">
          <i class="el-icon-reading"></i>
          <span slot="title">各教研室就业奖励表</span>
        </el-menu-item>
        <el-menu-item index="/manager/EmploymentComparison">
          <i class="el-icon-reading"></i>
          <span slot="title">师生指导就业对照表</span>
        </el-menu-item>
      </el-aside>
    </el-menu>
  </div>
</template>

<script>
export default {
	// created() {
	//   this.$router.path = "college";
	// },
};
</script>

<style lang="less" scoped>
.el-menu {
	min-height: 100vh;
	width: 200px;
	// border-right: none;
	.el-aside {
		.toggle-button {
			background-color: #ecf5ff;
			font-size: 10px;
			line-height: 24px;
			color: #ecf5ff;
			text-align: center;
			letter-spacing: 0.2em;
			cursor: pointer;
		}
	}
}

.el-avatar {
	background-color: #ecf5ff;
}
</style>
