<template>
  <div>
    <el-row class="demo-avatar demo-basic">
      <el-col :span="24" style="background-color: #ecf5ff">
        <div class="demo-basic--circle">
          <div>
            <el-avatar shape="square" :size="10"></el-avatar>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-menu
          :default-active="$router.path"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          :collapse="isCollapse"
          background-color="#ecf5ff"
          text-color="#000000"
          active-text-color="#409EFF"
          :router="true"
        >
          <el-menu-item index="situationAll">
            <i class="el-icon-menu"></i>
            <span slot="title">总就业率</span>
          </el-menu-item>
          <el-menu-item index="situationCollege">
            <i class="el-icon-office-building"></i>
            <span slot="title">各学院就业率</span>
          </el-menu-item>
          <el-menu-item index="situationMajor">
            <i class="el-icon-school"></i>
            <span slot="title">各教研室就业情况</span>
          </el-menu-item>
          <el-menu-item index="teacherBonus">
            <i class="el-icon-coin"></i>
            <span slot="title">教师指导奖金</span>
          </el-menu-item>
	        <el-menu-item index="collegeBonus">
		        <i class="el-icon-coin"></i>
		        <span slot="title">学院指导奖金</span>
	        </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
    }
  },
  methods: {
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
  },
}
</script>

<style>
.demo-basic--circle {
  margin: 2px 25px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-height: 100vh;
  width: 200px;
}
.el-avatar {
  background: #ecf5ff;
}
</style>
