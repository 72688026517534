<template>
  <div>
    <el-table
      :data="
        tableData.filter(
          (data) =>
            !search ||
            data.studentName.toLowerCase().includes(search.toLowerCase())
            || data.studentId.toLowerCase().includes(search.toLowerCase())
        ) 
      "
      style="width: 100%"
    >
      <el-table-column label="毕业年份" prop="endYear" width="90">
      </el-table-column>
      <el-table-column label="姓名" prop="studentName" width="80">
      </el-table-column>
      <el-table-column label="性别" prop="sex" width="70px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.sex | getSexColorType" disable-transitions>{{
            scope.row.sex | getSexColorName
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="学号" prop="studentId" width="120">
      </el-table-column>
      <el-table-column label="学院" prop="college" width="130">
      </el-table-column>
      <el-table-column label="专业" prop="office" width="160">
      </el-table-column>
      <el-table-column label="电话" prop="tel" width="130"> </el-table-column>
      <el-table-column prop="status" label="是否就业" width="175px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status | getColorType" disable-transitions>{{
            scope.row.status | getColorName
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="就业信息" width="80">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" size="small"
            >查看</el-button
          >
        </template>
      </el-table-column>

      <el-table-column align="right">
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search"
            size="mini"
            placeholder="输入姓名或学号搜索"
          />
        </template>
        <template slot-scope="scope">
          <el-popconfirm
            title="确认要移出公海吗？"
            @confirm="handleDelete(scope.row)"
          >
            <el-button size="mini" slot="reference" type="success" plain
              >移出公海</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="就业信息" :visible.sync="dialogVisible" width="60%">
      <template>
        <el-descriptions class="margin-top" :column="3" :size="size" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              学生名称
            </template>
            <span>{{ rowData.studentName }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              学号
            </template>
            <span>{{ rowData.studentId }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              专业
            </template>
            <span>{{ rowData.office }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              生源地
            </template>
            <span>{{ rowData.studentSource }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-information"></i>
              意向地(A)
            </template>
            <span>{{ rowData.intentionPlaceOne }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-information"></i>
              意向地(B)
            </template>
            <span>{{ rowData.intentionPlaceTwo }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(A)
            </template>
            <span>{{ rowData.jobIntentionOne }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(B)
            </template>
            <span>{{ rowData.jobIntentionTwo }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-files"></i>
              就业意向(C)
            </template>
            <span>{{ rowData.jobIntentionThree }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              介绍老师名称(A)
            </template>
            <span>{{ rowData.introducerOne }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师工号(A)
            </template>
            <span>{{ rowData.introducerOneWorkId }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师电话(A)
            </template>
            <span>{{ rowData.introducerOneTel }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              介绍老师名称(B)
            </template>
            <span>{{ rowData.introducerTwo }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师工号(B)
            </template>
            <span>{{ rowData.introducerTwoWorkId }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone-outline"></i>
              介绍老师电话(B)
            </template>
            <span>{{ rowData.introducerTwoTel }}</span>
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  filters: {
    getSexColorType(status) {
      const colorMap = {
        1: "primary",
        0: "danger",
      };
      return colorMap[status];
    },
    getSexColorName(status) {
      const colorMap = {
        1: "男",
        0: "女",
      };
      return colorMap[status];
    },
    getColorType(status) {
      const colorMap = {
        0: "danger",
        1: "",
        2: "warning",
        3: "success",
        4: "info",
        5: "info",
      };
      return colorMap[status];
    },
    getColorName(status) {
      const colorMap = {
        0: "未就业",
        1: "研究生",
        2: "公务员/选调生/参军入伍",
        3: "已就业(三方协议/劳务合同)",
        4: "自主创业",
        5: "灵活就业",
      };
      return colorMap[status];
    },
  },
  data() {
    return {
      tableData: [],
      rowData: [],
      search: "",
      size: "",
      dialogVisible: false,
    };
  },
  methods: {
    //查看学生就业信息
    handleClick(row) {
      this.dialogVisible = true;
      this.rowData = row;
    },
    //移出公海
    handleDelete(row) {
      // console.log(index, row);
      this.$http({
        url: "/openSea",
        method: "DELETE",
        params: {
          id: row.id,
        },
      })
        .then((res) => {
          this.openSea();
          this.$message({
            message: "恭喜你，成功移出公海",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取公海信息
    openSea() {
      this.$http({
        url: "/openSea",
        method: "GET",
      })
        .then((res) => {
          this.tableData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.openSea();
  },
};
</script>

<style>
</style>