<template>
  <div>
    <!-- 添加教师按钮区域 -->
    <el-row :gutter="10">
      <el-col :span="2" align="left" style="margin-right: 10px">
        <el-button type="primary" size="small" @click="choicePDF"
          >导入教师信息</el-button
        >
        <input
          ref="filElemPDF"
          type="file"
          @change="getFilePDF"
          style="display: none"
          id="fileUploadPDF"
          multiple="multiplt"
        />
      </el-col>
      <el-col :span="2" align="left" style="margin-right: 10px">
        <el-button type="primary" size="small" @click="exportExcel"
          >导出教师模板</el-button
        >
      </el-col>
      <el-col :span="4">
        <span
          ><el-button type="primary" size="small" @click="addList"
            >添加教师</el-button
          ></span
        >
      </el-col>
      <el-col :span="4">
        <el-input
          clearable
          v-model="search"
          maxlength="10"
          show-word-limit
          size="medium"
          placeholder="输入工号搜索"
          @input="reseek"
        >
          <el-button
            type="primary"
            plain
            slot="append"
            icon="el-icon-search"
            @click="seek"
          >
          </el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-cascader
          clearable
          size="medium"
          placeholder="请选择所属教研室"
          :show-all-levels="false"
          v-model="office"
          :options="options"
          :props="{ expandTrigger: 'hover', emitPath: true }"
          @change="handleChangeT"
        ></el-cascader>
      </el-col>
    </el-row>
    <!-- 教师信息列表 -->
    <el-table
      :data="teacherlist.filter((data) => !search || data.workerId)"
      style="width: 100%"
    >
      <el-table-column
        label="姓名"
        prop="teacherName"
        align="center"
      ></el-table-column>
      <el-table-column label="性别" prop="sex" align="center" width="100px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.sex | getColorType" size="small">{{
            scope.row.sex | getColorSex
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="工号"
        prop="workerId"
        align="center"
      ></el-table-column>
      <el-table-column
        label="密码"
        prop="password"
        align="center"
      ></el-table-column>
      <el-table-column
        label="所属学院"
        prop="college"
        align="center"
      ></el-table-column>
      <el-table-column label="所属教研室" prop="office" align="center">
      </el-table-column>
      <el-table-column
        label="联系电话"
        prop="tel"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="250px">
        <!-- 作用域插槽 -->
        <template slot-scope="scope">
          <!-- 修改按钮 -->
          <el-button
            type="primary"
            round
            size="mini"
            @click="showEditDialog(scope.row)"
            >修改</el-button
          >
          <!-- 删除按钮 -->
          <el-button
            type="danger"
            round
            size="mini"
            @click="removeTeacherById(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
        background
      >
      </el-pagination>
    </div>

    <!-- 添加/修改教师的对话框 -->
    <el-dialog
      :title="title2"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="120px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="姓名：" prop="teacherName">
              <el-input
                v-model="ruleForm.teacherName"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="性别：" prop="sex">
              <el-select v-model="ruleForm.sex" placeholder="请选择">
                <el-option
                  v-for="item in teaSex"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="工号：" prop="workerId">
              <el-input v-model="ruleForm.workerId"></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="密码：" prop="password">
              <el-input v-model="ruleForm.password"></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="所属学院：" prop="college">
              <el-select
                v-model="ruleForm.college"
                placeholder="请选择"
                @change="getMajor"
              >
                <el-option
                  v-for="item in selectCollege"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="所属教研室：" prop="office">
              <el-select v-model="ruleForm.office" placeholder="请选择">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option> </el-select></el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="联系电话：" prop="tel">
              <el-input v-model="ruleForm.tel"></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addEditTeacher">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  filters: {
    getColorType(sex) {
      const colorMap = {
        0: "danger",
        1: "",
      };
      return colorMap[sex];
    },
    getColorSex(sex) {
      const colorMap = {
        0: "女",
        1: "男",
      };
      return colorMap[sex];
    },
  },
  data() {
    return {
      options: [],
      options1: [],
      office: null,
      workerId: null,
      college: null,
      selectCollege: [],
      id: "",
      sex: 1,
      teaSex: [
        { value: 1, label: "男" },
        { value: 0, label: "女" },
      ],
      //  获取教师列表的参数对象
      currentPage: 1, //  当前页码
      pageSize: 10, //  每页数据条数
      total: 0, //  数组总数
      teacherlist: [], //每一页从后端获取的数据
      title2: "添加",
      search: "",
      dialogVisible: false, //关闭对话框
      modolType: 0, //0表示添加，1表示修改
      //对话框中表单数据
      ruleForm: {
        teacherName: null,
        workerId: null,
        sex: null,
        password: null,
        office: null,
        college: null,
        tel: null,
      },
      //对话框表单验证规则
      rules: {
        teacherName: [
          { required: true, message: "请输入老师姓名", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请输入老师性别", trigger: "blur" }],
        workerId: [
          { required: true, message: "请输入老师的工号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入老师的密码", trigger: "blur" },
        ],
        college: [
          { required: true, message: "请输入老师所属学院", trigger: "blur" },
          {
            min: 3,
            max: 12,
            message: "学院长度在3~12个字符之间",
            trigger: "blur",
          },
        ],
        office: [
          { required: true, message: "请输入老师所属教研室", trigger: "blur" },
          {
            min: 3,
            max: 12,
            message: "教研室长度在3~12个字符之间",
            trigger: "blur",
          },
        ],
        tel: [
          { required: true, message: "请输入老师联系电话", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getCollege();
    this.getTeacherList();
    this.getTeacherList1();
    this.initCollege();
    this.getAllCollege();
    // this.$router.go(0);
  },
  computed: {
    label() {
      if (this.title2 == "添加") {
        return "添加教师信息";
      } else {
        return "修改教师信息";
      }
    },
  },
  methods: {
    //查询所有学院
    getAllCollege() {
      this.$http({
        url: "/college/all",
        method: "GET",
      })
        .then((res) => {
          this.selectCollege = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMajor(res) {
      this.valueMajor = null;
      this.ruleForm.office = null;
      // console.log(res);
      this.$http({
        url: "/college/allMajor",
        method: "GET",
        params: {
          college: res,
        },
      })
        .then((res) => {
          this.options1 = res.data.data;
          const newOption = {
           label: '辅导员',
           value: '辅导员'
          };
          this.options1.push(newOption);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 所属教研室筛选
    handleChangeT(val) {
      this.office=val[1];
      this.college = val[0];
      this.pageNum = 1;
      this.getTeacherList();
    },
    handleChange(res) {
      this.ruleForm.office = res[1];
    },
    getCollege() {
      this.$http({
        url: "/college/valueLabel",
        method: "GET",
      })
        .then((res) => {
          this.options = res.data.data;
          this.options.forEach((parent) => {
            const newOption = {
           label: '辅导员',
           value: '辅导员'
          };
            parent.children.push(newOption);
        });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async initCollege() {
      const { data: res3 } = await this.$http.get("/college/all");
      for (var i = 0; i < res3.data.length; i++) {
        var obj1 = new Object();
        obj1.value = res3.data[i].college;
        this.selectCollege[i] = obj1;
      }
    },
    // 导入
    choicePDF() {
      //给输入框添加自定义触发事件，使用dispatchEvent来进行手动触发
      this.$refs.filElemPDF.dispatchEvent(new MouseEvent("click"));
    },
    getFilePDF(e) {
      let file = e.target.files;
      var arr = ["pdf", "doc", "docx"];
      for (let i = 0; i < file.length; i++) {
        //取出上传文件的扩展名
        let flag = false;
        var index = file[i].name.lastIndexOf(".");
        var ext = file[i].name.substr(index + 1);
        var name = file[i].name;
        if (ext == "xls" || ext == "xlsx") {
          let formData = new FormData();
          formData.append("file", file[i]); // 文件对象
          //调用导入后台接口
          axios
            .post("/teaExcel/import", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                access_token: sessionStorage.getItem("token"),
              },
            })
            .then((res) => {
              if (res.data.code === 200) {
                return this.$message.success(
                  {
                    message: "文件导入成功！",
                    type: "success",
                  },
                  this.getTeacherList() //数据上传成功刷新表格数据
                  // location.reload()
                  // this.$router.go(0)
                );
              }
              // this.refresh();
              if (res.data.code !== 200) {
                return this.$message.error(
                  {
                    message: "文件导入失败！",
                    type: "warning",
                  },
                  this.getTeacherList() //数据上传成功刷新表格数据
                  // this.$router.go(0)
                  // location.reload()
                );
              }
            });
        }
      }
      this.$refs.filElemPDF[0].value = null;
    },
    //添加教师时使modolType=0,处于添加状态，并打开对话框
    addList() {
      this.modolType = 0;
      this.title2 = "添加教师信息";
      this.ruleForm.college = null;
      this.ruleForm.office = null;
      this.dialogVisible = true;
      // this.$refs.ruleForm.resetFields();
    },
    //展示修改教师的对话框
    showEditDialog(row) {
      this.dialogVisible = true;
      this.title2 = "修改教师信息";
      this.modolType = 1;
      //再次点击时
      this.$nextTick(() => {
        this.ruleForm = JSON.parse(JSON.stringify(row));
      });
      this.id = row.id;
      this.ruleForm.college = row.college;
      this.ruleForm.office = row.office;
      // console.log(row.id, "row.id");
      this.getMajor(this.ruleForm.college);
    },
    //  获取所有教师信息列表
    getTeacherList() {
      this.$http({
        method: "POST",
        url: "/teachers/selectTeachersByConditions",
        params: {
          pageNum: this.currentPage,
          pageSize: 10,
        },
        data: {
          workerId: this.workerId,
          college: this.college,
          office: this.office,
        },
      })
        .then((res) => {
          // console.log(res.data.data.total, "获取到的学生信息条数");
          this.teacherlist = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(() => {
          this.$message({
              type: "error",
              message: "获取教师信息失败!",
            });
        });
    },
    getTeacherList1() {
      this.$http({
        method: "POST",
        url: "/teachers/selectTeachersByConditions",
        params: {
          pageNum: this.currentPage,
          pageSize: 10,
        },
        data: {
          workerId: this.workerId,
          college: this.college,
          office: this.office,
        },
      })
        .then((res) => {
          // console.log(res.data.data.total, "获取到的学生信息条数");
          this.teacherlist = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(() => {
          console.log("获取教师信息失败!");
        });
    },
    //点击 '添加教师' or '修改' 按钮时，添加/修改教师（预校验）
    addEditTeacher() {
      this.$refs.ruleForm.validate((valid) => {
        // console.log("valid", valid);
        if (valid) {
          if (this.modolType == 0) {
            //  添加教师
            this.$http({
              url: "/teachers",
              method: "POST",
              data: {
                teacherName: this.ruleForm.teacherName,
                sex: this.ruleForm.sex,
                workerId: this.ruleForm.workerId,
                password: this.ruleForm.password,
                college: this.ruleForm.college,
                office: this.ruleForm.office,
                tel: this.ruleForm.tel,
              },
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "添加成功！",
                });
                this.options1 = null;
              } else {
                this.$message({
                  type: "error",
                  message: "数据异常，添加失败！",
                });
              }
              //添加后刷新数据列表
              this.getTeacherList1();
              this.$refs.ruleForm.resetFields();
              console.log("添加后获取到的教师列表", this.teacherlist);
            });
          } else {
            //  修改教师
            this.$http({
              url: "/teachers",
              method: "PUT",
              data: {
                teacherName: this.ruleForm.teacherName,
                sex: this.ruleForm.sex,
                workerId: this.ruleForm.workerId,
                password: this.ruleForm.password,
                college: this.ruleForm.college,
                office: this.ruleForm.office,
                tel: this.ruleForm.tel,
                id: this.id,
              },
            })
              .then((res) => {
                if (res.data.code == 200) {
                  this.$message({
                    type: "success",
                    message: "修改成功！",
                  });
                  this.options1 = null;
                } else {
                  this.$message({
                    type: "error",
                    message: "数据异常，修改失败！",
                  });
                }
                this.getTeacherList();
              })
              .catch((error) => {
                console.log(error);
              });
            // this.ruleForm.college = null;
            // this.ruleForm.office = null
          }
          // this.college = null;
          this.getTeacherList1();
          this.dialogVisible = false;
          this.$refs.ruleForm.resetFields();
        }
      });
    },
    //根据id删除对应教师-的信息
    removeTeacherById(row) {
      this.$confirm("此操作将永久删除该教师，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: "/teachers",
            method: "DELETE",
            params: {
              id: row.id,
            },
          }).then((res) => {
            if (res.data.code !== 200) {
              return this.$message.error(res.data.message);
            }
            this.$message.success(res.data.message);
            this.getTeacherList1();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //导出教师信息模板的点击事件
    exportExcel() {
      this.$http({
        url: "/download/teacherTemplate",
        method: "GET",
        responseType: "blob",
        data: {
          teacher: "",
        },
      }).then((res) => {
        //在计算机中，Blob常常是数据库中用来存储二进制文件的字段类型
        const blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let fileName = "教师信息模板.xlsx"; //定义导出excel文件的名字
        if ("download" in document.createElement("a")) {
          const elink = document.createElement("a"); //a标签下载
          elink.download = fileName; //download指定下载属性名
          elink.style.display = "none";
          elink.href = window.URL.createObjectURL(blob); //herf属性指定下载链接
          document.body.appendChild(elink);
          elink.click(); //click()事件触发下载
          window.URL.revokeObjectURL(elink.href); //释放内存（URL对象）
          document.body.removeChild(elink);
        } else {
          navigator.msSaveBlob(blob, fileName); //本地保存
        }
      });
    },
    //搜索的按钮
    // searchHandle() {
    //   this.pageNum = 1;
    //   this.getStudentList();
    // },
    //  监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getTeacherList();
    },
    //  filter-method方法 ---- 筛选所属教研室
    // filterHandlerOffice(value, row) {
    //   return row.office === value;
    // },
    //点击取消时，清空表单内容，并关闭对话框
    handleClose() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
      this.options1 = null;
    },
    seek() {
      if (this.search) {
        this.workerId = this.search;
        this.getTeacherList();
      }
    },
    reseek(res) {
      if (!this.search) {
        this.workerId = null;
        this.getTeacherList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
body {
  margin: 0;
}

.block {
  text-align: center;
  padding: 30px 0;
}
.item {
  padding: 18px 0;
}
</style>
