<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="com-container">
    <el-row>
      <el-col :span="24">
        <el-card class="box-card">
          <div class="year">
            <!-- <el-date-picker
              v-model="valueYear"
              type="year"
              placeholder="选择年"
              value-format="yyyy"
              @change="selectYear(valueYear)"
            ></el-date-picker>-->
          </div>
          <div class="com-chart" ref="employment_ref"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      myChart: '',
      valueYear: new Date().getFullYear().toString(),
      dataX: [],
      dataY: []
    }
  },
  methods: {
    drawBarChart() {
      this.myChart = this.$echarts.init(this.$refs.employment_ref, {
        renderer: 'svg'
      })
      this.myChart.setOption({
        title: {
          text: '总就业率',
          x: 'center'
          //   top: '0.5%'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['就业率'],
          bottom: '20'
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '就业率',
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
              formatter: '{value} %'
            }
          }
          //   {
          //     type: 'value',
          //     name: '人数',
          //     min: 0,
          //     max: 100,
          //     interval: 5,
          //     axisLabel: {
          //       formatter: '{value} 位'
          //     }
          //   }
        ],
        series: [
          //   {
          //     name: '就业率',
          //     type: 'bar',
          //     tooltip: {
          //       valueFormatter: function(value) {
          //         return value + '%'
          //       }
          //     },
          //     data: []
          //   }

          {
            name: '就业率',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function(value) {
                return value + '%'
              }
            },
            data: []
          }
        ]
      })
    },

    async initData() {
      const { data: res } = await this.$http.get('/echarts/allStuJobRate', {
        // params: {
        //   endYear: this.valueYear
        // }
      })
      this.dataX = []
      this.dataY = []
      //先进行赋值
      for (let i = 0; i < res.data.length; i++) {
        this.dataX[i] = res.data[i].endYear
        this.dataY[i] = res.data[i].rate
      }
      this.myChart.setOption({
        xAxis: [{ data: this.dataX }],
        series: [
          //   {
          //     type: 'bar',
          //     data: this.dataY
          //   }

          {
            type: 'line',
            data: this.dataY
          }
        ]
      })
    },
    // 选择年份的方法
    selectYear(val) {
      this.valueYear = val

    }
  },
  mounted() {
    this.initData()
    this.drawBarChart()
  },
}
</script>
 
<style lang="less" scoped>
.com-container {
  padding-top: 10px;
  .com-chart {
    padding-top: 60px;
    width: 100%;
    height: 400px;
  }
}
</style>