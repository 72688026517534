<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <el-container>
    <el-header>
      <LeaderCommonHeader></LeaderCommonHeader>
    </el-header>
    <el-container>
      <el-aside width="201px">
        <LeaderCommonAside></LeaderCommonAside>
      </el-aside>
      <el-main>
        <!-- 路由出口 -->
        <!-- 路由匹配到的组件将渲染在这里 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import LeaderCommonHeader from '../../components/LeaderCommonHeader.vue'
import LeaderCommonAside from '../../components/LeaderCommonAside.vue'

export default {
  components: {
    LeaderCommonAside,
    LeaderCommonHeader
  }
}
</script>

<style>
body {
  min-width: 800px;
}
.el-aside {
  background-color: #ecf5ff;
}
.el-header {
  background-color: #409eff;
}
</style>